import axios from "axios";
import clsx from "clsx";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import {
  ComponentProps,
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { ErrorBoundary } from "react-error-boundary";

// slices
import {
  moveToHistory,
  nextMessage,
  setCurrent,
  toggleRendering,
} from "src/store/slices/MessageSlice";
import { Next } from "src/store/types/Message/messageState";
import { LanguageBlock } from "src/types/interview";
import { QutterCalandly } from "src/types/qutter";
import { Error, Ok, Result } from "src/utils/Result";
import { CalendlyEventScheduledData, ScheduledEvent } from "./types";

// hooks
import { useMediaQuery } from "react-responsive";
import { useSendClosedMessageMutation } from "src/services/interview";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import useCandidate from "src/hooks/useCandidate";

// types
import { EMessageSender } from "src/types/payload.types";

dayjs.extend(calendar);

type ScheduledEventResourceOrError = Result<unknown, ScheduledEvent>;

type CalandlyProps = Omit<ComponentProps<"div">, "ref">;

const Calendly = forwardRef<HTMLDivElement, CalandlyProps>((props, ref) => {
  const dispatch = useAppDispatch();
  const interview = useAppSelector((state) => state.Interview);
  const candidate = useCandidate();
  const question = useAppSelector((state) => state.Messages.current);
  const [sendClosedMessage] = useSendClosedMessageMutation();

  const [eventScheduledEvent, setEventScheduledEvent] =
    useState<CalendlyEventScheduledData | null>(null);
  const [resolvedScheduledEventDetails, setResolvedScheduledEventDetails] =
    useState<ScheduledEventResourceOrError | null>(null);

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setEventScheduledEvent(e.data);
    },
  });

  const localizedQuestion = useMemo(() => {
    if (question && interview._state?.language) {
      return question[
        interview._state.language as LanguageBlock
      ] as QutterCalandly;
    }
  }, [question, interview]);

  const sendMessage = useCallback(
    async (scheduledEventResourceOrError: ScheduledEventResourceOrError) => {
      let formattedStartTime = "";
      let eventName = localizedQuestion?.plain_text || "Event";

      if (!scheduledEventResourceOrError.isError()) {
        formattedStartTime = ` for ${dayjs(
          scheduledEventResourceOrError.value.start_time
        ).calendar()}`;
        eventName = scheduledEventResourceOrError.value.name || eventName;
      }

      const answerText = `${eventName} scheduled${formattedStartTime}.`;

      const candidate_answer: Next = {
        sender:   EMessageSender.Candidate,
        answers:  [answerText],
        wait:     0,
      };

      dispatch(moveToHistory());
      dispatch(nextMessage({ next: candidate_answer }));

      if (!question || !eventScheduledEvent) {
        return;
      }

      const sendClosedMessageResponse = await sendClosedMessage({
        interview_id: interview._id,
        qutter_oid: question?._id,
        qutter_id: question?.id,
        language: question?.language,
        type: question?.type,
        text: answerText,
        value: answerText,
        label: answerText,
        calendly: {
          event: {
            event: eventScheduledEvent.payload.event,
            invitee: eventScheduledEvent.payload.invitee,
          },
          resource: scheduledEventResourceOrError.isError()
            ? null
            : (scheduledEventResourceOrError as ScheduledEvent),
        },
      });

      if ("data" in sendClosedMessageResponse) {
        const response = sendClosedMessageResponse.data;

        dispatch(toggleRendering({ value: true }));
        dispatch(moveToHistory());

        const interviewState = response.interviewState;
        dispatch(setCurrent({
          current:
            (interviewState && interviewState.current) ??
            response.data
        }));
      }
    },
    [
      dispatch,
      interview,
      question,
      eventScheduledEvent,
      localizedQuestion?.plain_text,
      sendClosedMessage,
    ]
  );

  useEffect(() => {
    const getScheduledEventDetails = async (
      eventScheduledEvent: CalendlyEventScheduledData
    ) => {
      try {
        const bearerToken =
          localizedQuestion?.calendlyPersonalAccessToken &&
          `Bearer ${localizedQuestion?.calendlyPersonalAccessToken}`;

        const calendlyResponse = await axios.get<{ resource: ScheduledEvent }>(
          eventScheduledEvent.payload.event.uri,
          { headers: { Authorization: bearerToken || "" } }
        );

        setResolvedScheduledEventDetails(
          Ok.with(calendlyResponse.data.resource)
        );
      } catch (error) {
        setResolvedScheduledEventDetails(Error.of(error));
      }
    };

    if (eventScheduledEvent !== null) {
      getScheduledEventDetails(eventScheduledEvent);
    }
  }, [
    eventScheduledEvent,
    interview,
    question,
    localizedQuestion?.calendlyPersonalAccessToken,
  ]);

  useEffect(() => {
    if (resolvedScheduledEventDetails !== null) {
      sendMessage(resolvedScheduledEventDetails);
    }
  }, [resolvedScheduledEventDetails, sendMessage]);

  const isDesktop = useMediaQuery({
    // Calendly Breakpoint for shifting between their mobile and desktop layouts
    minWidth: "682px",
  });

  const calendlyDimensions = useMemo((): CSSProperties | undefined => {
    const common: CSSProperties = {
      overflowY: "scroll",
      width: "100%",
    };

    if (isDesktop) {
      return {
        ...common,
        height: "920px",
        maxHeight: "750px",
        marginTop: "-60px",
      };
    }

    return {
      ...common,
      height: "850px",
      maxHeight: "625px",
    };
  }, [isDesktop]);

  const iframeTitle = "Schedule with Calendly";
  const localizedCalendlyQuestion = localizedQuestion as QutterCalandly;
  const inlineWidgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inlineWidgetRef.current) {
      const widgetRef = inlineWidgetRef.current;
      widgetRef.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  return (
    <ErrorBoundary fallback={<div>Something went wrong with Calendly</div>}>
      {/* Used as auto-scrolling target since the InlineWidget doesn't expose
       * a ref to the internal iframe */}
      <div ref={inlineWidgetRef} className="w-6 h-2"></div>
      {localizedQuestion?.calendlyUrl && (
        <div
          {...props}
          aria-label={iframeTitle}
          className={clsx("mt-4 overflow-y-hidden")}
        >
          <InlineWidget
            iframeTitle={iframeTitle}
            url={localizedCalendlyQuestion?.calendlyUrl}
            prefill={{
              email: candidate.email,
              // We are including all 3 fields in case there are forms which take name but do not take first name and last name, or the other way around.
              name: [
                ...(candidate.firstName ? [candidate.firstName] : []),
                ...(candidate.lastName ? [candidate.lastName] : []),
              ].join(" "),
              firstName: candidate.firstName,
              lastName: candidate.lastName,
            }}
            styles={calendlyDimensions}
            pageSettings={{
              hideGdprBanner: true,
              hideLandingPageDetails: true,
              hideEventTypeDetails: true,
              primaryColor: "7543ff",
              textColor: "111827",
            }}
          />
        </div>
      )}
    </ErrorBoundary>
  );
});

export default Calendly;
