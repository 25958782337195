import clsx from "clsx";
import dayjs from "dayjs";
import { forwardRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "src/components/common/DatePicker";
import { useAppDispatch } from "src/store/hooks";
import { updateReusableDateValue } from "src/store/slices/ReusableSlice";
import { Nullable } from "src/types/global";
import { ReusableMessage } from "src/types/interview";
import useConditionalReusable from "../../hooks/useConditionalReusable";

interface ReusableDateProps {
  reusable: ReusableMessage;
}

export const ReusableDate = forwardRef<HTMLDivElement, ReusableDateProps>(
  ({ reusable }, ref) => {
    const dispatch = useAppDispatch();
    const [show] = useConditionalReusable(reusable);

    if (!reusable.qutter_id) return null;

    const onDateChange = (date: Nullable<Date>) => {
      const currentDateAsUtc = dayjs(date).utc().format();

      dispatch(
        updateReusableDateValue({
          qutter_id: reusable.qutter_id,
          value: currentDateAsUtc,
          original_value: reusable.original_value,
          label: currentDateAsUtc,
          text: currentDateAsUtc,
        })
      );
    };

    const pickerLanguage =
      reusable.language === "en-gb"
        ? "en-GB"
        : reusable.language === "en-us"
        ? "en-US"
        : reusable.language.split("-")[0];

    return (
      <>
        <div className={clsx("reusable-date", show === false && "hidden")}>
          <div className="question-wrapper">
            <i>
              <p
                className="m-0"
                dangerouslySetInnerHTML={{
                  __html: reusable.question.text,
                }}
              ></p>
            </i>
          </div>

          <div ref={ref} className="text-center">
            <DatePicker
              selected={new Date(reusable.value)}
              onChange={onDateChange}
              dateFormat="yyyy-MM-dd"
              locale={pickerLanguage}
              placeholderText="Weeks start on Monday"
              inline
              className="form-control"
              minDate={new Date()}
              showMonthYearPicker={reusable.question.type !== "date-picker"}
            />
          </div>
        </div>
      </>
    );
  }
);
