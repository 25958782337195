import { forwardRef } from "react";
import ReusablesHeader from "./components/ReusablesHeader";
import ReusablesList from "./components/ReusablesList";
import SaveReusables from "./components/SaveReusables";

const Reusable = forwardRef((_, ref) => {
  return (
    <section className="reusables-container">
      <ReusablesHeader />
      <ReusablesList ref={ref} />
      <SaveReusables />
    </section>
  );
});

Reusable.displayName = "Reusable";

export default Reusable;
