import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { updateReusableApplicability } from "src/store/slices/ReusableSlice";
import { ReusableMessage } from "src/types/interview";

const useConditionalReusable = (reusable: ReusableMessage) => {
  const dispatch = useAppDispatch();
  const map = useAppSelector((state) => state.Reusables.map);
  const [reusableVisibility, setReusableVisibility] = useState<boolean>();

  useEffect(() => {
    if (!reusable.condition) return;
    const current_value = map?.[reusable.condition.qutter_id].value;
    const shouldShowReusable = reusable.condition.value === current_value;

    setReusableVisibility(shouldShowReusable);
    dispatch(
      updateReusableApplicability({
        qutter_id: reusable.qutter_id,
        applicable: shouldShowReusable,
      })
    );
  }, [map, reusable.condition, dispatch, reusable.qutter_id]);

  return [reusableVisibility, setReusableVisibility] as const;
};

export default useConditionalReusable;
