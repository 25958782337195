import { cva } from "class-variance-authority";
import { forwardRef, useState } from "react";
import { Check, Send } from "react-feather";
import { Text } from "src/components/common";
import Button from "src/components/common/Button";
import { multipleSelectDescription, sendText } from "src/components/common/utils";

// slices
import {
  moveToHistory,
  nextMessage,
  setCurrent,
  toggleHubertTyping,
  toggleRendering,
  setPending,
} from "src/store/slices/MessageSlice";
// types
import { Next } from "src/store/types/Message/messageState";
import { Optional } from "src/types/global";
import { Current, MultipleSelectMessage } from "src/types/interview";
import { EMessageSender } from "src/types/payload.types";
// styles
import multipleSelectClasses from "./MultipleSelect.module.scss";
// hooks
import { useSendClosedMessageMutation } from "src/services/interview";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useTranslation } from "react-i18next";
import { useInterviewLanguage } from "src/hooks/useInterview";

const getItemVariantClasses = cva(multipleSelectClasses.selectOption, {
  variants: {
    selected: {
      true: multipleSelectClasses.selected,
      false: undefined,
    },
  },
});

type MultipleSelectOption = Current<MultipleSelectMessage>["options"][number];

const MultipleSelect = forwardRef<HTMLFormElement>((_, ref) => {
  const dispatch = useAppDispatch();
  const interviewLanguage = useInterviewLanguage();
  const interviewId = useAppSelector((state) => state.Interview._id);
  const question = useAppSelector(
    (state) =>
      state.Messages.current as Optional<Current<MultipleSelectMessage>>
  );
  const [sendClosedMessage] = useSendClosedMessageMutation();
  const { t } = useTranslation("formErrors");

  const [selection, setSelection] = useState<MultipleSelectOption[]>([]);
  const [errorMessage, setErrorMessage] = useState("");


  const handleChange = (option: MultipleSelectOption) => {
    if (!question) return;
    let mutableSelection = [...selection];

    const getAllAboveOptions = () =>
      question?.options.filter(
        (o) => o.value !== "none-above" && o.value !== "other"
      ) ?? [];

    if (option.value) {
      mutableSelection =
        option.value === "all-above" ? getAllAboveOptions() : [option];
    } else {
      const providedOptions = question.options.filter((o) => !o.value);

      const isSelected = selection.includes(option);
      const selectedProvidedOptionCount = selection.filter((o) =>
        providedOptions.includes(o)
      ).length;

      if (isSelected) {
        mutableSelection = mutableSelection.filter(
          (o) => o !== option && o.value !== "all-above"
        );
      } else {
        if (selectedProvidedOptionCount === providedOptions.length - 1) {
          mutableSelection = getAllAboveOptions();
        } else {
          mutableSelection = mutableSelection
            .filter((o) => o.value !== "none-above" && o.value !== "other")
            .concat(option);
        }
      }
    }

    if (question.min_selections !== undefined) {
      if (mutableSelection.length < question.min_selections) {
        const min = '' + question.min_selections.toString();
        setErrorMessage(t('multipleSelect.minSelection', { minSelection: min }))
        return;
      }
    }

    if (question.max_selections !== undefined) {
      if (mutableSelection.length > question.max_selections) {
        const max = '' + question.max_selections;
        setErrorMessage(t("multipleSelect.maxSelection", { maxSelection: max }))
        return;
      }
    }

    setErrorMessage('');
    setSelection(mutableSelection);
  };

  const handleOptionsSubmit = async () => {
    if (!question) return;

    const selected: Array<string> = [];
    const candidate_answers: Array<string> = [];

    selection
      .filter((option) => option.value !== "all-above")
      .forEach((option) => {
        selected.push(option.id);
        candidate_answers.push(option.label);
      });
    if (selected.length === 0) {
      let text = 
        ['en-gb', 'en-us'].includes(interviewLanguage) ? "You need to select an option.":
        interviewLanguage === 'da-dk' ? 'Vælg venligst en mulighed.':
        interviewLanguage === 'sv-se' ? 'Du måste ange minst ett alternativ.':
        'You need to select an option.';
      return setErrorMessage(text);
    } else {
      setErrorMessage("");
    }

    const candidate_answer: Next = {
      sender:   EMessageSender.Candidate,
      answers:  candidate_answers,
    };

    dispatch(moveToHistory());
    dispatch(nextMessage({ next: candidate_answer }));

    const response = await sendClosedMessage({
      interview_id: interviewId,
      qutter_oid: question._id,
      qutter_id: question.id,
      language: question.language,
      type: question.type,
      label: candidate_answers,
      value: selected,
      text: selected,
    }).unwrap();

    dispatch(toggleRendering({ value: true }));
    dispatch(moveToHistory());

    const interviewState = response.interviewState;
    dispatch(setCurrent({
      current:
        (interviewState && interviewState.current) ??
        response.data
    }));
    dispatch(setPending({ 
      pending:
        (interviewState && interviewState.pending) ??
        response.pending
    }));

    dispatch(toggleHubertTyping({ value: true }));
  };

  if (!question?._id) {
    return <p>There's some critical data missing in the form component</p>;
  }

  return (
    <form
      ref={ref}
      className={multipleSelectClasses.multipleSelectWrapper}
      id={question._id}
    >
      <Text weight="semibold">
        {multipleSelectDescription(interviewLanguage)}
      </Text>

      <div className={multipleSelectClasses.selectWrapper}>
        {question.options.map((o, index) => {
          const isSelected = selection.includes(o);

          return (
            <div
              key={index}
              className={multipleSelectClasses.selectBubbleWrapper}
            >
              <div
                className={getItemVariantClasses({
                  selected: isSelected,
                })}
                onClick={() => handleChange(o)}
              >
                <Text onContextMenu={() => void 0}>{o.label}</Text>
                {isSelected && <Check size={16} />}
              </div>
            </div>
          );
        })}
      </div>

      <div className="text-center w-full min-h-[24px]">
        <p id="input_error" className="m-2" style={{ color: "red" }}>
          {errorMessage}
        </p>
      </div>

      <Button
        id="formButton"
        variant="primary"
        className="mt-4"
        iconLeft={<Send size={16} />}
        expanded
        onClick={handleOptionsSubmit}
      >
        {sendText(interviewLanguage)}
      </Button>
    </form>
  );
});

export default MultipleSelect;
