import { ComponentProps } from 'react';
import classes from './fileUpload.module.scss';

interface FileUploadHeaderProps extends ComponentProps<'label'> {
  label: string;
  required?: boolean;
}

export default function FileUploadHeader({
  label,
  required = false,
  children,
  ...props
}: FileUploadHeaderProps) {
  return (
    <div className="flex flex-col gap-1">
      <label {...props} className={classes.label}>
        {label} {required && <span className="text-danger"> *</span>}
      </label>
      {children && <p className={classes.description}>{children}</p>}
    </div>
  );
}
