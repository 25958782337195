import { useEffect, useMemo, useRef } from "react";
import Prompts from "src/components/Prompts";
import useScrollToComponent from "src/hooks/useScrollToComponent";
import { useAppSelector } from "src/store/hooks";

const CloseEnded = () => {
  const message = useAppSelector((state) => state.Messages.current);
  const rendering = useAppSelector((state) => state.Messages.is_rendering);
  const messagesEndRef = useRef(null);
  const scrollToMessage = useScrollToComponent(messagesEndRef);

  useEffect(() => {
    if (!rendering) {
      if (message?.type !== "calendly") {
        scrollToMessage();
      }
    }
  }, [scrollToMessage, rendering, message?.type]);

  const question = useMemo(() => {
    if (rendering) {
      return null;
    }

    if (!message) {
      return <span>ERROR: No message.</span>;
    }

    if (!message.type || !message.type.length) {
      return <span>ERROR: No message type.</span>;
    }

    switch (message.type) {
      case "open": {
        return <Prompts.Open ref={messagesEndRef} messageId={message.id} />;
      }

      case "single-select":
        return <Prompts.SingleSelect ref={messagesEndRef} />;

      case "multiple-select":
        return <Prompts.MultipleSelect ref={messagesEndRef} />;

      case "date-picker":
        return <Prompts.DatePicker ref={messagesEndRef} message={message} />;

      case "reusable":
        return <Prompts.Reusable ref={messagesEndRef} />;

      case "form":
        return <Prompts.Forms message={message} />;

      case "gaps":
        return <Prompts.FillInTheGaps ref={messagesEndRef} />;

      case "calendly":
        return <Prompts.Calendly ref={messagesEndRef} />;

      default:
        return null;
    }
  }, [message, rendering]);

  return question;
};

export default CloseEnded;
