import clsx from "clsx";
import { forwardRef, useState } from "react";
import { Send } from "react-feather";
import { Text } from "src/components/common";
import Button from "src/components/common/Button";
import { sendText } from "src/components/common/utils";

// hooks
import { useSendClosedMessageMutation } from "src/services/interview";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useInterviewLanguage } from "src/hooks/useInterview";

// slices
import {
  moveToHistory,
  nextMessage,
  setCurrent,
  setPending,
  toggleHubertTyping,
  toggleRendering
} from "src/store/slices/MessageSlice";
// components
import GapDropdown from "./components/GapDropdown";
// types
import { Optional } from "src/types/global";
import { Current, FillInTheGapsMessage } from "src/types/interview";
import { EMessageSender } from "src/types/payload.types";
// styles
import fillInTheGapsStyles from "./FillInTheGaps.module.scss";


const FillInTheGaps = forwardRef<HTMLDivElement>((_, ref) => {
  const dispatch = useAppDispatch();
  const interviewId = useAppSelector((state) => state.Interview._id);
  const interviewLanguage = useInterviewLanguage();
  const question = useAppSelector(
    (state) => state.Messages.current as Optional<Current<FillInTheGapsMessage>>
  );

  const [sendClosedMessage] = useSendClosedMessageMutation();

  const [values, setValues] = useState<Record<string, any>>({});
  const [error, setError] = useState<{
    active: boolean;
    message?: Optional<string>;
  }>({
    active: false,
  });

  const handleSend = async () => {
    if (!question) return;

    const answerCount = Object.keys(values).length;
    if (answerCount !== question.dropdowns.length) {
      setError({
        active: true,
        message: "You must give an answer to all.",
      });

      return;
    }

    setError({
      active: false,
    });

    const answers = [];

    for (let i = 0; i < question.dropdowns.length; i++) {
      const dropdown = question.dropdowns[i];
      const answer = values[dropdown.id];
      answers.push(answer);
    }

    dispatch(moveToHistory());
    dispatch(
      nextMessage({
        next: {
          sender:  EMessageSender.Candidate,
          answers: answers.flatMap((a) => a.label),
        },
      })
    );

    dispatch(toggleHubertTyping({ value: true }));
    const response = await sendClosedMessage({
      interview_id: interviewId,
      qutter_oid: question._id,
      qutter_id: question.id,
      language: question.language,
      type: question.type,
      value: answers,
      text: answers,
    }).unwrap();

    dispatch(toggleRendering({ value: true }));
    dispatch(moveToHistory());

    const interviewState = response.interviewState;
    dispatch(setCurrent({
      current:
        (interviewState && interviewState.current) ??
        response.data
    }));
    dispatch(setPending({ 
      pending:
        (interviewState && interviewState.pending) ??
        response.pending
    }));
  };

  if (!question) {
    return null;
  }

  return (
    <form className={fillInTheGapsStyles.fillGapsWrapper}>
      <Text className="mb-2" block>
        <span dangerouslySetInnerHTML={{ __html: question.pre_text }} />
      </Text>

      <div>
        <Text className={fillInTheGapsStyles.gapsText} block>
          <span dangerouslySetInnerHTML={{ __html: question.gaps_text }} />
        </Text>

        {question.dropdowns.map((dropdown, idx) => {
          return (
            <GapDropdown
              key={`gap-dropdown-${idx}`}
              id={dropdown.id}
              label={dropdown.label}
              options={dropdown.options}
              onChange={(selected) =>
                setValues((prevData) => ({
                  ...prevData,
                  [selected.id]: selected.data,
                }))
              }
            />
          );
        })}
      </div>

      {error.active && error.message && (
        <div className="text-center mt-2">
          <p id="input_error" className={clsx(`text-danger`)}>
            {error.message}
          </p>
        </div>
      )}

      <Button
        id="formButton"
        className="mt-8"
        onClick={handleSend}
        variant="primary"
        iconLeft={<Send size={16} />}
        expanded
      >
        <Text>
          {sendText(interviewLanguage)}
        </Text>
      </Button>
      <div ref={ref} />
    </form>
  );
});

export default FillInTheGaps;
