import { AxiosBaseError } from "./axiosBaseQuery/types";

export const HubChatErrorPrefix = "hub-chat-" as const;

interface Error {
  code: string;
  message?: string;
}

export default class HubertChatError {
  private constructor(private error: Error) {}

  static safeParse(e?: AxiosBaseError) {
    if (e?.code && e.code.startsWith(HubChatErrorPrefix)) {
      return new HubertChatError({ code: e.code, message: e.message });
    }

    return null;
  }

  get code() {
    return this.error.code;
  }

  get message() {
    return this.error.message;
  }

  compareTo(error: string | HubertChatError): boolean {
    if (error instanceof HubertChatError) {
      return this.compareTo(error.code);
    }

    return error === this.code;
  }
}
