import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import LoadingScreen from "src/components/LoadingScreen";
import OverviewSection from "src/components/OverviewSection";
import DebugActionBar from "src/components/Testing/DebugActionBar";
import useConditionalSearchParams from "src/hooks/useConditionalSearchParams";
import useDebug from "src/hooks/useDebug";
import Interview from "src/pages/Interview";
import { useGetInterviewQuery } from "src/services/interview";
import { Breakpoints } from "src/utils/breakpoints";

// hooks
import { useAppSelector } from "src/store/hooks";
import useCandidate from "src/hooks/useCandidate";

const InterviewContainer = () => {
  const template = useAppSelector((state) => state.Template);
  const candidate = useCandidate();
  const application = useAppSelector((state) => state.Application);
  const job = useAppSelector((state) => state.Job);
  const interview = useAppSelector((state) => state.Interview);
  const [searchParams] = useConditionalSearchParams();

  const { interviewId } = useParams();
  const token = searchParams.get("token");
  const redirectUrl = searchParams.get("redirectUrl");

  const isMobile = useMediaQuery({
    maxWidth: Breakpoints.sm,
  });

  const { isFetching } = useGetInterviewQuery(
    interviewId ? { id: interviewId, token, redirectUrl } : skipToken,
    {
      refetchOnReconnect: true,
    }
  );

  const isDebugging = useDebug();

  useEffect(() => {
    if (job?.title) {
      document.title = `Interview - ${job.title}`;
    }
  }, [job?.title]);

  if (isFetching) return <LoadingScreen />;

  return (
    <div className="interview-container-wrapper">
      {isDebugging && <DebugActionBar />}
      {!isMobile && <OverviewSection interview={interview} />}
      <Interview
        template={template}
        candidate={candidate}
        application={application}
        job={job}
      />
    </div>
  );
};

export default InterviewContainer;
