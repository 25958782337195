import { useAppSelector } from "src/store/hooks";
import TypingIndicator from "../TypingIndicator";
import CandidateMessage from "./CandidateMessage/CandidateMessage";
import HubertMessage from "./HubertMessage/HubertMessage";

const CurrentMessage = () => {
  const answers = useAppSelector((state) => state.Messages.answers);
  const isTyping = useAppSelector((state) => state.Messages.htyping);

  if (!answers) return null;

  let t = 0;
  const delay = 500;
  t += delay;
  t = answers.list.length * 150;
  return (
    <>
      {answers.sender === "hubert" ? (
        <HubertMessage wait={t} />
      ) : (
        <CandidateMessage />
      )}
      {isTyping && <TypingIndicator />}
    </>
  );
};

export default CurrentMessage;
