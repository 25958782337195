import { createSlice } from "@reduxjs/toolkit";
import { Application } from "src/types/interview";

const initialState = { _id: "" } as Application;

const ApplicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    initApplication(state, action: { payload: { application: Application } }) {
      const { application } = action.payload;
      return {
        ...state,
        ...application,
      };
    },
  },
});

export const { initApplication } = ApplicationSlice.actions;
export default ApplicationSlice.reducer;
