import { zodResolver } from '@hookform/resolvers/zod';
import { animated } from '@react-spring/web';
import jwtDecode from 'jwt-decode';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import HookFormPhoneInput from 'src/components/HookFormWrappers/HookFormPhoneInput';
import LoadingScreen from 'src/components/LoadingScreen';
import Input from 'src/components/PreInterview/Input';
import Button from 'src/components/common/Button';
import { AxiosBaseError } from 'src/services/common/axiosBaseQuery/types';
import { AuthenticatedHubChatErrorCode } from 'src/services/common/types';
import { useInterviewByJobIdMutation } from 'src/services/job';
import { useLazyGetCandidateQuery } from 'src/services/pre-interview';
import { PreInterviewApiErrorCode } from 'src/services/pre-interview/errors';
import RtsApi from 'src/services/rts';
import { z } from 'zod';
import { usePreInterviewContext } from '../../PreInterviewProvider';
import { useRegistrationContext } from '../../RealTimeScreening/hooks/useRegistrationContext';
import containerClasses from '../../container.module.scss';
import { PreInterviewPageParams } from '../../types';
import useSlideSpring from '../useSlideSpring';
import { getAdditionalDetailsFormSchema } from './getDetails.schemas';

type AdditionalDetailsFormType = z.infer<
  ReturnType<typeof getAdditionalDetailsFormSchema>
>;

interface DeserializedUser {
  email: string;
  firstName: string;
  lastName: string;
  countryCode?: string;
  phoneNumber: string;
}

export default function GetDetailsStep() {
  const slideAnimation = useSlideSpring();
  const { numberOfSteps, setCurrentStep } = usePreInterviewContext();
  const formRef = useRef<HTMLFormElement>(null);
  const { jobId } = useParams<PreInterviewPageParams>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getInterviewFromJob, getInterviewFromJobMutation] =
    useInterviewByJobIdMutation();
  const { registrationState, setRegistrationState } = useRegistrationContext();
  const [getCandidate, { data: job, isLoading: isCandidateLoading }] =
    useLazyGetCandidateQuery();
  const [applyToJob, { isLoading: applyToJobLoading }] =
    RtsApi.v1.useApplyToRtsJobMutation();
  const isLoading = applyToJobLoading;

  const { t } = useTranslation(['preInterview', 'common']);

  const additionalDetailsFormSchema = useMemo(
    () => getAdditionalDetailsFormSchema(t),
    [t]
  );

  // If no email is present, redirect to the get-email step
  useEffect(() => {
    const paramEmail = searchParams.get('user');
    const stateEmail = registrationState.candidate?.email;

    if (!paramEmail && !stateEmail) {
      navigate(
        {
          pathname: '../get-email',
          search: searchParams.toString(),
        },
        { replace: true }
      );
    }
  }, []);

  useEffect(() => {
    const token = searchParams.get('reuse');
    const user = searchParams.get('user');

    if (token) {
      sessionStorage.setItem('rts-user-token', token);
      // searchParams.delete("token");
    }

    if (user) {
      const decodedUser = jwtDecode<DeserializedUser>(user);

      setRegistrationState((prevState) => ({
        ...prevState,
        candidate: {
          ...prevState.candidate,
          email: decodedUser.email,
          firstName: decodedUser.firstName,
          lastName: decodedUser.lastName,
          phone: `${decodedUser.countryCode ?? ''}${decodedUser.phoneNumber}`,
        },
      }));
    }

    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem('rts-user-token');
    const email = searchParams.get('email');

    const fetchCandidate = async () => {
      if (token && email) {
        const candidateResult = await getCandidate({ email, token });

        if (candidateResult.data) {
          setRegistrationState((prevState) => ({
            ...prevState,
            candidate: {
              ...prevState.candidate,
              firstName: candidateResult.data?.data.firstName,
              lastName: candidateResult.data?.data.lastName,
              phone: candidateResult.data?.data.phone,
            },
          }));
        }
      }
    };

    if (token && email) {
      fetchCandidate();
    }
  }, []);

  const additionalDetailsForm = useForm<AdditionalDetailsFormType>({
    resolver: zodResolver(additionalDetailsFormSchema),
    defaultValues: useMemo(() => {
      const ret = registrationState.candidate?.firstName
        ? registrationState.candidate
        : undefined;

      if (!ret) return undefined;

      return {
        firstName: ret?.firstName,
        lastName: ret.lastName,
        phone: ret.phone,
      };
    }, [registrationState.candidate]),
  });

  useEffect(() => {
    if (registrationState.candidate) {
      additionalDetailsForm.reset(registrationState.candidate);
    }
  }, [registrationState]);

  useEffect(() => {
    if (numberOfSteps === 5) {
      setCurrentStep(3);
    } else {
      setCurrentStep(2);
    }
  }, [setCurrentStep, numberOfSteps]);

  const handleFormSubmission = async (formData: AdditionalDetailsFormType) => {
    const candidate = searchParams.get('candidate');
    const token = sessionStorage.getItem('rts-user-token');
    const otpToken = searchParams.get('otpToken');
    const jobAdId = searchParams.get('jobAdId');

    if (jobId) {
      try {
        let interviewId: string;
        const candidateState = registrationState.candidate;

        setRegistrationState((prevState) => ({
          ...prevState,
          candidate: {
            ...prevState.candidate,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phone: formData.phone,
          },
        }));

        const response = await applyToJob({
          jobId,
          ...(jobAdId && { jobAdId }),
          candidate: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: candidateState?.email!,
            phone: formData.phone,
          },
          ...(registrationState.documents && {
            documents: registrationState.documents,
          }),
        }).unwrap();

        interviewId = response.data.interview?.id;

        const newSearchParams = new URLSearchParams(searchParams);
        if (interviewId) {
          navigate(
            {
              pathname: `/${interviewId}/pre-interview`,
              search: `?${newSearchParams.toString()}`,
            },
            {
              replace: true,
              state: {
                candidate: {
                  firstName: formData.firstName,
                },
              },
            }
          );
        }
      } catch (e) {
        const error = e as AxiosBaseError<
          AuthenticatedHubChatErrorCode<PreInterviewApiErrorCode>
        >;
        if (error.code === 'hub-chat-jwt-expired') {
          toast.error(t('additionalDetailsStep.errors.otp.expired'), {
            duration: 3000,
          });
          navigate(
            {
              pathname: '../otp',
              search: `?${searchParams.toString()}`,
            },
            { replace: true }
          );
        } else {
          // navigate("../get-email", { replace: true });
          toast.error(error.message, { dismissible: true });
        }
      }
    }
  };

  const alreadyKnown = useMemo(
    () =>
      registrationState.candidate?.email &&
      registrationState.candidate?.firstName &&
      registrationState.candidate?.lastName &&
      registrationState.candidate?.phone,
    []
  );

  if (isCandidateLoading) {
    return <LoadingScreen />;
  }

  return (
    <animated.section
      style={slideAnimation}
      className={containerClasses.container}
    >
      <section className={containerClasses.intro}>
        <h1>
          {t(
            alreadyKnown
              ? 'preInterview:additionalDetailsStep.whenUserIsKnown.title'
              : 'preInterview:additionalDetailsStep.title'
          )}
        </h1>
      </section>
      <form
        ref={formRef}
        className="grid grid-cols-8 gap-4 w-full max-w-md"
        onSubmit={additionalDetailsForm.handleSubmit(handleFormSubmission)}
      >
        <Input
          value={registrationState.candidate?.email}
          className="col-span-8"
          disabled
        />
        <Input
          {...additionalDetailsForm.register('firstName')}
          placeholder={t(
            'preInterview:additionalDetailsStep.placeholders.firstName'
          )}
          className="col-span-full md:col-span-4"
          error={additionalDetailsForm.formState.errors.firstName?.message}
          alignment="left"
          disabled={isLoading}
        />
        <Input
          {...additionalDetailsForm.register('lastName')}
          placeholder={t(
            'preInterview:additionalDetailsStep.placeholders.lastName'
          )}
          className="col-span-full md:col-span-4"
          error={additionalDetailsForm.formState.errors.lastName?.message}
          alignment="left"
          disabled={isLoading}
        />
        <div className="col-span-full">
          <HookFormPhoneInput
            name="phone"
            control={additionalDetailsForm.control}
            placeholder={t('additionalDetailsStep.placeholders.phoneNumber')}
            inputClass="text-center md:!ml-[-24px]"
            country={'se'}
            meta={{
              error: additionalDetailsForm.formState.errors.phone?.message,
            }}
            disabled={isLoading}
          />
        </div>
      </form>
      <Button
        className="max-w-full md:max-w-xs mx-auto"
        expanded
        onClick={() => formRef.current?.requestSubmit()}
        loading={isLoading}
        disabled={isLoading}
      >
        {t('common:continue')}
      </Button>
    </animated.section>
  );
}
