import clsx from "clsx";
import { ComponentProps, FC } from "react";

interface TextProps extends ComponentProps<"div"> {
  size?: "small" | "large" | "";
  weight?: "regular" | "medium" | "semibold" | "light";
  block?: boolean;
}

const Text: FC<TextProps> = ({
  size,
  weight = "regular",
  block = false,
  className,
  children,
  onClick,
  ...props
}) => {
  const Tag = block ? "div" : "span";
  const textClass = size ? `body-${size}-${weight}` : `body-${weight}`;

  return (
    <Tag
      className={clsx(textClass, className, "select-none")}
      onClick={onClick}
      {...props}
    >
      {children}
    </Tag>
  );
};

export default Text;
