import { VariantProps } from "class-variance-authority";
import clsx from "clsx";
import {
  ComponentProps,
  forwardRef,
  useId,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from "react";
import fromVariants, { CommonFieldProps } from "./TextField.variants";

type TextAreaVariants = VariantProps<typeof fromVariants>;

type TextAreaProps = TextAreaVariants &
  ComponentProps<"textarea"> &
  CommonFieldProps & {
    flexibleHeight?: boolean;
    disableScrollbar?: boolean;
  };

const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      flexibleHeight,
      id,
      theme,
      edges,
      className,
      fullWidth,
      error,
      disableScrollbar = false,
      ...textAreaProps
    },
    ref
  ) => {
    const textFieldId = useId();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const initialHeightSet = useRef<boolean>(false);

    useImperativeHandle(ref, () =>
      textAreaRef.current ? textAreaRef.current : ({} as HTMLTextAreaElement)
    );

    const getTheme = (): TextAreaVariants["theme"] => {
      if (theme) {
        return theme;
      }

      if (error) {
        return "error";
      }
    };

    useLayoutEffect(() => {
      const updateFieldHeight = () => {
        const textArea = textAreaRef.current;
        if (!textArea) return;
        if (textArea.scrollHeight > 24) {
          textArea.style.cssText = `--textarea-height: 3em;`;
          textArea.style.cssText = `--textarea-height:${textArea.scrollHeight}px;`;
        }
      };

      const textArea = textAreaRef.current;

      if (!initialHeightSet.current) {
        updateFieldHeight();
        initialHeightSet.current = true;
      }

      textArea?.addEventListener("input", updateFieldHeight);
      return () => {
        textArea?.removeEventListener("input", updateFieldHeight);
      };
    }, []);

    return (
      <textarea
        id={id ?? textFieldId}
        ref={textAreaRef}
        {...textAreaProps}
        className={clsx(
          fromVariants({
            theme: getTheme(),
            edges,
            className,
            flexibleHeight,
            inputType: "textarea",
            fullWidth,
          }),
          disableScrollbar && "scrollbar-hide"
        )}
      />
    );
  }
);

TextAreaField.displayName = "TextField.Area";

export default TextAreaField;
