import { TFunction } from "i18next";
import { z } from "zod";

export const getAdditionalDetailsFormSchema = (
  t: TFunction<["preInterview", "common"]>
) =>
  z.object({
    firstName: z
      .string({
        required_error: t(
          "preInterview:additionalDetailsStep.errors.firstName.required"
        ),
      })
      .min(
        2,
        t("preInterview:additionalDetailsStep.errors.firstName.minLength")
      )
      .nonempty(),
    lastName: z
      .string({
        required_error: t(
          "preInterview:additionalDetailsStep.errors.lastName.required"
        ),
      })
      .min(2, t("preInterview:additionalDetailsStep.errors.lastName.minLength"))
      .nonempty(),
    phone: z
      .string({
        required_error: t(
          "preInterview:additionalDetailsStep.errors.phoneNumber.required"
        ),
      })
      .min(
        9,
        t("preInterview:additionalDetailsStep.errors.phoneNumber.minLength")
      ),
  });
