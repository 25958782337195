import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/store/hooks";

const ReusablesHeader = () => {
  const company = useAppSelector((state) => state.Reusables.company);
  const role = useAppSelector((state) => state.Reusables.role_name);
  const question = useAppSelector((state) => state.Messages.current);
  const { t } = useTranslation("reusables");

  if (!question) {
    return null;
  }

  return (
    <header className="flex flex-col items-center gap-1">
      <p className="text-xs md:text-sm">{t("intro")}</p>
      <span className="text-base font-semibold">{company}</span>
      <span className="max-w-[30ch] text-ellipsis whitespace-nowrap overflow-hidden">
        {role && t("position", { position: role })}
      </span>
    </header>
  );
};

export default ReusablesHeader;
