import { animated } from '@react-spring/web';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BaseSyntheticEvent, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { useAlert } from 'src/components/common/AlertDialog/AlertProvider';
import Button from 'src/components/common/Button';
import { useJobQuery, useUploadDocumentMutation } from 'src/services/job';
import { Optional } from 'src/types/global';
import containerClasses from '../../container.module.scss';
import { PreInterviewPageParams } from '../../types';
import useSlideSpring from '../useSlideSpring';
import { FileUploadControl } from './FileUploadControl';
import { useRegistrationContext } from '../../RealTimeScreening/hooks/useRegistrationContext';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

interface DocumentSubmissionForm {
  [fileType: string]: File;
}

export default function SubmitDocumentsStep() {
  const slideAnimation = useSlideSpring();
  const { jobId } = useParams<PreInterviewPageParams>();
  const { registrationState, setRegistrationState } = useRegistrationContext();
  const { data: job, ...jobQuery } = useJobQuery(jobId ?? skipToken);
  const [uploadDoc, { isLoading }] = useUploadDocumentMutation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['preInterview', 'common']);

  const formValidator = useMemo(() => {
    const fields: any = {};

    for (const requiredDoc of job?.data?.documentRequirements ?? []) {
      if (requiredDoc.isRequired) {
        fields[requiredDoc.type] = z.custom<File>(
          (data) => {
            return data instanceof File;
          },
          t('preInterview:submitDocuments.errors.file.required'),
          true
        );
      }
    }

    return zodResolver(z.object(fields));
  }, [job?.data.documentRequirements]);

  const documentSubmissionForm = useForm<any>({
    resolver: formValidator,
    shouldUnregister: true,
  });

  const { confirm } = useAlert();

  useEffect(() => {
    if (
      job?.data?.documentRequirements &&
      job.data.documentRequirements.length === 0
    ) {
      navigate({
        pathname: `../additional-details`,
        search: `?${searchParams.toString()}`,
      });
    }
  }, [job]);

  const handleFileUpload: SubmitHandler<any> = async (
    _: DocumentSubmissionForm,
    e: Optional<BaseSyntheticEvent<object, any, any>>
  ) => {
    // const confirmed = await confirm({
    //   title: t('preInterview:submitDocuments.preConfirmation.title'),
    //   description: t('preInterview:submitDocuments.preConfirmation.subtitle'),
    //   actionButton: {
    //     text: t('preInterview:submitDocuments.preConfirmation.confirm'),
    //   },
    //   cancelButton: {
    //     text: t('preInterview:submitDocuments.preConfirmation.cancel'),
    //   },
    // });

    // if (!confirmed) return;

    if (jobId) {
      const docsToUploadPromises = [];

      for (const [key, value] of Object.entries(_)) {
        docsToUploadPromises.push(
          uploadDoc({
            jobId,
            documentType: key,
            file: value,
          })
            .unwrap()
            .then((res) => {
              setRegistrationState((prevState) => ({
                ...prevState,
                documents: {
                  ...prevState.documents,
                  ...res.data,
                },
              }));
            })
        );
      }

      await Promise.all(docsToUploadPromises);

      navigate({
        pathname: `../additional-details`,
        search: `?${searchParams.toString()}`,
      });
    }
  };

  if (jobQuery.isFetching) {
    return <LoadingScreen />;
  }

  return (
    <animated.section
      style={slideAnimation}
      className={containerClasses.container}
    >
      <section className={containerClasses.intro}>
        <h1>{t('preInterview:submitDocuments.title')}</h1>
        <p>{t('preInterview:submitDocuments.subtitle')}</p>
      </section>
      <form
        className="text-left flex flex-col gap-4"
        onSubmit={documentSubmissionForm.handleSubmit(handleFileUpload)}
      >
        {job?.data?.documentRequirements?.map((requiredDoc, index) => {
          return (
            <Controller
              name={requiredDoc.type}
              key={index}
              control={documentSubmissionForm.control}
              rules={{ required: requiredDoc.isRequired }}
              render={({ field: { onChange, value } }) => {
                return (
                  <FileUploadControl
                    // {...documentSubmissionForm.register(requiredDoc.name, {
                    //   required: requiredDoc.required,
                    // })}
                    media="file"
                    onChange={(e: any) => {
                      console.log('woop', e);
                      onChange(e);
                    }}
                    title={t(
                      `preInterview:submitDocuments.${requiredDoc.type}.title`
                    )}
                    label={t(
                      `preInterview:submitDocuments.${requiredDoc.type}.label`
                    )}
                    required={requiredDoc.isRequired}
                    error={
                      documentSubmissionForm.formState.errors[requiredDoc.type]
                        ?.message
                    }
                  />
                );
              }}
            />
          );
        })}
        <Button type="submit" className="mt-8" disabled={isLoading} expanded>
          {t('common:continue.upload')}
        </Button>
      </form>
    </animated.section>
  );
}
