import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";

import {
  toggleHubertTyping,
  toggleRendering,
} from "src/store/slices/MessageSlice";

import clsx from "clsx";
import formatToRelativeTime from "date-fns/formatRelative";
import dayjs from "dayjs";
import useDebug from "src/hooks/useDebug";
import useScrollToComponent from "src/hooks/useScrollToComponent";
import { useAppDispatch } from "src/store/hooks";
import { runAfterSleep } from "src/utils";
import BaseMessage from "./BaseMessage";
import { useMessageTime } from "./MessageTimeProvider/MessageTimeProvider";

interface SimulateMessageProps {
  wait?: number;
  sender: "hubert" | "candidate";
  bubble: string;
  is_last_message: boolean;
  createdAt?: string;
}

const SimulateMessage = ({
  wait,
  sender,
  bubble,
  is_last_message,
  createdAt,
}: SimulateMessageProps) => {
  const dispatch = useAppDispatch();
  const isDebugging = useDebug();
  const { setFocusedRef, focusedRef } = useMessageTime();
  const [render, setRender] = useState(!wait || wait === 0 ? true : false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messageTimeRef = useRef<HTMLDivElement>(null);
  const scrollToMessage = useScrollToComponent(messagesEndRef);

  const toggleMessageTime = () => {
    setFocusedRef(
      messageTimeRef && messageTimeRef !== focusedRef
        ? (messageTimeRef as MutableRefObject<HTMLDivElement>)
        : undefined
    );
  };

  useEffect(() => {
    if (render === false) {
      runAfterSleep(() => {
        setRender(true);
        if (is_last_message) {
          dispatch(toggleRendering({ value: false }));
          dispatch(toggleHubertTyping({ value: false }));
        }
      }, wait ?? 0);
    }

    scrollToMessage();
  }, [dispatch, is_last_message, render, wait, scrollToMessage]);

  const createdDate = useMemo(() => {
    if (createdAt && dayjs(createdAt).isValid()) {
      return dayjs(createdAt).toDate();
    }

    return dayjs().toDate();
  }, [createdAt]);

  return (
    <>
      {isDebugging ? <span>Render: {render.toString()}</span> : null}

      <div
        className={clsx(
          "chat-bubble",
          sender === "hubert" ? "hubert" : "candidate",
          !render && "hidden"
        )}
      >
        <div
          ref={messagesEndRef}
          onClick={toggleMessageTime}
          className={clsx(
            "bubble-content transition-none",
            sender === "hubert" ? "left" : "right"
          )}
        >
          <BaseMessage bubble={bubble} />
        </div>
        <div ref={messageTimeRef} className="message-time">
          <span>{formatToRelativeTime(createdDate, new Date())}</span>
        </div>
      </div>
    </>
  );
};

export default SimulateMessage;
