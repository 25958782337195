import { useController } from "react-hook-form";
import PhoneInput from "../common/PhoneInput";

interface Props extends React.ComponentProps<typeof PhoneInput> {
  control: any;
  name: string;
}

export default function HookFormPhoneInput({ control, name, ...props }: Props) {
  const { field } = useController({ name, control });

  const { ref: _, ...fieldProps} = field;

  return <PhoneInput {...fieldProps} {...props} />;
}
