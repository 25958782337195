import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useCallback } from "react";
import { useFormContext } from "src/components/Prompts/Form/providers/FormProvider";
import { RangeFormValue } from "src/components/Prompts/Form/providers/FormProvider/types";
import { RangeForm } from "src/types/forms";

import { FormError } from "../common/FormError";
import { FormLabel } from "../common/FormLabel";
import { FormComponentProps } from "../common/types";
import formStyle from "../FormControl.module.scss";

type FormRangeProps = FormComponentProps<RangeForm>;

const FormRange = ({ form }: FormRangeProps) => {
  const [{ values }, { addValue, getImmediateError }] = useFormContext();
  const min = form.min ?? 0;
  const max = form.max ?? 100;

  const rangeValues = values[form.id]
    ? (values[form.id] as RangeFormValue).value
    : [min, max];
  const formError = getImmediateError(form.id);

  const handleChange = useCallback(
    (e: number[]) => {
      const data = {
        id: form.id,
        value: e,
        range_min: e[0],
        range_max: e[1],
        updated_at: new Date(),
        is_mandatory: form.is_mandatory,
        hubiverse_property: form.hubiverse_property,
        candidate_property: form.candidate_property,
      };
      addValue({
        [form.id]: data,
      });
    },
    [
      form.id,
      form.is_mandatory,
      form.hubiverse_property,
      form.candidate_property,
      addValue,
    ]
  );

  return (
    <div className={formStyle.formGroup}>
      <FormLabel label={form.label} is_mandatory={form.is_mandatory} />

      <div className="flex mt-1">
        {form.label_left && (
          <div className="w-1/4 m-auto text-start">
            <span>{form.label_left}</span>
          </div>
        )}
        <div className="w-1/2 m-auto text-center">
          {rangeValues && rangeValues.length === 2 ? (
            <span>
              {rangeValues[0]} - {rangeValues[1]}
            </span>
          ) : null}
          {form && form.unit ? <span>({form.unit})</span> : null}
        </div>
        {form.label_right && (
          <div className="w-1/4 text-end">
            <span>{form.label_right}</span>
          </div>
        )}
      </div>

      <div className="mx-6">
        <Slider
          range
          min={min}
          max={max}
          step={form.step ? form.step : 1}
          defaultValue={rangeValues}
          onChange={(e) => Array.isArray(e) && handleChange(e)}
        />
      </div>

      {formError && <FormError error={formError} />}
    </div>
  );
};

export default FormRange;
