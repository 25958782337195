import { ComponentProps } from "react";
import { Text } from "src/components/common";
import { FillInTheGapsMessage } from "src/types/interview";
import fillInTheGapsStyles from "../FillInTheGaps.module.scss";

export interface DropdownReturnItem {
  dropdown_id: string;
  value: number;
  text: string;
  label: string;
}

interface DropdownReturnData {
  id: string;
  data: DropdownReturnItem;
}

interface GapDropdownProps
  extends Omit<ComponentProps<"select">, "onChange" | "id"> {
  id: string;
  label: string;
  options: FillInTheGapsMessage["dropdowns"][number]["options"];
  onChange: (data: DropdownReturnData) => void;
}

const GapDropdown = ({ options, onChange, id, label }: GapDropdownProps) => {
  return (
    <div className={fillInTheGapsStyles.gapDropdownWrapper}>
      <Text block weight="semibold">
        {label}
      </Text>
      <select
        onChange={(c) => {
          const v = parseInt(c.target.value);
          const pick = options.find((o) => o.value === v);
          if (pick) {
            onChange({
              id,
              data: {
                dropdown_id: id,
                value: v,
                text: pick.label,
                label: pick.label,
              },
            });
          }
        }}
      >
        {options.map((option, idx) => {
          return (
            <option key={`gap-dropdown-option-${idx}`} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default GapDropdown;
