export type Result<T, U> = Error<T> | Ok<U>;

export class Error<T> {
  private constructor(readonly error: T) {}

  isError(): this is Error<T> {
    return true;
  }

  isOk(): this is Ok<never> {
    return false;
  }

  static of<T>(t: T) {
    return new this(t);
  }
}

export class Ok<U> {
  private constructor(readonly value: U) {}

  isOk(): this is Ok<U> {
    return true;
  }

  isError(): this is Error<never> {
    return false;
  }

  static with<U>(u: U) {
    return new this(u);
  }
}
