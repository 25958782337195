interface BaseMessageProps {
  bubble: string;
}

const BaseMessage = ({ bubble }: BaseMessageProps) => {

  // If empty string, return nothing.
  if (!bubble || !bubble.length) {
    return null;
  }

  return (
    <div
      className="m-0"
      dangerouslySetInnerHTML={{ 
        __html: bubble
      }}
    />
  );
};

export default BaseMessage;
