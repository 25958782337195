import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const DebugSlice = createSlice({
  name: "debug",
  initialState: {
    isDebugging: false,
  },
  reducers: {
    toggleDebug: (state, action: PayloadAction<boolean | undefined>) => {
      state.isDebugging = action?.payload ?? !state.isDebugging;
    },
  },
});

export const { toggleDebug } = DebugSlice.actions;

export default DebugSlice.reducer;
