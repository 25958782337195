const useDataSerializer = () => {
  return {
    serialize<T>(data: T) {
      const json = JSON.stringify(data);
      const serializedData = window.btoa(json);
      return serializedData;
    },
    deserialize<T>(data: string) {
      const deserializedData = window.atob(data);
      const parsedData: T = JSON.parse(deserializedData);
      return parsedData;
    },
  };
};

export default useDataSerializer;
