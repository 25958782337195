import { Current } from "src/types/interview";
import FormContainer from "./components/FormContainer";
import "./components/Forms.scss";
import FormProvider from "./providers/FormProvider";

interface FormsProps {
  message: Current;
}

export const Forms = ({ message }: FormsProps) => {
  return (
    <FormProvider message={message}>
      <FormContainer submitBtnText={message.send_text}>
        <div>
          <span className="h4">{message.help_text}</span>
        </div>
      </FormContainer>
    </FormProvider>
  );
};

Forms.displayName = "Forms";

export default Forms;
