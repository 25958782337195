import { PreInterviewCandidate } from 'src/services/pre-interview/queries/types';
import { Optional } from 'src/types/global';

export const SupportedAtsProviders = {
  Intelliplan: 'intelliplan',
} as const;

export type ApplicationDocument = {
  type: 'cv' | 'coverLetter';
  name: string;
  isRequired: boolean;
};

type AgreementType = 'privacy-policy' | 'consent';
type AgreementInfo = {
  id: string;
  required: boolean;
  company: string;
  type: AgreementType;
};

export type Agreement =
  | (AgreementInfo & { text: string })
  | (AgreementInfo & { link: string });

export interface JobResponse {
  clientId: string;
  company: string;
  position: string;
  featureFlags: {
    otp: boolean;
    rts: boolean;
  };
  terms: Agreement[];
  documentRequirements?: ApplicationDocument[];
}

interface ApplicationTypeAware {
  applicationType?: 'ats';
}

export interface GetInterviewFromJobRequest extends ApplicationTypeAware {
  jobId: string;
  candidateToken?: string;
  userDetails: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
}

export interface GetInterviewFromJobResponse {
  applicationId: string;
  candidate: Omit<PreInterviewCandidate, 'isHubertUser'> & {
    candidateId: string;
  };
  interviewId: string;
}

export type GetMyHubertInterviewFromJobRequest = ApplicationTypeAware & {
  jobId: string;
  candidateToken: string;
};

export type GetMyHubertInterviewFromJobResponse = GetInterviewFromJobResponse;

export type IntelliplanExtras = {
  jobAdId: string;
};

export type ATSApplyConfiguration<
  Args extends Optional<IntelliplanExtras> = never
> = {
  ats: string;
  clientId: string;
  jobId: string;
  jobTitle: string;
  args?: Args;
};

export type GetApplicationJobResponse = JobResponse & {
  jobId: string;
};
