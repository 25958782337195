import Header from "src/components/Header";
import LoadingScreen from "src/components/LoadingScreen";
import MessageHistory from "src/components/MessageHistory";
import MessageTimeProvider from "src/components/Messages/MessageTimeProvider/MessageTimeProvider";
import DebugActionBar from "src/components/Testing/DebugActionBar";
import useDebug from "src/hooks/useDebug";
import { useAppSelector } from "src/store/hooks";

const PreviewInterview = () => {
  const candidate = useAppSelector((state) => state.Candidate);
  const application = useAppSelector((state) => state.Application);
  const job = useAppSelector((state) => state.Job);
  const interview = useAppSelector((state) => state.Interview);

  const isDebugging = useDebug();

  return job && interview && candidate && application ? (
    <section className="container" style={{ padding: "2rem" }}>
      {isDebugging && <DebugActionBar />}
      <div className="messages-container scrollbar-hide">
        <Header />
        <div id="chatContainer" className="chat-container">
          <MessageTimeProvider>
            <MessageHistory />
          </MessageTimeProvider>
        </div>
      </div>
    </section>
  ) : (
    <LoadingScreen />
  );
};

export default PreviewInterview;
