import { forwardRef, useId, useMemo, useState } from "react";
import clsx from "clsx";
import da from "date-fns/locale/da";
import enGB from "date-fns/locale/en-GB";
import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import fi from "date-fns/locale/fi";
import fr from "date-fns/locale/fr";
import nb from "date-fns/locale/nb";
import sv from "date-fns/locale/sv";

import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Send } from "react-feather";

// components
import Button from "src/components/common/Button";
import DatePicker from "src/components/common/DatePicker";
import { sendText } from "src/components/common/utils";
// hooks
import { useSendClosedMessageMutation } from "src/services/interview";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useInterviewLanguage } from "src/hooks/useInterview";
// slices
import {
  moveToHistory,
  nextMessage,
  setCurrent,
  toggleRendering
} from "src/store/slices/MessageSlice";
// types
import { Next } from "src/store/types/Message/messageState";
import { Current } from "src/types/interview";
import { EMessageSender } from "src/types/payload.types";

registerLocale("es", es);
registerLocale("fr", fr);
registerLocale("sv", sv);
registerLocale("fi", fi);
registerLocale("en-GB", enGB);
registerLocale("en-US", enUS);
registerLocale("nb", nb);
registerLocale("da", da);

interface CalendarProps {
  message: Current;
}

const Calendar = forwardRef<HTMLFormElement, CalendarProps>(
  ({ message }, ref) => {
    const dispatch = useAppDispatch();
    const interviewId = useAppSelector((state) => state.Interview._id);
    const interviewLanguage = useInterviewLanguage();

    const datepickerId = useId();

    const [sendClosedMessage] = useSendClosedMessageMutation();

    const [date, setDate] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState<string>();

    const handleSend = async () => {
      if (!date) return setErrorMessage("You must do something.");
      else setErrorMessage(undefined);

      const localizedSelectedDate = date.toLocaleDateString();

      const candidateAnswer: Next = {
        sender:   EMessageSender.Candidate,
        answers:  [localizedSelectedDate],
        wait:     0,
      };

      dispatch(moveToHistory());
      dispatch(nextMessage({ next: candidateAnswer }));

      const sendClosedMessageResponse = await sendClosedMessage({
        interview_id: interviewId,
        qutter_oid: message._id,
        qutter_id: message.id,
        language: message.language,
        type: message.type,
        value: date.toISOString(),
        text: localizedSelectedDate,
      });

      if ("data" in sendClosedMessageResponse) {
        const response = sendClosedMessageResponse.data;

        dispatch(toggleRendering({ value: true }));
        dispatch(moveToHistory());

        const interviewState = response.interviewState;
        dispatch(setCurrent({
          current:
            (interviewState && interviewState.current) ??
            response.data
        }));
      }
    };

    const pickerLocale = useMemo(() => {
      if (message.language === "en-gb") return "en-GB";
      if (message.language === "en-us") return "en-US";
      return message.language.split('-')[0];
    }, [message.language]);

    return (
      <form id={datepickerId} ref={ref} className="mt-5 mb-3 mx-3">
        {message.help_text && (
          <p className="font-semibold">{message.help_text}</p>
        )}
        <DatePicker
          inline
          showMonthYearPicker={message.type !== "date-picker"}
          placeholderText="Weeks start on Monday"
          dateFormat="yyyy-MM-dd"
          locale={pickerLocale}
          minDate={new Date()}
          selected={date}
          onChange={(date) => {
            if (date) setDate(date);
          }}
        />
        <div className="text-center w-full min-h-[18px]">
          <p className={clsx(`m-0 text-danger`, !errorMessage && "hidden")}>
            {errorMessage}
          </p>
        </div>
        <Button
          variant="primary"
          iconRight={<Send />}
          onClick={handleSend}
          expanded
        >
          {sendText(interviewLanguage)}
        </Button>
      </form>
    );
  }
);

export default Calendar;
