import { createSlice } from "@reduxjs/toolkit";
import { Interview } from "src/types/interview";
import {
  PushReusablesPayload,
  SetReusableTextPayload,
  UpdateReusableDateValuePayload
} from "../types/reusables.types";

type ResuableSliceState = Partial<Interview["reusable"]> & {
  reusable?: Partial<Interview["reusable"]>;
};

const initialState = {} as ResuableSliceState;

const ReusableSlice = createSlice({
  name: "reusables",
  initialState,
  reducers: {
    pushReusables: (_state, action: PushReusablesPayload) => {
      const reusable = action.payload;
      if (!reusable) {
        return;
      }

      return { ...reusable, reusable };
    },
    /** Updates reusable open answer by it's qutter_id */
    updateOpenReusableById: (state, action: SetReusableTextPayload) => {
      const { qutter_id, text } = action.payload;
      if (state.map) {
        const map = { ...state.map };
        map[qutter_id].texts = [text.toString()];
        map[qutter_id].reusage.is_edited = true;
      }
    },
    unset: () => {
      return undefined;
    },
    updateReusableSingleSelectValue: (state, action) => {
      const { qutter_id, value, original_value, label } = action.payload;
      if (state.map) {
        const map = { ...state.map };
        map[qutter_id].value = value;
        map[qutter_id].label = label;
        map[qutter_id].text = label;
        map[qutter_id].reusage.is_edited = value !== original_value;
      }
    },
    updateReusableDateValue: (
      state,
      action: UpdateReusableDateValuePayload
    ) => {
      const { qutter_id, value, original_value, label } = action.payload;
      if (state.map) {
        const map = { ...state.map };
        map[qutter_id].value = value;
        map[qutter_id].label = label;
        map[qutter_id].reusage.is_edited = value !== original_value;
      }
    },
    updateReusableApplicability: (state, action) => {
      const { qutter_id, applicable } = action.payload;
      if (state.map) {
        const map = {
          ...state.map,
          [qutter_id]: { ...state.map[qutter_id], applicable },
        };
      }
    },
  },
});

export const {
  pushReusables,
  updateOpenReusableById,
  unset,
  updateReusableSingleSelectValue,
  updateReusableDateValue,
  updateReusableApplicability,
} = ReusableSlice.actions;

export default ReusableSlice.reducer;
