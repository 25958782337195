import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../common/axiosBaseQuery";
import handlers from "./queries/handlers";
import { GetTemplateQueryPayload } from "./queries/types";
import { TLoadChatPayload } from "src/types/payload.types";

export const TemplateApiReducerPath = "templateApi" as const;

export const templateApi = createApi({
  reducerPath: TemplateApiReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL!}/templates/v1`,
  }),
  endpoints: (builder) => ({
    getTemplate: builder.query<TLoadChatPayload, GetTemplateQueryPayload>({
      query: ({ templateId, redirectUrl, token }) => ({
        url: `/${templateId}`,
        method: "GET",
        params: {
          redirectUrl,
          token,
        },
      }),
      onQueryStarted: handlers.handleGetTemplateQueryStarted,
    }),
  }),
});

export const { useGetTemplateQuery, useLazyGetTemplateQuery } = templateApi;
