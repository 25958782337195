import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { HexColor, HubertBasePalette } from "src/hooks/useTheme";
import { clientApiEndpoints } from "src/services/client";

const LayoutControlSliceName = "layoutControl";

export enum EChatViewMode {
  // User cannot interact with the chat content.
  ReadOnly    = 'readonly',
  // User can interact with the chat content.
  Interactive = 'interactive',
}

interface LayoutControlSliceState {
  globalTextInputVisibility: boolean;
  baseTheme: {
    primaryColor: HexColor;
    isDefault?: boolean;
  };
  chatHeaderOptions: {
    resetInterview: boolean,
    notInterested: boolean,
  }
  viewMode: EChatViewMode
}

const LayoutControlSlice = createSlice<
  LayoutControlSliceState,
  SliceCaseReducers<LayoutControlSliceState>,
  typeof LayoutControlSliceName
>({
  name: LayoutControlSliceName,
  initialState: {
    globalTextInputVisibility: false,
    baseTheme: {
      primaryColor: HubertBasePalette.primary.hex as HexColor,
      isDefault: true,
    },
    chatHeaderOptions: {
      resetInterview: false,
      notInterested: false,
    },
    viewMode: EChatViewMode.Interactive
  },
  reducers: {
    setGlobalTextInputVisibility(state, action: PayloadAction<boolean>) {
      state.globalTextInputVisibility = action.payload;
    },
    setThemePrimaryColor(state, action: PayloadAction<HexColor>) {
      state.baseTheme.primaryColor = action.payload;
      state.baseTheme.isDefault = false;
    },
    setHeaderOptionResetInterview(state, action: PayloadAction<boolean>) {
      state.chatHeaderOptions.resetInterview = action.payload;
    },
    setHeaderOptionNotInterested(state, action: PayloadAction<boolean>) {
      state.chatHeaderOptions.notInterested = action.payload;
    },
    setChatViewMode(state, action: PayloadAction<EChatViewMode>) {
      state.viewMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      clientApiEndpoints.getClientConfiguration.matchFulfilled,
      (state, { payload }) => {
        state.baseTheme.primaryColor = payload.data.theme
          .baseColour as HexColor;
        state.baseTheme.isDefault = false;
      }
    );
  },
});

export const {
  setGlobalTextInputVisibility,
  setThemePrimaryColor,
  setHeaderOptionResetInterview,
  setHeaderOptionNotInterested,
  setChatViewMode,
} = LayoutControlSlice.actions;

export default LayoutControlSlice.reducer;
