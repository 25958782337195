import clsx from "clsx";
import { ComponentProps, forwardRef, ReactElement } from "react";
import BaseSingleSelectOption, {
  BaseSingleSelectOptionProps,
} from "./BaseSingleSelectOption";

type ModifiedDivProps = Omit<
  ComponentProps<"div">,
  "aria-multiselectable" | "aria-roledescription" | "role"
>;

export interface BaseSingleSelectProps extends ModifiedDivProps {
  children:
    | ReactElement<BaseSingleSelectOptionProps>
    | ReactElement<BaseSingleSelectOptionProps>[];
}

const BaseSingleSelectComponent = forwardRef<
  HTMLDivElement,
  BaseSingleSelectProps
>(({ children, className, ...selectProps }, ref) => {
  return (
    <div
      {...selectProps}
      ref={ref}
      role="listbox"
      aria-multiselectable="false"
      aria-roledescription="Single Select"
      className={clsx(
        className,
        "relative",
        "my-4 mx-auto py-3 px-2",
        "bg-primary-neutral border border-primary-light",
        "w-full md:max-w-md rounded-xl"
      )}
    >
      {children}
    </div>
  );
});

const BaseSingleSelect = Object.assign(BaseSingleSelectComponent, {
  Option: BaseSingleSelectOption,
});

BaseSingleSelect.displayName = "BaseSingleSelect";

export default BaseSingleSelect;
