import clsx from "clsx";
import { useEffect, useState } from "react";
import { AlertCircle, ChevronRight } from "react-feather";
import {
  redirect,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Button from "src/components/common/Button";
import useBackButtonListener from "src/hooks/useBackButtonListener";
import { OptionalValues } from "src/types/global";
import styles from "./ErrorPage.module.scss";

export interface ErrorPageProps {
  status?: number | string;
  title: string;
  message: string;
  backToPrevious?: boolean;
  redirect?:
    | {
        text: string;
        to: string;
      }
    | string;
}

const ErrorPage = (props: OptionalValues<ErrorPageProps>) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [info, setInfo] = useState<ErrorPageProps>();
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  useBackButtonListener(() => {
    const redirectUrl = searchParams.get("redirect");
    if (redirectUrl) {
      redirect(decodeURI(redirectUrl));
    }
  });

  useEffect(() => {
    if (props?.title && props.message) {
      const { title, message, ...rest } = props;
      setInfo({ title, message, ...rest });
      return;
    }

    if (location.state) {
      const typedState = location.state as ErrorPageProps;
      if (typedState.redirect) {
        const newParams = new URLSearchParams();
        newParams.set(
          "redirect",
          typeof typedState.redirect === "string"
            ? typedState.redirect
            : typedState.redirect.to
        );
        setSearchParams(newParams);
      }
      setInfo(location.state);
    }
  }, [location.state, props, setSearchParams]);

  const handleBackToPreviousClick = () => {
    const redirectParam = searchParams.get("redirect");
    if (redirectParam) {
      return navigate(decodeURI(redirectParam));
    }

    if (info?.redirect) {
      return navigate(
        typeof info.redirect === "object" ? info.redirect.to : info.redirect
      );
    }

    return navigate(-1);
  };

  return (
    <main className={styles.main}>
      <section className={styles.errorContentWrapper}>
        <header>
          <AlertCircle width={36} height={36} />
          <h2>{info?.title}</h2>
        </header>
        <p>{info?.message}</p>
        {info?.status && (
        <div
          className={styles.additionalDetails}
          data-expanded={detailsExpanded}
        >
          <button
            type="button"
            onClick={() => setDetailsExpanded(!detailsExpanded)}
          >
            <span>Additional Details</span>
            <ChevronRight
              height={16}
              width={16}
              className={clsx(
                "transition-transform ease-in-out",
                detailsExpanded && "rotate-90"
              )}
            />
          </button>
          
            <div className={styles.details}>
              <div>
                <p>Reason: {info?.status}</p>
              </div>
            </div>
        </div>
        )}
        {(info?.backToPrevious || searchParams.get("redirect")) && (
          <Button
            variant="primary"
            className="self-start"
            onClick={handleBackToPreviousClick}
          >
            {typeof info?.redirect === "string" && info.redirect}
            {(typeof info?.redirect === "object" && info?.redirect?.text) ||
              "Back to previous page"}
          </Button>
        )}
      </section>
      <img
        src={require("src/assets/images/hubert-logo.png")}
        alt="Hubert logo"
        className={styles.logo}
      />
    </main>
  );
};

export default ErrorPage;
