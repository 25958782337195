import { ChangeEvent } from "react";
import TextField from "src/components/common/TextField";
import { useFormContext } from "src/components/Prompts/Form/providers/FormProvider";
import { TextFormValue } from "src/components/Prompts/Form/providers/FormProvider/types";
import { TextForm } from "src/types/forms";
import { FormError } from "../common/FormError";
import { FormLabel } from "../common/FormLabel";
import { FormComponentProps } from "../common/types";
import formStyles from "../FormControl.module.scss";

type FormTextProps = FormComponentProps<TextForm>;

const FormText = ({ form }: FormTextProps) => {
  const [{ values }, { addValue, getImmediateError }] = useFormContext();

  const formError = getImmediateError(form.id);
  const formValue = values[form.id] as TextFormValue;

  const addValueToContext = (e: ChangeEvent<HTMLInputElement>) => {
    if (!form.id || e.target.value == null) {
      return;
    }

    const data = {
      id: form.id,
      value: e.target.value,
      updated_at: new Date(),
      is_mandatory: form.is_mandatory ?? false,
      hubiverse_property: form.hubiverse_property,
      candidate_property: form.candidate_property,
    };

    addValue({
      [form.id]: data,
    });
  };

  return (
    <div className={formStyles.formGroup}>
      <FormLabel label={form.label} is_mandatory={form.is_mandatory} />
      <TextField
        type="text"
        placeholder={form.placeholder}
        onChange={addValueToContext}
        value={formValue?.value ?? ""}
      />

      {formError && <FormError error={formError} />}
    </div>
  );
};

export default FormText;
