import {
  ChangeEvent,
  ClipboardEvent,
  FormEvent,
  forwardRef,
  KeyboardEvent,
} from "react";
// components
import Button from "src/components/common/Button";
import TextField from "src/components/common/TextField";
// hooks
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useKeyDetection, { KeyCombinations } from "src/hooks/useKeyDetection";
import { useAppDispatch } from "src/store/hooks";
// slices
import {
  moveToHistory,
  nextMessage,
  toggleCandidateTyping,
  toggleHubertTyping,
} from "src/store/slices/MessageSlice";
import {
  Pastes,
  PushBackspace,
  PushChange,
  PushKey,
  Reset,
  SetCurrentKey,
  SetEndTime,
} from "src/store/slices/QmetricSlice";
import { updateOpenReusableById } from "src/store/slices/ReusableSlice";
// types
import { Next } from "src/store/types/Message/messageState";
import { ReusableMessage } from "src/types/interview";
import { EditingState } from "./reusableOpen.types";
import { EMessageSender } from "src/types/payload.types";

interface Form {
  response: string;
}

interface UpdateOpenReusableFormProps {
  reusable: ReusableMessage;
  onEditComplete?: (editing: EditingState) => void;
}

const UpdateOpenReusableForm = forwardRef<
  HTMLTextAreaElement,
  UpdateOpenReusableFormProps
>(({ reusable, onEditComplete }) => {
  const dispatch = useAppDispatch();
  const { getKeyCombination } = useKeyDetection();
  const { t } = useTranslation("common");
  const reusableOpenQuestionForm = useForm<Form>({
    defaultValues: {
      response: reusable.texts.join("\n"),
    },
  });

  const handleClipboardPaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    const text = e.clipboardData.getData("text");
    dispatch(
      Pastes({
        qutter_id: reusable.qutter_id,
        text,
      })
    );

    reusableOpenQuestionForm.setValue("response", text);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const keyCombination = getKeyCombination(e);
    const value = (e.target as HTMLInputElement | HTMLTextAreaElement).value;

    if (value === "\n") reusableOpenQuestionForm.setValue("response", "");
    else if (keyCombination === KeyCombinations.ENTER) {
      dispatch(toggleCandidateTyping({ value: false }));
      const candidate_answer: Next = {
        sender:   EMessageSender.Candidate,
        answers:  [`${reusableOpenQuestionForm.getValues().response}`],
      };

      dispatch(
        SetEndTime({
          qutter_id: reusable.qutter_id,
          end: new Date().getTime(),
        })
      );

      dispatch(Reset({ qutter_id: reusable.qutter_id }));
      dispatch(moveToHistory());
      dispatch(nextMessage({ next: candidate_answer }));
    }
  };

  const handleInput = (e: FormEvent<HTMLTextAreaElement>) => {
    dispatch(
      PushChange({
        qutter_id: reusable.qutter_id,
        value: e.currentTarget.value,
      })
    );
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const { qutter_id } = reusable;
    const key = e.key;
    const detectedKeyCombination = getKeyCombination(e);

    // Dispatch actions
    dispatch(PushKey({ qutter_id, keycode: key }));
    dispatch(SetCurrentKey({ qutter_id, keycode: key }));

    // Handle specific keys
    if (detectedKeyCombination === KeyCombinations.BACKSPACE) {
      dispatch(PushBackspace({ qutter_id }));
    }
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;

    if (value && value.length) {
      dispatch(toggleCandidateTyping({ value: true }));
      dispatch(toggleHubertTyping({ value: false }));
    } else {
      dispatch(toggleCandidateTyping({ value: false }));
    }

    reusableOpenQuestionForm.setValue("response", value);
  };

  const submitUpdatedOpenAnswer = (data: Form) => {
    const joinedReusableData = reusable.texts.join("").replaceAll(" ", "");
    const joinedFormData = data.response.replaceAll(" ", "");
    const valuesAreEqual = joinedReusableData === joinedFormData;
    onEditComplete?.(valuesAreEqual ? "unedited" : "edited");
    dispatch(
      updateOpenReusableById({
        qutter_id: reusable.qutter_id,
        text: data.response,
      })
    );
  };

  return (
    <form
      onSubmit={reusableOpenQuestionForm.handleSubmit(submitUpdatedOpenAnswer)}
    >
      <TextField.Area
        {...reusableOpenQuestionForm.register("response", {
          onChange: handleChange,
        })}
        placeholder="Enter your response"
        onKeyDown={handleKeyDown}
        onInput={handleInput}
        onKeyUp={handleKeyUp}
        onPaste={handleClipboardPaste}
        flexibleHeight
        fullWidth
      />
      <Button expanded type="submit">
        {t("saveChanges")}
      </Button>
    </form>
  );
});

UpdateOpenReusableForm.displayName = "UpdateOpenReusableForm";

export default UpdateOpenReusableForm;
