import { ComponentProps } from 'react';
import clsx from 'clsx';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MoreVertical } from 'react-feather';
import { toast } from "sonner";

// slices
import {
  moveToHistory,
  setPending,
  setCurrent,
  toggleHubertTyping,
} from 'src/store/slices/MessageSlice';
// hooks
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useSendCustomRequestMutation } from 'src/services/interview';
import useCandidate from 'src/hooks/useCandidate';
// style
import styles from './dropdown.module.scss';

// errors handlers
import { AxiosBaseError } from "src/services/common/axiosBaseQuery/types";
import HubertChatError from "src/services/common/HubertChatError";

type Props = ComponentProps<typeof DropdownMenu.Root> & {};

const HeaderDropdown = ({
  ...props
}: Props) => {
  const dispatch = useAppDispatch();
  const candidate = useCandidate();
  const Interview = useAppSelector((state) => state.Interview);
  const question = useAppSelector((state) => state.Messages.current);
  const [sendCustomRequest] = useSendCustomRequestMutation();

  const onResetInterviewClick = async () => {
    if (question) {
      dispatch(toggleHubertTyping({ value: true }));
      try {
        const response = await sendCustomRequest({
          name: 'reset-interview',
          interviewId: Interview?._id
        }).unwrap();

        dispatch(moveToHistory());

        const interviewState = response.interviewState;
        dispatch(setCurrent({
          current:
            (interviewState && interviewState.current) ??
            response.data
        }));
        dispatch(setPending({ 
          pending:
            (interviewState && interviewState.pending) ??
            response.pending
        }));
        dispatch(toggleHubertTyping({ value: false }));
  
      } catch (e) {
        const error = HubertChatError.safeParse(e as AxiosBaseError);
        toast.error("Failed to submit open answer", {
          description: error?.message,
        });
      }
    }
  }

  return (
    <DropdownMenu.Root {...props}>

      <DropdownMenu.Trigger>
        <MoreVertical className='' />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        side='bottom'
        sideOffset={10}
        align='end'
        className={clsx(
          styles.Content
        )} 
      >

        {/*
        <DropdownMenu.Item className={styles.Item}>
          <a
            href='https://www.hubert.ai/insights/advice-before-your-first-hubert-interview'
            target='_blank'
          >
            FAQ
          </a>
        </DropdownMenu.Item>
        */}

        <DropdownMenu.Item
          className={clsx(
            styles.Item,
            'text-sm',
            'text-gray-300',
            'pointer-events-none',
            'items-end',
          )}
        >
          {candidate.fullName}
        </DropdownMenu.Item>

        <DropdownMenu.Separator className={styles.Separator} />

        <DropdownMenu.Item
          className={styles.Item}
          onClick={onResetInterviewClick}
        >
          Reset interview
        </DropdownMenu.Item>

      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default HeaderDropdown;
