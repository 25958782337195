import { useReducer } from "react";
import useCountdown from "src/hooks/useCountdown";
import { Optional } from "src/types/global";

type OTP = [
  Optional<number>,
  Optional<number>,
  Optional<number>,
  Optional<number>
];

interface OTPState {
  values: OTP;
  error?: string;
}

interface UseOtpProps {
  initialExpiry?: number;
}

enum OTPStateActionKey {
  SET_FIELD = "SET_FIELD",
  SET_ERROR = "SET_ERROR",
  SET_OTP_VALUES = "SET_OTP_VALUES",
  SET_DISABLED = "SET_DISABLED",
}

type OTPStateAction =
  | {
      type: OTPStateActionKey.SET_FIELD;
      payload: { index: number; value: Optional<number> };
    }
  | {
      type: OTPStateActionKey.SET_ERROR;
      payload: { error?: string };
    }
  | {
      type: OTPStateActionKey.SET_OTP_VALUES;
      payload: OTPState["values"];
    }
  | {
      type: OTPStateActionKey.SET_DISABLED;
      payload: boolean;
    };

const otpStateReducers = (
  state: OTPState,
  action: OTPStateAction
): OTPState => {
  switch (action.type) {
    case OTPStateActionKey.SET_FIELD:
      const newOtp: OTP = [...state.values];
      newOtp[action.payload.index] = action.payload.value;
      return {
        ...state,
        values: newOtp,
      };

    case OTPStateActionKey.SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    case OTPStateActionKey.SET_OTP_VALUES:
      return {
        ...state,
        values: action.payload,
      };

    default:
      return state;
  }
};

const useOtp = ({ initialExpiry }: UseOtpProps) => {
  const [{ values }, dispatch] = useReducer(otpStateReducers, {
    values: [undefined, undefined, undefined, undefined],
  });

  const countdown = useCountdown({
    initialExpiry,
  });

  const setOtpField = (index: number, value: Optional<number>) => {
    dispatch({
      type: OTPStateActionKey.SET_FIELD,
      payload: { index, value },
    });
  };

  const setOtp = (otp: OTP) => {
    dispatch({
      type: OTPStateActionKey.SET_OTP_VALUES,
      payload: otp,
    });
  };

  const setError = (error?: string) => {
    dispatch({
      type: OTPStateActionKey.SET_ERROR,
      payload: { error },
    });
  };

  const getAt = (index: number) => values[index];
  const clearError = () => setError();

  return {
    values,
    setField: setOtpField,
    set: setOtp,
    countdown,
    getAt,
    setError,
    clearError,
  };
};

export default useOtp;
