import formatToRelativeTime from 'date-fns/formatRelative';
import { useEffect, useRef } from 'react';
import TypingIndicator from 'src/components/TypingIndicator';
import useScrollToComponent from 'src/hooks/useScrollToComponent';
import { useAppSelector } from 'src/store/hooks';

const CandidateMessage = () => {
  const answers = useAppSelector((state) => state.Messages.answers);
  const isTyping = useAppSelector((state) => state.Messages.ctyping);

  const messagesEndRef = useRef(null);
  const scrollToComponent = useScrollToComponent(messagesEndRef);

  useEffect(() => {
    scrollToComponent();
  }, [scrollToComponent]);

  if (!answers || answers.list.length === 0) {
    return null;
  }

  return (
    <div className="candidate-messages-wrapper">
      <div ref={messagesEndRef}>
        {answers.list.map((answer, idx) => (
          <span key={`CandidateMessage-${idx}`}>
            <div className="chat-bubble candidate-message-preview">
              <div className="bubble-content">
                <div
                  className="m-0"
                  dangerouslySetInnerHTML={{ __html: answer }}
                ></div>
              </div>
              <span className="message-time">
                {formatToRelativeTime(new Date(), new Date())}
              </span>
            </div>
            {messagesEndRef && <div ref={messagesEndRef} />}
          </span>
        ))}
      </div>
      {isTyping && <TypingIndicator />}
    </div>
  );
};

export default CandidateMessage;
