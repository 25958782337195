import { useAppSelector } from "src/store/hooks";

// types
import { TCandidate } from "src/types/interview";

const useCandidate = (): TCandidate & { fullName: string } => {
  const candidate = useAppSelector((state) => state.Candidate);

  return {
    ...candidate,
    fullName: `${candidate.firstName} ${candidate.lastName}`
  };
};

export default useCandidate;
