import { FormEvent, PropsWithChildren, Reducer } from "react";
import { SendFormPayloadItem } from "src/services/interview/mutations/types";
import { Form, SubForm } from "src/types/forms";
import { Current } from "src/types/interview";

export type IFormProviderProps = PropsWithChildren & {
  message: Current;
};

export type CommonFormValue = Pick<
  Form<SubForm>,
  "id" | "hubiverse_property" | "candidate_property" | "is_mandatory" | "file_property"
>;

export interface TextFormValue extends CommonFormValue {
  value: string;
}

export interface NumberFormValue extends CommonFormValue {
  value: number;
}

export interface CheckboxFormValue extends CommonFormValue {
  checked: boolean;
}

export interface FileFormValue extends CommonFormValue {
  uploaded: any;
}

export interface MatrixFormValue extends CommonFormValue {
  row_values: Record<
    string,
    { checked: boolean; column_id: string; updated_at: Date }
  >;
}

export interface RangeFormValue extends CommonFormValue {
  value: number[];
  range_min: number;
  range_max: number;
}

export interface SliderFormValue extends CommonFormValue {
  value: number;
}

type FormValue =
  | TextFormValue
  | MatrixFormValue
  | NumberFormValue
  | CheckboxFormValue
  | RangeFormValue
  | SliderFormValue
  | FileFormValue;

export interface IFormState {
  forms: any[];
  values: Record<string, FormValue>;
  errors?: Record<string, string | string[] | null | undefined>;
}

export enum FormActions {
  ADD_FORM = "ADD_FORM",
  ADD_VALUE = "ADD_VALUE",
  RESET_FORM = "RESET_FORM",
  SET_FIELD_ERROR = "SET_FIELD_ERROR",
  CLEAR_FORM_ERROR = "CLEAR_FORM_ERROR",
}

export interface AddFormAction {
  type: FormActions.ADD_FORM;
  payload: any;
}

export interface AddValueAction {
  type: FormActions.ADD_VALUE;
  payload: Record<string, any>;
}

export interface ResetFormAction {
  type: FormActions.RESET_FORM;
  payload: any;
}

export interface SetFieldErrorAction {
  type: FormActions.SET_FIELD_ERROR;
  payload: { fieldId: string; error?: string | string[] | null };
}

export interface ClearErrorsAction {
  type: FormActions.CLEAR_FORM_ERROR;
  payload: string;
}

export type FormAction =
  | AddFormAction
  | AddValueAction
  | ResetFormAction
  | SetFieldErrorAction
  | ClearErrorsAction;

export type FormOperations = {
  addForm: (form: any) => void;
  addValue: (value: Record<string, any>) => void;
  handleSendClick: (e: FormEvent<HTMLFormElement>) => void;
  clearFormError: (formId: string) => void;
  getImmediateError: (formId: string) => string | null;
};

export type FormReducer = Reducer<IFormState, FormAction>;
export type IFormContext = [IFormState, FormOperations];

export interface ConstructNextQuestionParams {
  form: Form<SubForm>;
  value: FormValue;
  payload: SendFormPayloadItem;
  type?: "number" | "checkbox" | "range" | "matrix"| "file";
}
