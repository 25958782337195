import { createRoot } from "react-dom/client";

// Rollbar
import { ErrorBoundary, Provider as RollbarProvider } from "@rollbar/react"; // Provider imports 'rollbar'

// Redux
import { Provider } from "react-redux";
import { store } from "./store/store";

// SCSS/
import "./../src/styles/main.scss";
import "./index.css";
import "./styles/hubert/index.scss";
import "./xcustom.scss";

// APP
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import React, { useState } from "react";
import App from "./App";
import { Overlay, OverlayContext } from './Overlay';
import "./services/localization/index";
import { rollbarConfig } from "./services/loggers/rollbar";

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(duration);

const container = document.getElementById("root");
const root = createRoot(container!);

const AppWrap = () => {
  const [text, setText] = useState('');
  return <OverlayContext.Provider value={{ 
    get text() { return text; },
    set text(value: string) { setText(value); },
  }}>
  <Overlay />
  <App />
</OverlayContext.Provider>

}

root.render(
  <React.StrictMode>
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Provider store={store}>
          <AppWrap />
          {/* <TestError /> */}
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
