import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import ClientApi from "src/services/client";
import InterviewApi from "src/services/interview";
import JobApi from "src/services/job";
import PreInterviewApi from "src/services/pre-interview";
import RtsApi from "src/services/rts";
import { templateApi } from "src/services/template";
import ApplicationSlice from "./slices/ApplicationSlice";
import CandidateSlice from "./slices/CandidateSlice";
import DebugSlice from "./slices/DebugSlice";
import InterviewSlice from "./slices/InterviewSlice";
import JobSlice from "./slices/JobSlice";
import LayoutControlSlice from "./slices/LayoutControlSlice";
import MessageSlice from "./slices/MessageSlice";
import QmetricSlice from "./slices/QmetricSlice";
import ReusableSlice from "./slices/ReusableSlice";
import TemplateSlice from "./slices/TemplateSlice";

export const store = configureStore({
  reducer: {
    Qmetrics: QmetricSlice,
    Job: JobSlice,
    Template: TemplateSlice,
    Candidate: CandidateSlice,
    Interview: InterviewSlice,
    Application: ApplicationSlice,
    Messages: MessageSlice,
    Reusables: ReusableSlice,
    Debug: DebugSlice,
    LayoutControl: LayoutControlSlice,
    [InterviewApi.v1.reducerPath]: InterviewApi.v1.reducer,
    [InterviewApi.v2.reducerPath]: InterviewApi.v2.reducer,
    [JobApi.v1.reducerPath]: JobApi.v1.reducer,
    [JobApi.v2.reducerPath]: JobApi.v2.reducer,
    [RtsApi.v1.reducerPath]: RtsApi.v1.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
    [PreInterviewApi.reducerPath]: PreInterviewApi.reducer,
    [ClientApi.reducerPath]: ClientApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(InterviewApi.v1.middleware)
      .concat(InterviewApi.v2.middleware)
      .concat(JobApi.v1.middleware)
      .concat(JobApi.v2.middleware)
      .concat(RtsApi.v1.middleware)
      .concat(templateApi.middleware)
      .concat(PreInterviewApi.middleware)
      .concat(ClientApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
