import FormCheckbox from "./checkbox/FormCheckbox";
import FormEmail from "./email/FormEmail";
import FormFileUpload from "./file/FormFileUpload";
import FormElementBoundary from "./FormElementBoundary";
import FormMatrix from "./matrix/FormMatrix";
import FormNumber from "./number/FormNumber";
import FormRange from "./range/FormRange";
import FormSlider from "./slider/FormSlider";
import FormText from "./text/FormText";

interface FormElementProps {
  form: any;
}

export const FormElement = (props: FormElementProps) => {
  const formProps = { form: props.form };

  if (!props.form.type) {
    return null;
  }

  switch (props.form.type) {
    case "text":
      return (
        <FormElementBoundary
          name="text"
          withComponent={<FormText {...formProps} />}
        />
      );

    case "number":
      return (
        <FormElementBoundary
          name="number"
          withComponent={<FormNumber {...formProps} />}
        />
      );

    case "email":
      return (
        <FormElementBoundary
          name="email"
          withComponent={<FormEmail {...formProps} />}
        />
      );

    case "slider":
      return (
        <FormElementBoundary
          name="slider"
          withComponent={<FormSlider {...formProps} />}
        />
      );

    case "range":
      return (
        <FormElementBoundary
          name="range"
          withComponent={<FormRange {...formProps} />}
        />
      );

    case "checkbox":
      return (
        <FormElementBoundary
          name="checkbox"
          withComponent={<FormCheckbox {...formProps} />}
        />
      );

    case "matrix":
      return (
        <FormElementBoundary
          name="matrix"
          withComponent={<FormMatrix {...formProps} />}
        />
      );

    case "file":
      return (
        <FormElementBoundary
          name="file"
          withComponent={<FormFileUpload {...formProps} />}
        />
      );

    default:
      return null;
  }
};
