import { useCallback, useMemo } from "react";

interface ScrollOptions extends ScrollIntoViewOptions {
  toTop?: {
    offset?: number;
  };
}

const useScrollToComponent = (ref?: React.RefObject<HTMLElement>) => {
  const localRef = ref;

  const defaultOptions: ScrollOptions = useMemo(
    () => ({
      behavior: "smooth",
      block: "start",
      inline: "start",
    }),
    []
  );

  const scroll = useCallback(
    (options: ScrollOptions | null = defaultOptions) => {
      if (localRef?.current) {
        if (options?.toTop) {
          const chatContainer = document.getElementById("chatContainer");
          const localRefDimensions = localRef.current.getBoundingClientRect();
          if (chatContainer) {
            chatContainer.scrollTo({
              top:
                localRefDimensions.top +
                window.scrollY +
                (options.toTop.offset || 0),
              behavior: "smooth",
            });
          }
        } else {
          localRef.current.scrollIntoView({
            behavior: "smooth",
            ...options,
          });
        }
      }
    },
    [localRef, defaultOptions]
  );

  return scroll;
};

export default useScrollToComponent;
