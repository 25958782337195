import { useEffect } from 'react';
import {
  Navigate,
  generatePath,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import RtsApi from 'src/services/rts';
import { z } from 'zod';
import useRtsJobConfig from './hooks/useRtsJobConfig';

type UnifiedRTSEntryParams = {
  jobId: string;
};
// export const CreateRtsJobBaseSchema = z
// 	.object({
// 		ats: z.nativeEnum(SupportedAts),
// 		clientId: objectIdValidator,
// 	})
// 	.passthrough();

// export const CreateRtsJobIntelliplanSchema = CreateRtsJobBaseSchema.extend({
// 	title: z.string(),
// 	jobId: z.string(),
// 	ats: z.literal(SupportedAts.INTELLIPLAN),
// });

const IpSchema = z.object({
  jobId: z.string(),
  clientId: z.string(),
  ats: z.string(),
  title: z.string(),
});

/**
 * Redirect Page for ATS Application Requests
 */
const UnifiedRTSEntry = () => {
  const { jobId } = useParams<UnifiedRTSEntryParams>();
  const [searchParams] = useSearchParams();
  const [GetOrCreateRtsJob, { isLoading, data: rtsJob, error }] =
    RtsApi.v1.useRtsJobMutation();
  const jobAdId = searchParams.get('jobAdId');

  const [atsConfig, errors] = useRtsJobConfig();

  useEffect(() => {
    if (atsConfig) {
      GetOrCreateRtsJob(atsConfig);
    }
  }, [atsConfig, GetOrCreateRtsJob]);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (rtsJob) {
    const { jobId } = rtsJob.data;
    let redirectUrl = jobId
      ? generatePath('/rts/:jobId', {
          jobId,
        })
      : '.';

    redirectUrl = redirectUrl + `?jobAdId=${jobAdId}`;

    return <Navigate to={redirectUrl} />;
  }

  return <span></span>;
};

export default UnifiedRTSEntry;
