import { cva, VariantProps } from "class-variance-authority";
import clsx from "clsx";
import { ComponentProps } from "react";
import decoratorClasses from "./formControlDecorator.module.scss";

const getDecoratorContainerVariants = cva(decoratorClasses.container, {
  variants: {
    alignment: {
      left: "items-start",
      center: "items-center",
    },
    expanded: {
      true: "w-full",
      false: "w-auto",
    },
    error: {
      true: decoratorClasses.error,
    },
  },
});

type Props = ComponentProps<"div"> &
  Omit<VariantProps<typeof getDecoratorContainerVariants>, "error"> & {
    label?: string;
    error?: string;
    helperText?: string;
  };

const FormControlDecorator = ({
  children,
  error,
  helperText,
  alignment = "center",
  label,
  className,
  expanded,
  ...props
}: Props) => {
  return (
    <div
      {...props}
      className={getDecoratorContainerVariants({
        alignment,
        expanded,
        className,
        error: Boolean(error),
      })}
    >
      {label && <label>{label}</label>}
      {children}
      {helperText && (
        <span
          className={clsx(
            decoratorClasses.helperText,
            alignment === "left" && "ml-4"
          )}
        >
          {helperText}
        </span>
      )}
      {error && (
        <span
          className={clsx(decoratorClasses.helperText, decoratorClasses.error)}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default FormControlDecorator;
