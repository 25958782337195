export enum FormType {
  Matrix = "matrix",
  Text = "text",
  Email = "email",
  Range = "range",
  Slider = "slider",
  Number = "number",
  Checkbox = "checkbox",
  File = "file",
}

export interface MatrixCell {
  id: string;
  label: string;
  value?: string | number | null;
}

export interface MatrixForm {
  type: FormType.Matrix;
  columns: Array<MatrixCell>;
  rows: Array<MatrixCell>;
}

export interface SliderForm {
  type: FormType.Slider;
}

export interface TextForm {
  type: FormType.Text;
}

export interface EmailForm {
  type: FormType.Email;
}

export interface RangeForm {
  type: FormType.Range;
}

export interface NumberForm {
  type: FormType.Number;
}

export interface CheckboxForm {
  type: FormType.Checkbox;
}

export interface FileUploadForm {
  type: FormType.File;
}

export type SubForm =
  | MatrixForm
  | SliderForm
  | TextForm
  | EmailForm
  | RangeForm
  | NumberForm
  | CheckboxForm
  | FileUploadForm;

export type Form<K extends SubForm> = {
  id: string;
  label: string;
  help_text?: string;
  placeholder?: string;
  is_mandatory: boolean;
  min: number;
  max: number;
  step: number;
  validator?: any;
  hubiverse_property?: string;
  candidate_property?: string;
  file_property?: string;
  label_left?: string;
  label_right?: string;
  unit?: string;
} & K;
