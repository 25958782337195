import { useSpring } from "@react-spring/web";

export default function useSlideSpring() {
  const slideAnimation = useSpring({
    from: { opacity: 0, transform: "translateX(100%)" },
    to: { opacity: 1, transform: "translateX(0)" },
  });

  return slideAnimation;
}
