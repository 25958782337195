import { Loader } from "react-feather";
import {
  CommonButtonProps,
  IconButtonProps,
  TextButtonProps,
} from "./Button.types";
import fromButtonVariants from "./Button.variant";

type ButtonProps = CommonButtonProps & (IconButtonProps | TextButtonProps);

const Button = ({
  variant,
  expanded,
  children,
  iconLeft,
  iconRight,
  className,
  kind = "text",
  icon,
  type = "button",
  loading = false,
  ...buttonProps
}: ButtonProps) => {
  const variants: Parameters<typeof fromButtonVariants>[0] = {
    variant,
    buttonFit: expanded ? "expanded" : "default",
    kind,
    className: className,
  };

  return (
    <button
      {...buttonProps}
      className={fromButtonVariants(variants)}
      type={type}
    >
      {kind !== "icon" ? (
        <>
          {!iconRight && loading && <Loader className="animate-spin" />}
          {iconLeft && !loading && <span>{iconLeft}</span>}
          <span>{children}</span>
          {iconRight && !loading && <span>{iconRight}</span>}
          {iconRight && loading && <Loader className="animate-spin" />}
        </>
      ) : (
        icon && icon
      )}
    </button>
  );
};

export default Button;
