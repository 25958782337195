import { useFormContext } from "src/components/Prompts/Form/providers/FormProvider";
import { CheckboxFormValue } from "src/components/Prompts/Form/providers/FormProvider/types";
import { CheckboxForm } from "src/types/forms";
import { Optional } from "src/types/global";
import { FormError } from "../common/FormError";
import { FormLabel } from "../common/FormLabel";
import { FormComponentProps } from "../common/types";
import formStyles from "../FormControl.module.scss";

type FormCheckboxProps = FormComponentProps<CheckboxForm>;

const FormCheckbox = ({ form }: FormCheckboxProps) => {
  const [{ values }, { addValue, getImmediateError }] = useFormContext();

  const formError = getImmediateError(form.id);
  const formValue = values[form.id] as Optional<CheckboxFormValue>;

  return (
    <div className={formStyles.formGroup}>
      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          placeholder="Data Privacy Agreement"
          checked={formValue?.checked}
          className="accent-primary"
          onChange={(e) => {
            const data = {
              id: form.id,
              checked: e.target.checked,
              updated_at: new Date(),
              is_mandatory: form.is_mandatory,
              hubiverse_property: form.hubiverse_property,
              candidate_property: form.candidate_property,
            };
            addValue({
              [form.id]: data,
            });
          }}
        />
        <FormLabel label={form.label} is_mandatory={form.is_mandatory} dense />
      </div>

      {formError && <FormError error={formError} className="!mt-2" />}
    </div>
  );
};

export default FormCheckbox;
