import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { toggleDebug } from "src/store/slices/DebugSlice";

const useDebug = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const isDebugging = useAppSelector((state) => state.Debug.isDebugging);

  useEffect(() => {
    const debug = searchParams.get("debug");

    if (
      process.env.NODE_ENV !== "production" &&
      debug === "true" &&
      !isDebugging
    ) {
      dispatch(toggleDebug(debug === "true"));
    }
  }, [searchParams, dispatch, isDebugging]);

  return isDebugging;
};

export default useDebug;
