import clsx from "clsx";
import { ComponentProps, MouseEvent } from "react";

type ModifiedDivProps = Omit<ComponentProps<"div">, "onClick">;

export interface BaseSingleSelectOptionProps extends ModifiedDivProps {
  id?: string;
  value?: string;
  onClick?: (
    e: MouseEvent<HTMLDivElement>,
    value: { id?: string; value?: string }
  ) => void;
}

const BaseSingleSelectOption = ({
  id,
  value,
  onClick,
  className,
  children,
  ...baseProps
}: BaseSingleSelectOptionProps) => {
  return (
    <div
      {...baseProps}
      className={clsx(
        className,
        "w-full py-1.5 mb-1",
        "bg-gray-900 hover:bg-primary rounded-xl text-white",
        "text-center text-sm md:text-base leading-normal",
        "cursor-pointer",
        "duration-150 ease-in"
      )}
      onClick={(e) => onClick?.(e, { id, value })}
      role="option"
      aria-selected="false"
    >
      {children}
    </div>
  );
};

export default BaseSingleSelectOption;
