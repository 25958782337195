import { ComponentProps, useEffect, useState } from "react";
import { useAppSelector } from "src/store/hooks";
import progressBarClasses from "./ProgressBar.module.scss";

interface ProgressBarProps extends ComponentProps<"div"> {
  interviewLength: number;
}

const ProgressBar = ({ interviewLength }: ProgressBarProps) => {
  const [width, setWidth] = useState(0);
  const pending = useAppSelector((state) => state.Messages.pending);

  useEffect(() => {
    if (pending === null) return;

    if (interviewLength) {
      const questionRangeValue = 100 / interviewLength;
      const percentage = questionRangeValue * (interviewLength - pending);
      if (percentage > width) setWidth(Math.trunc(percentage));
    }
  }, [pending, interviewLength, width]);

  return (
    <div className={progressBarClasses.progressBarWrapper}>
      <div className={progressBarClasses.progressBar}>
        <div
          className={progressBarClasses.filled}
          style={{ width: `${width}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
