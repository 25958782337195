import { animated } from '@react-spring/web';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  Dispatch,
  SetStateAction,
  Suspense,
  createContext,
  useState,
} from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { ErrorPageProps } from 'src/pages/ErrorPage';
import { useGetClientConfigurationQuery } from 'src/services/client';
import { useJobQuery } from 'src/services/job';
import { PreInterviewCandidate } from 'src/services/pre-interview/queries/types';
import { useAppSelector } from 'src/store/hooks';
import useSlideSpring from '../Steps/useSlideSpring';
import PreInterviewPageLayout from '../container';
import { PreInterviewPageParams } from '../types';

interface PreInterviewJob {
  title: string;
  requiredDocuments?: {
    name: string;
    type: string;
    isRequired: boolean;
  }[];
}

interface RegistrationDetails {
  isMyHubertUser: boolean;
  candidate: Partial<PreInterviewCandidate>;
  job: PreInterviewJob;
  documents: {
    [docType: string]: string;
  };
}

export interface Registration {
  registrationState: Partial<RegistrationDetails>;
  setRegistrationState: Dispatch<SetStateAction<Partial<RegistrationDetails>>>;
}

export const RegistrationContext = createContext<Registration | undefined>(
  undefined
);

export default function RTSPreInterviewPage() {
  const slideAnimation = useSlideSpring();
  const { jobId } = useParams<PreInterviewPageParams>();
  const { data: job, ...jobQuery } = useJobQuery(jobId ?? skipToken);
  const [registrationState, setRegistrationState] = useState<
    Partial<RegistrationDetails>
  >({
    isMyHubertUser: false,
    candidate: {},
    job: {
      title: job?.data.position ?? '',
    },
  });
  const appTheme = useAppSelector((state) => state.LayoutControl.baseTheme);
  const clientConfigurationQuery = useGetClientConfigurationQuery(
    appTheme.isDefault && job?.data.clientId ? job?.data.clientId : skipToken
  );

  if (jobQuery.isFetching || clientConfigurationQuery.isFetching) {
    return <LoadingScreen />;
  }

  if (jobQuery.error) {
    return (
      <Navigate
        to={'/error'}
        state={
          {
            title: 'Failed to load this job',
            message: jobQuery.error.message,
            status: jobQuery.error.code,
            backToPrevious: true,
          } as ErrorPageProps
        }
      />
    );
  }

  if (job?.data.featureFlags.rts === false) {
    return (
      <Navigate
        to={'/error'}
        state={
          {
            title: 'This job does not have Real Time Screening enabled',
            message: 'Please contact the job poster for more information.',
            status: '404',
          } as ErrorPageProps
        }
      />
    );
  }

  return (
    <PreInterviewPageLayout>
      <animated.div style={slideAnimation} className="p-8">
        <Suspense>
          <RegistrationContext.Provider
            value={{ registrationState, setRegistrationState }}
          >
            <Outlet />
          </RegistrationContext.Provider>
        </Suspense>
      </animated.div>
    </PreInterviewPageLayout>
  );
}
