import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EInterviewStateName, TLoadInterviewPayload } from "src/types/payload.types";

export type TInterview = TLoadInterviewPayload & {}

const initialState: TInterview = { 
  _id: "",
  state: {
    name: EInterviewStateName.NotStarted,
    language: "",
    pending: 0,
    current: null
  },
  history: [],
  _state: null
};

const InterviewSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    initInterview (
      state,
      action: PayloadAction<{ interview: TLoadInterviewPayload }>
    ) {
      const { interview } = action.payload;

      return {
        _id: interview._id,
        _state: interview._state,
        state: interview.state,
        history: interview.history,
      };
    },
    // updateInterviewState (
    //   state,
    //   action: PayloadAction<{ interview: InterviewMeta }>) {
    // }
  },
});

export const { initInterview } = InterviewSlice.actions;
export default InterviewSlice.reducer;
