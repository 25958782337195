import clsx from "clsx";
import { forwardRef } from "react";
import { ReusableMapValue } from "src/types/interview";
import useConditionalReusable from "../../hooks/useConditionalReusable";
import { ReusableOption } from "./ReusableOption";

interface ReusableSingleSelectProps {
  reusable: ReusableMapValue;
}

export const ReusableSingleSelect = forwardRef<
  HTMLDivElement,
  ReusableSingleSelectProps
>(({ reusable }, ref) => {
  const [show] = useConditionalReusable(reusable);

  if (!reusable.qutter) return null;

  return (
    <div
      ref={ref}
      className={clsx("reusable-single-select", show === false && "hidden")}
    >
      <div className="question-wrapper">
        <em>
          <i
            className="m-0"
            dangerouslySetInnerHTML={{
              __html: reusable.question.text,
            }}
          />
        </em>
      </div>

      <div role="list" className="flex flex-col gap-1 my-1">
        {reusable.qutter.options.map((option) => {
          return (
            <ReusableOption
              key={option.id}
              option={option}
              qutter_id={reusable.qutter_id}
              value={reusable.value}
            />
          );
        })}
      </div>
    </div>
  );
});
