import { useEffect, useRef } from "react";
import { useFormContext } from "src/components/Prompts/Form/providers/FormProvider";
import { MatrixForm } from "src/types/forms";
import { FormError } from "../common/FormError";
import { FormLabel } from "../common/FormLabel";
import { FormComponentProps } from "../common/types";
import formStyles from "../FormControl.module.scss";
import matrixClasses from "./FormMatrix.module.scss";
import { FormMatrixRow } from "./FormMatrixRow";

type FormMatrixProps = FormComponentProps<MatrixForm>;

const FormMatrix = ({ form }: FormMatrixProps) => {
  const [, { getImmediateError }] = useFormContext();
  const formError = getImmediateError(form.id);

  const matrixHeaderRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (matrixHeaderRowRef.current) {
      matrixHeaderRowRef.current.style.setProperty(
        "--column-count",
        `${form.columns.length + 1}`
      );
    }
  }, [form.columns.length]);

  return (
    <div className={formStyles.formGroup}>
      <FormLabel label={form.label} is_mandatory={form.is_mandatory} />
      {form.help_text && <FormLabel label={form.help_text} />}
      {formError && <FormError error={formError} />}

      <div className={matrixClasses.matrix}>
        <div ref={matrixHeaderRowRef} className={matrixClasses.matrixRow}>
          <div></div>
          {form.columns.map((col) => (
            <p key={col.id}>{col.label}</p>
          ))}
        </div>

        {form.rows.map((row, rowIndex) => {
          return (
            <FormMatrixRow
              key={`${row.id}-${rowIndex}`}
              form={form}
              row={row}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FormMatrix;
