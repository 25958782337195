import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import containerClasses from "src/pages/PreInterviewPage/container.module.scss";

export const OverlayContext = React.createContext({ text: '' });

export const Overlay = () => {
  const overlay = useContext(OverlayContext);
  const { t } = useTranslation('preInterview');

  return <div className={containerClasses.container} style={{ visibility: overlay.text !== '' ? 'visible' : 'hidden', zIndex: 1024, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, background: 'rgba(9, 33, 60, 0.1)' }} id='mother-of-modals' onClick={() => overlay.text = ''}>
    <div className="h-screen max-h-screen overflow-hidden flex flex-col items-center justify-center">
      <div className={containerClasses.consentContainer} dangerouslySetInnerHTML={{ __html: overlay.text}} />
      <div className={containerClasses.infoText}>{t('tips.clickToClose')}</div>
    </div>
  </div>
};
