import { useAppSelector } from "src/store/hooks";
import CandidateHistoryMessage from "./CandidateHistoryMessage";
import HubertHistoryMessage from "./HubertHistoryMessage";

const MessageHistory = () => {
  const history = useAppSelector((state) => state.Messages.history);

  if (history.length === 0) {
    return null;
  }

  return (
    <section>
      {history.map((historyMessage, idx) => {
        const historyMessageList =
          typeof historyMessage.list === "string"
            ? [historyMessage.list]
            : historyMessage.list;

        return historyMessage.sender === "hubert" ? (
          <HubertHistoryMessage
            key={`HubertHistoryMessage-container-${idx}`}
            messages={historyMessageList}
            createdAt={historyMessage.createdAt}
          />
        ) : (
          <CandidateHistoryMessage
            key={`CandidateHistoryMessage-container-${idx}`}
            message={historyMessage}
          />
        );
      })}
    </section>
  );
};

export default MessageHistory;
