import chroma from "chroma-js";
import { useLayoutEffect } from "react";
import { useAppSelector } from "src/store/hooks";

export const HubertBasePalette = {
  primary: { hex: "#794dff", hsl: [256, 1, 0.65] },
  primaryDark: { hex: "#4f18bf", hsl: [256, 0.59, 0.51] },
  primaryLight: { hex: "#8f66ff", hsl: [256, 1, 0.73] },
  primaryExtraLight: { hex: "#c8b4ff", hsl: [256, 1, 0.98] },
  primaryNeutral: { hex: "#f4f2ff", hsl: [249, 1, 97] },
};

export type HexColor =
  | `#${number}${number}${number}${number}${number}${number}`
  | `#${number}${number}${number}`;

type HslColor = [hue: number, saturation: number, lightnesss: number];

const getShades = (color: HslColor) => {
  const getLightnessDifferenceOf = (
    primaryVariant: Exclude<keyof typeof HubertBasePalette, "primary">
  ) => {
    const primaryColor = HubertBasePalette.primary.hsl[2];
    const primaryVariantColor = HubertBasePalette[primaryVariant].hsl[2];
    return primaryColor - primaryVariantColor;
  };

  const [hue, saturation, lightness] = color;
  const shadeDifferences = {
    extraLight: getLightnessDifferenceOf("primaryExtraLight"),
    light: getLightnessDifferenceOf("primaryLight"),
  };

  return {
    extraLight: [
      hue,
      saturation,
      lightness + shadeDifferences.extraLight,
    ] as HslColor,
    light: [hue, saturation, lightness + shadeDifferences.light] as HslColor,
  };
};

const useTheme = () => {
  const baseTheme = useAppSelector((state) => state.LayoutControl.baseTheme);

  useLayoutEffect(() => {
    const rootElement = window.document.documentElement.querySelector("body");

    if (baseTheme.primaryColor === HubertBasePalette.primary.hex) {
      return;
    }

    if (rootElement && baseTheme.primaryColor) {
      const primaryColor = chroma.valid(baseTheme.primaryColor)
        ? baseTheme.primaryColor
        : HubertBasePalette.primary.hex;

      const targetColorAsHsl = chroma.hex(primaryColor).hsl();

      const [hue, saturation, lightness] = targetColorAsHsl;
      const textColor =
        chroma.contrast(chroma.hsl(hue, saturation, lightness), "white") < 4.5
          ? "var(--color-gray-900)"
          : "var(--color-white)";
      const shades = getShades([hue, saturation, lightness]);

      const colorVariableMap = {
        "--color-primary": chroma.hsl(hue, saturation, lightness).css(),
        "--color-primary-text": textColor,
        "--color-primary-extra-light": `hsl(${shades.extraLight[0]}, ${shades.extraLight[1]}%, ${shades.extraLight[2]}%)`,
        "--color-primary-light": `hsl(${shades.light[0]}, ${shades.light[1]}%, ${shades.light[2]}%)`,
        "--color-primary-dark": chroma
          .hsl(hue, saturation, lightness)
          .darken(1)
          .css(),
        "--color-primary-neutral": chroma
          .hsl(hue, saturation, lightness)
          .brighten(3)
          .css(),
      };

      for (const [key, value] of Object.entries(colorVariableMap)) {
        rootElement.style.setProperty(key, value);
      }
    }
  }, [baseTheme.primaryColor]);
};

export default useTheme;
