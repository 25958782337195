import clsx from 'clsx';
import { ComponentProps } from 'react';
import { Outlet } from 'react-router-dom';
import mainLayoutClasses from './mainLayout.module.scss';
import { Toaster } from 'sonner';

type MainLayoutProps = ComponentProps<'div'>;

const MainLayout = ({ className, ...props }: MainLayoutProps) => {
  return (
    <>
      <div {...props} className={clsx(className, mainLayoutClasses.container)}>
        <Outlet />
      </div>
      <Toaster />
    </>
  );
};

export default MainLayout;
