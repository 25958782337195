import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface IPreInterviewContext {
  currentStep: number;
  numberOfSteps: number;
  setNumberOfSteps: Dispatch<SetStateAction<number>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

export const PreInterviewContext = createContext<IPreInterviewContext>({
  currentStep: 0,
  numberOfSteps: 4,
  setNumberOfSteps: () => void 0,
  setCurrentStep: () => void 0,
});

export default function PreInterviewProvider({ children }: PropsWithChildren) {
  const [currentStep, setCurrentStep] = useState(0);
  const [numberOfSteps, setNumberOfSteps] = useState(4);

  return (
    <PreInterviewContext.Provider
      value={{ currentStep, setCurrentStep, numberOfSteps, setNumberOfSteps }}
    >
      {children}
    </PreInterviewContext.Provider>
  );
}

export const usePreInterviewContext = () => {
  const context = useContext(PreInterviewContext);

  if (!context) {
    throw new Error(
      'usePreInterviewContext must be used within a PreInterviewProvider'
    );
  }

  return context;
};
