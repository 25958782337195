import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { RequestSuccess } from "src/types/global/ApiResponse";
import axiosBaseQuery from "../common/axiosBaseQuery";
import { ClientConfiguration, ClientId } from "./queries/types";

const ClientApiReducerPath = "clientApi" as const;

const ClientApi = createApi({
  reducerPath: ClientApiReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL!}/v2/client`,
  }),
  endpoints: (builder) => ({
    getClientConfiguration: builder.query<
      RequestSuccess<ClientConfiguration>,
      ClientId
    >({
      query(clientId) {
        return {
          url: `/${clientId}/configuration`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetClientConfigurationQuery } = ClientApi;
export const clientApiEndpoints = ClientApi.endpoints;
export default ClientApi;
