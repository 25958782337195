import { useCallback, useEffect } from "react";

type GenericOSDeviceType = "ios" | "android" | "windows" | "macos" | "linux";
type PredefinedActionName = "prevent-ios-zoom";

interface UsePlatformActionProps {
  platformAction?: {
    os: GenericOSDeviceType | GenericOSDeviceType[];
    task: () => void;
  };
  runAction?: PredefinedActionName;
}

export const usePlatformAction = ({
  platformAction,
  runAction,
}: UsePlatformActionProps) => {
  const getPlatformNavigatorString = useCallback(
    (platforms: GenericOSDeviceType[]) => {
      const navigatorPlatformAliases: string[] = [];
      if (navigator.userAgentData) {
        platforms.forEach((p) => {
          switch (p) {
            case "ios":
              navigatorPlatformAliases.push("iPhone");
              break;

            case "android":
              navigatorPlatformAliases.push("Android");
              break;

            case "windows":
              navigatorPlatformAliases.push("Win32");
              break;

            case "macos":
              navigatorPlatformAliases.push("MacIntel");
              break;

            case "linux":
              navigatorPlatformAliases.push("Linux");
              break;

            default:
              break;
          }
        });

        return navigatorPlatformAliases;
      }

      platforms.forEach((p) => {
        switch (p) {
          case "ios":
            navigatorPlatformAliases.push("iPhone", "iPad", "iPod");
            break;

          case "android":
            navigatorPlatformAliases.push("Android", "Linux");
            break;

          case "windows":
            navigatorPlatformAliases.push("Windows");
            break;

          case "macos":
            navigatorPlatformAliases.push("Macintosh");
            break;

          case "linux":
            navigatorPlatformAliases.push("X11", "Linux", "CrOS");
            break;

          default:
            break;
        }
      });

      return navigatorPlatformAliases;
    },
    []
  );

  const shouldRunTask = useCallback((userAgentStrings: string[]) => {
    let shouldRun = false;

    if (navigator.userAgentData) {
      const platform = navigator.userAgentData.platform;
      return userAgentStrings.some((u) => platform.includes(u));
    }

    userAgentStrings.forEach((u) => {
      if (navigator.userAgent.includes(u)) {
        shouldRun = true;
      }
    });

    return shouldRun;
  }, []);

  useEffect(() => {
    if (runAction === "prevent-ios-zoom") {
      const metaViewport = document.querySelector("[name=viewport]");
      const defaultViewport = "width=device-width, initial-scale=1.0";
      let isIos = false;

      if (navigator.userAgentData) {
        isIos = navigator.userAgentData.platform === "iPhone";
      } else {
        isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
      }

      if (isIos) {
        metaViewport?.setAttribute(
          "content",
          `${defaultViewport} maximum-scale=1.0`
        );
      } else {
        metaViewport?.setAttribute("content", defaultViewport);
      }
    }
  }, [runAction]);

  useEffect(() => {
    if (platformAction) {
      const platformActionOs = Array.isArray(platformAction.os)
        ? platformAction.os
        : [platformAction.os];
      const platformNavigators = getPlatformNavigatorString(platformActionOs);
      const shouldRun = shouldRunTask(platformNavigators);

      if (shouldRun) {
        platformAction.task();
      }
    }
  }, [getPlatformNavigatorString, platformAction, shouldRunTask]);
};
