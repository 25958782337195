import { ErrorBoundary } from "react-error-boundary";

interface FormElementBoundaryProps {
  name: string;
  withComponent: React.ReactNode;
}

const FormElementBoundary = ({
  name,
  withComponent,
}: FormElementBoundaryProps) => {
  return (
    <ErrorBoundary
      fallbackRender={(props) =>
        process.env.NODE_ENV === "development" && (
          <p>
            Failed to render {name} component {JSON.stringify(props.error)}
          </p>
        )
      }
    >
      {withComponent}
    </ErrorBoundary>
  );
};

export default FormElementBoundary;
