import { useAppSelector } from "src/store/hooks";

// types
import { TInterview } from "src/store/slices/InterviewSlice";


export const useInterviewLanguage = (): TInterview['state']['language'] => {
  const language = useAppSelector((state) => state.Interview.state.language);
  return language;
};


export const useInterviewState = (): TInterview['state']['name'] => {
  const name = useAppSelector((state) => state.Interview.state.name);
  return name;
};

