import { z } from "zod";

const EnvironmentVariableSchema = z.object({
  REACT_APP_URL: z.string().url(),
  REACT_APP_ENVIRONMENT: z.enum(["DEVELOPMENT", "TEST", "PRODUCTION"]),
  REACT_APP_CHAT_VERSION: z.string().optional(),
  REACT_APP_CANDIDATE_HUB_URL: z.string().url(),
  REACT_APP_HIDE_INTRO: z.string().optional(),
});

const useEnvironment = () => {
  return {
    env: EnvironmentVariableSchema.parse(process.env),
  };
};

export default useEnvironment;
