import { cva } from "class-variance-authority";
import classes from "./TextField.module.scss";

export type CommonFieldProps = {
  error?: string;
};

const fromVariants = cva(classes.hubertTextField, {
  variants: {
    inputType: {
      textarea: classes.textarea,
      input: undefined,
    },
    fullWidth: {
      true: classes.expanded,
      false: undefined,
    },
    theme: {
      default: classes.default,
      error: classes.error,
    },
    edges: {
      default: classes["fieldRadius-default"],
      rounded: classes["fieldRadius-rounded"],
    },
    flexibleHeight: {
      true: classes.flexibleHeight,
      false: undefined,
    },
  },
  defaultVariants: {
    inputType: "input",
    edges: "default",
    theme: "default",
  },
});

export default fromVariants;
