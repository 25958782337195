import SimulateMessage from "src/components/Messages/SimulateMessage";
import { History } from "src/types/interview";

interface HubertHistoryMessageProps {
  messages: History["list"];
  createdAt?: string;
}

const HubertHistoryMessage = ({
  messages,
  createdAt,
}: HubertHistoryMessageProps) => {
  if (Array.isArray(messages) && typeof messages[0] === "string") {
    return (
      <div className="hubert-history">
        <div className={"hubert-avatar"}>H</div>
        <div className="flex-1">
          {(messages as string[])?.map((answer, idx) => {
            return (
              <SimulateMessage
                key={`hubert-history-message-answer-idx-${idx}`}
                wait={0}
                sender="hubert"
                createdAt={createdAt}
                bubble={answer}
                is_last_message={idx === messages.length - 1}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <SimulateMessage
      wait={0}
      sender="candidate"
      createdAt={createdAt}
      bubble={messages as string}
      is_last_message
    />
  );
};

export default HubertHistoryMessage;
