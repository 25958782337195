import { VariantProps } from "class-variance-authority";
import { ComponentProps, MutableRefObject, useId } from "react";
import TextAreaField from "./TextAreaField";
import fromVariants, { CommonFieldProps } from "./TextField.variants";

type TextFieldProps = VariantProps<typeof fromVariants> &
  ComponentProps<"input"> &
  CommonFieldProps & {
    innerRef?: MutableRefObject<HTMLInputElement>;
  };

const TextField = ({
  id,
  theme,
  edges,
  className,
  inputType,
  innerRef,
  fullWidth,
  ...textfieldProps
}: TextFieldProps) => {
  const textFieldId = useId();

  return (
    <input
      id={id ?? textFieldId}
      ref={innerRef}
      {...textfieldProps}
      className={fromVariants({
        theme,
        edges,
        className,
        fullWidth,
      })}
    />
  );
};

// Attach TextArea component as compound component
TextField.Area = TextAreaField;
TextField.Area.displayName = "TextField.Area";

TextField.displayName = "TextField";

export default TextField;
