import TextField from "src/components/common/TextField";
import { useFormContext } from "src/components/Prompts/Form/providers/FormProvider";
import { NumberFormValue } from "src/components/Prompts/Form/providers/FormProvider/types";
import { NumberForm } from "src/types/forms";
import { Optional } from "src/types/global";
import { FormError } from "../common/FormError";
import { FormLabel } from "../common/FormLabel";
import { FormComponentProps } from "../common/types";
import fieldStyles from "../FormControl.module.scss";
import numberFieldStyles from "./FormNumber.module.scss";

type FormNumberProps = FormComponentProps<NumberForm>;

const FormNumber = ({ form }: FormNumberProps) => {
  const [{ values }, { addValue, getImmediateError }] = useFormContext();
  const formError = getImmediateError(form.id);
  const formValue = values[form.id] as Optional<NumberFormValue>;

  return (
    <div className={fieldStyles.formGroup}>
      <div className={fieldStyles.fieldLabel}>
        <FormLabel label={form.label} is_mandatory={form.is_mandatory} />
      </div>
      <TextField
        type="number"
        pattern="\d*"
        placeholder={form.placeholder ?? form.label}
        className={numberFieldStyles.numberField}
        onChange={(e) =>
          addValue({
            [form.id]: {
              id: form.id,
              value: parseInt(e.target.value),
              updated_at: new Date(),
              is_mandatory: form.is_mandatory,
              hubiverse_property: form.hubiverse_property,
              candidate_property: form.candidate_property,
            },
          })
        }
        value={formValue?.value ?? ""}
      />
      {formError && <FormError error={formError} />}
    </div>
  );
};

export default FormNumber;
