import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer
} from "react";
// hooks
import { useTranslation } from "react-i18next";
import { useSendFormPayloadsMutation } from "src/services/interview";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import useCandidate from "src/hooks/useCandidate";

// slices
import {
  moveToHistory,
  nextMessage,
  setCurrent,
  setPending,
  toggleHubertTyping,
  toggleRendering
} from "src/store/slices/MessageSlice";

// reducers
import formProviderReducer from "./reducers";
// types
import { EMessageSender } from "src/types/payload.types";
import { FormMatrixRowValue } from "src/components/Prompts/Form/components/matrix/types";
import {
  SendFormPayloadItem,
  SendFormPayloadsRequest
} from "src/services/interview/mutations/types";
import { Next } from "src/store/types/Message/messageState";
import { FormType } from "src/types/forms";
import { Optional } from "src/types/global";
import {
  FormActions,
  FormOperations,
  IFormContext,
  IFormProviderProps,
  SetFieldErrorAction
} from "./types";
// other
import { constructNextQuestion } from "./utils";
import FormValidator, { ValidateOptions } from "./validators";

const FormContext = createContext<IFormContext>({} as IFormContext);

const FormProvider = ({ children, message }: IFormProviderProps) => {
  const appDispatch = useAppDispatch();
  const interviewId = useAppSelector((state) => state.Interview._id);
  const candidate = useCandidate();

  const question = useAppSelector((state) => state.Messages.current);
  const [sendFormPayloads] = useSendFormPayloadsMutation();
  const { t } = useTranslation("formErrors");

  const [state, dispatch] = useReducer(formProviderReducer, {
    forms: message.forms,
    values: message.forms.reduce((acc, form) => {
      const currentFormIndex = message.forms.indexOf(form);
      const currentForm = message.forms[currentFormIndex];

      acc[form.id] = {
        id: form.id,
        is_mandatory: form.is_mandatory,
        hubiverse_property: form.hubiverse_property,
        candidate_property: form.candidate_property,
      };

      switch (form.type) {
        case FormType.Slider:
          acc[form.id].value = currentForm?.min ?? 0;
          break;

        case FormType.Range:
          acc[form.id].value = [currentForm?.min ?? 0, currentForm?.max ?? 0];
          acc[form.id].range_min = currentForm?.min ?? 0;
          acc[form.id].range_max = currentForm?.max ?? 0;
          break;

        case FormType.Checkbox:
          acc[form.id].checked = false;
          break;

        case FormType.Text:
        case FormType.Email:
          acc[form.id].value = "";
          break;

        default:
          break;
      }

      return acc;
    }, {} as any),
    errors: {},
  });

  const validate = useCallback(() => {
    let hasNoError = true;

    for (let i = 0; i < state.forms.length; i++) {
      const form = { ...state.forms[i] };

      let errorPayload: SetFieldErrorAction["payload"] = {
        fieldId: form.id,
        error: null,
      };
      const commonValidationOptions: ValidateOptions = {
        required: form.is_mandatory,
      };

      switch (form.type) {
        case "text":
          errorPayload.error = FormValidator(t).validateText(
            state.values[form.id]?.value,
            {
              ...commonValidationOptions,
              minLength: form.min,
              maxLength: form.max,
            }
          ).error;
          break;

        case "number":
          errorPayload.error = FormValidator(t).validateNumber(
            state.values[form.id]?.value,
            {
              ...commonValidationOptions,
              min: form.min,
              max: form.max,
            }
          ).error;
          break;

        case "email":
          errorPayload.error = FormValidator(t).validateEmail(
            state.values[form.id]?.value,
            commonValidationOptions
          ).error;
          break;

        case "slider":
          errorPayload.error = FormValidator(t).validateNumber(
            state.values[form.id]?.value,
            {
              ...commonValidationOptions,
              min: form.min,
              max: form.max,
            }
          ).error;
          break;
        case "range":
          errorPayload.error = FormValidator(t).validateRange(
            state.values[form.id]?.value,
            {
              ...commonValidationOptions,
              min: form.min,
              max: form.max,
            }
          ).error;
          break;

        case "checkbox":
          errorPayload.error = FormValidator(t).validateCheckbox(
            state.values[form.id]?.checked,
            commonValidationOptions
          ).error;
          break;

        case "matrix":
          {
            const currentFormValue = state.values[form.id];
            const rowValuesExist = currentFormValue?.row_values != null;
            const matrixRows: Optional<FormMatrixRowValue[]> = rowValuesExist
              ? Object.values(currentFormValue?.row_values)
              : undefined;
            errorPayload.error = FormValidator(t).validateMatrix(matrixRows, {
              ...commonValidationOptions,
              minLength: state.forms.find((f) => f.id === form.id)?.rows.length,
            }).error;
          }
          break;
        default:
          break;
      }

      dispatch({
        type: FormActions.SET_FIELD_ERROR,
        payload: {
          fieldId: errorPayload.fieldId,
          ...(errorPayload.error != null ? { error: errorPayload.error } : {}),
        },
      });

      if (errorPayload.error) {
        hasNoError = false;
      }
    }
    return hasNoError;
  }, [state.forms, state.values, t]);

  const send = useCallback(async () => {
    if (!interviewId || !question?.language) {
      return;
    }

    const formData: Omit<SendFormPayloadsRequest, "candidate"> = {
      interview_id: interviewId,
      qutter_oid: question?._id,
      qutter_id: question?.id,
      payload: [],
    };

    let nextQuestion: ReturnType<typeof constructNextQuestion> | null = null;
    const candidateAnswers: Next[] = [];

    for (let i = 0; i < message.forms.length; i++) {
      const form = message.forms[i];
      const payload: SendFormPayloadItem = {
        form_id:    form.id,
        form_type:  form.type,
        expected_language: question.language,
        language: question.language,
        sender:   EMessageSender.Candidate,
        type:     question.type,
      };

      switch (form.type) {
        case FormType.Number:
          nextQuestion = constructNextQuestion({
            form,
            payload,
            value: state.values[form.id],
            type: "number",
          });
          break;

        case FormType.Checkbox:
          nextQuestion = constructNextQuestion({
            form,
            payload,
            value: state.values[form.id],
            type: "checkbox",
          });
          break;

        case FormType.Range:
          nextQuestion = constructNextQuestion({
            form,
            value: state.values[form.id],
            payload,
            type: "range",
          });
          break;

        case FormType.Matrix:
          nextQuestion = constructNextQuestion({
            form,
            payload,
            value: state.values[form.id],
            type: "matrix",
          });
          break;

        case FormType.File:
          nextQuestion = constructNextQuestion({
            form,
            payload,
            value: state.values[form.id],
            type: "file",
          });
          break;

        default:
          nextQuestion = constructNextQuestion({
            form,
            payload,
            value: state.values[form.id],
          });
          break;
      }

      if (form.type === FormType.Matrix && nextQuestion?.data) {
        formData.payload.push(...nextQuestion.data);
      } else {
        formData.payload.push(nextQuestion.payload);
      }

      candidateAnswers.push(nextQuestion.candidateAnswer);
    }

    if (candidateAnswers.length > 0) {
      const answers = candidateAnswers.flatMap((answer) => answer.answers);

      appDispatch(moveToHistory());
      appDispatch(
        nextMessage({
          next: {
            sender: EMessageSender.Candidate,
            answers,
          },
        })
      );
    }

    try {
      const response = await sendFormPayloads({
        ...formData,
        candidate,
      }).unwrap();

      appDispatch(toggleHubertTyping({ value: true }));
      appDispatch(toggleRendering({ value: true }));
      appDispatch(moveToHistory());

      const interviewState = response.interviewState;
      appDispatch(setCurrent({
        current:
          (interviewState && interviewState.current) ??
          response.data
      }));
      appDispatch(setPending({ 
        pending:
          (interviewState && interviewState.pending) ??
          response.pending
      }));
    } catch (e) {
      console.log("SendFormPayloads Error: ", e);
    }
  }, [
    interviewId,
    question?.language,
    question?._id,
    question?.id,
    question?.type,
    message.forms,
    state.values,
    appDispatch,
    sendFormPayloads,
    candidate,
  ]);

  const formOperations = useMemo(
    () =>
      ({
        addForm: (form: any) => {
          dispatch({ type: FormActions.ADD_FORM, payload: form });
        },
        addValue: (value: any) => {
          const formKeys = Object.keys(value);
          if (value && formKeys.length > 0) {
            dispatch({
              type: FormActions.CLEAR_FORM_ERROR,
              payload: formKeys[0],
            });
          }
          dispatch({ type: FormActions.ADD_VALUE, payload: value });
        },
        handleSendClick: async (e) => {
          e.preventDefault();
          if (validate()) send();
        },
        clearFormError: (formId) => {
          dispatch({ type: FormActions.CLEAR_FORM_ERROR, payload: formId });
        },
        getImmediateError: (formId) => {
          const error = state.errors?.[formId];

          if (error == null) {
            return null;
          }

          if (Array.isArray(error)) {
            return error[0];
          }

          return error;
        },
      } as FormOperations),
    [send, state.errors, validate]
  );

  const providerValue: IFormContext = useMemo(() => {
    return [state, formOperations];
  }, [formOperations, state]);

  return (
    <FormContext.Provider value={providerValue}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within FormProvider");
  }
  return context;
};

export default FormProvider;
