import { cva, VariantProps } from "class-variance-authority";
import clsx from "clsx";
import { ComponentProps } from "react";
import formStyles from "../FormControl.module.scss";

const labelCva = cva(formStyles.fieldLabel, {
  variants: {
    dense: {
      true: "mb-0",
      false: "mb-2",
    },
  },
  defaultVariants: {
    dense: false,
  },
});

type FormLabelProps = ComponentProps<"div"> & {
  label: string;
  is_mandatory?: boolean;
} & VariantProps<typeof labelCva>;

export const FormLabel = ({
  label,
  is_mandatory = false,
  className,
  dense,
  ...labelProps
}: FormLabelProps) => {
  return (
    <div {...labelProps} className={clsx(className, labelCva({ dense }))}>
      <div dangerouslySetInnerHTML={{ __html: `${label}` }}></div>
      {is_mandatory && <span>*</span>}
    </div>
  );
};
