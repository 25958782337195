import { useCallback, useEffect, useRef, useState } from "react";

const COUNTDOWN_TIMER_TICK_RATE = 1000;
interface CountdownMeta {
  expiry: number;
  count: number;
  state: "started" | "finished" | "idle";
}

interface UseCountdownReturn extends CountdownMeta {
  restart: (expiry?: number) => void;
}

type UseCountdownHook = (props: {
  initialExpiry?: number;
}) => UseCountdownReturn;

const useCountdown: UseCountdownHook = ({ initialExpiry }) => {
  const [{ count, expiry, state }, setMeta] = useState<CountdownMeta>({
    expiry: 0,
    count: 0,
    state: "idle",
  });
  const intervalRef = useRef<NodeJS.Timer>();

  const reduceTimerCount = useCallback(() => {
    setMeta((prev) => ({
      ...prev,
      count: prev.count - 1,
      state: prev.state !== "started" ? "started" : prev.state,
    }));
  }, []);

  const restart = (expiry?: number) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(
      reduceTimerCount,
      COUNTDOWN_TIMER_TICK_RATE
    );

    setMeta((prev) => ({
      ...prev,
      count: Math.ceil(expiry ?? prev.expiry),
      expiry: Math.ceil(expiry ?? prev.expiry),
    }));
  };

  useEffect(() => {
    if (initialExpiry && expiry === 0) {
      setMeta({
        expiry: Math.ceil(initialExpiry),
        count: Math.ceil(initialExpiry),
        state: "idle",
      });
    }

    if (!intervalRef.current && expiry > 0) {
      intervalRef.current = setInterval(
        reduceTimerCount,
        COUNTDOWN_TIMER_TICK_RATE
      );
      setMeta((prev) => ({ ...prev, state: "started" }));
    }
  }, [initialExpiry, expiry, reduceTimerCount]);

  useEffect(() => {
    if (count === 0) {
      setMeta((prev) => ({ ...prev, state: "finished" }));
      clearInterval(intervalRef.current);
    }
  }, [count]);

  return {
    count,
    expiry,
    state,
    restart,
  };
};

export default useCountdown;
