import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Method } from "axios";

// slices
import { updateCandidate } from "src/store/slices/CandidateSlice";
// types
import { ClosedAnswer, ICustomRequest, OpenAnswer } from "src/store/types/Answers";
import {
  SendFormPayloadsRequest,
  SendReusablesRequest,
} from "./types";
import { TChatOnlinePayload } from "src/types/payload.types";

export type InterviewMutationLifecycle<P, R> = MutationLifecycleApi<
  P,
  BaseQueryFn<{
    url: string;
    method: Method | undefined;
    data?: any;
    params?: any;
  }>,
  R,
  "interviewApi"
>;


async function handleSendClosedMessageMutationStarted(
  arg: ClosedAnswer,
  api: InterviewMutationLifecycle<ClosedAnswer, TChatOnlinePayload>
) {
  try {
    const fulfilledQuery = await api.queryFulfilled;

    if (fulfilledQuery.data) {
      api.dispatch(
        updateCandidate({
          ...fulfilledQuery.data.candidate,
          onlyUpdateOnMismatch: true,
        })
      );
    }
  } catch (e) {
    console.error({
      message: "SendCloseMessage mutation failed",
      details: {
        type: arg.type,
        text: arg.text,
        label: arg.label,
      },
      trace: e,
    });
  }
}


async function handleSendOpenMessageMutationStarted(
  arg: OpenAnswer,
  api: InterviewMutationLifecycle<OpenAnswer, TChatOnlinePayload>
) {
  try {
    const fulfilledQuery = await api.queryFulfilled;

    if (fulfilledQuery.data) {
      api.dispatch(
        updateCandidate({
          ...fulfilledQuery.data.candidate,
          onlyUpdateOnMismatch: true,
        })
      );
    }
  } catch (e) {
    console.error({
      message: "SendOpenMessage mutation failed",
      details: {
        type: arg.type,
        qutterId: arg.qutter_id,
      },
      trace: e,
    });
  }
}


async function handleSendFormPayloads(
  arg: SendFormPayloadsRequest,
  api: InterviewMutationLifecycle<any, TChatOnlinePayload>
) {
  try {
    const fulfilledQuery = await api.queryFulfilled;

    if (fulfilledQuery.data) {
      const candidate = fulfilledQuery.data.candidate;
      const isInSync = ["_id", "first_name", "last_name", "email"].every(
        (property) =>
          fulfilledQuery.data.candidate[property as keyof typeof candidate] ===
          arg.candidate[property as keyof typeof candidate]
      );
      if (!isInSync) {
        api.dispatch(
          updateCandidate({
            ...fulfilledQuery.data.candidate,
            onlyUpdateOnMismatch: true,
          })
        );
      }
    }
  } catch (e) {}
}


async function handleSendReusableMutationStarted(
  arg: SendReusablesRequest,
  api: InterviewMutationLifecycle<SendReusablesRequest, TChatOnlinePayload>
) {
  try {
    const fulfilledQuery = await api.queryFulfilled;

    if (fulfilledQuery.data) {
      api.dispatch(
        updateCandidate({
          ...fulfilledQuery.data.candidate,
          onlyUpdateOnMismatch: true,
        })
      );
    }
  } catch (e) {
    console.error({
      message: "SendReusableMessage mutation failed",
      details: {
        interviewId: arg.interview_id,
      },
      trace: e,
    });
  }
}


async function handleSendCustomRequestMutationStarted(
  arg: ICustomRequest,
  api: InterviewMutationLifecycle<ICustomRequest, TChatOnlinePayload>
) {
  try {
    const fulfilledQuery = await api.queryFulfilled;

    if (fulfilledQuery.data) {
      api.dispatch(
        updateCandidate({
          ...fulfilledQuery.data.candidate,
          onlyUpdateOnMismatch: true,
        })
      );
    }
  } catch (e) {
    console.error({
      message: "Custom Request mutation failed",
      details: {
        // type: arg.type,
        // qutterId: arg.qutter_id,
      },
      trace: e,
    });
  }
}


const MutationHandlers = {
  handleSendClosedMessageMutationStarted,
  handleSendOpenMessageMutationStarted,
  handleSendReusableMutationStarted,
  handleSendFormPayloads,
  handleSendCustomRequestMutationStarted,
};

export default MutationHandlers;
