export const PreInterviewApiErrorCodes = {
  CandidateNotFound: "hub-chat-candidate-not-found",
  OtpExpired: "hub-chat-otp-expired",
  OtpInvalid: "hub-chat-otp-invalid",
} as const;

export type PreInterviewApiErrorCode =
  (typeof PreInterviewApiErrorCodes)[keyof typeof PreInterviewApiErrorCodes];

export const JobApiErrorCodes = {
  JobNotFound: "hub-chat-job-not-found",
} as const;

export type JobApiErrorCode =
  (typeof JobApiErrorCodes)[keyof typeof JobApiErrorCodes];
