import Calendly from "./Calendly";
import DatePicker from "./Datepicker";
import FillInTheGaps from "./FillInTheGaps";
import Forms from "./Form";
import MultipleSelect from "./MultipleSelect";
import Open from "./Open";
import Reusable from "./Reusables";
import SingleSelect from "./SingleSelect";

const Prompts = {
  DatePicker,
  FillInTheGaps,
  Forms,
  MultipleSelect,
  Open,
  Reusable,
  SingleSelect,
  Calendly,
};

export default Prompts;
