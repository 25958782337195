import clsx from "clsx";
import { ComponentProps, forwardRef } from "react";
import FormControlDecorator from "src/components/common/FormControlDecorator";
import inputClasses from "./Input.module.scss";

interface InputProps extends ComponentProps<"input"> {
  error?: string;
  helperText?: string;
  inputClassName?: string;
  alignment?: ComponentProps<typeof FormControlDecorator>["alignment"];
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { error, helperText, className, inputClassName, alignment, ...inputProps },
    ref
  ) => {
    return (
      <FormControlDecorator
        expanded
        {...{ error, helperText, className, alignment }}
      >
        <input
          {...inputProps}
          ref={ref}
          className={clsx(inputClasses.preInterviewInput, inputClassName, {
            [inputClasses.error]: error,
          })}
        />
      </FormControlDecorator>
    );
  }
);

export default Input;
