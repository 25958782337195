import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { generatePath } from 'react-router-dom';
import { ApiResponse, RequestSuccess } from 'src/types/global/ApiResponse';
import axiosBaseQuery from '../common/axiosBaseQuery';
import {
  ATSApplyConfiguration,
  GetApplicationJobResponse,
  GetInterviewFromJobRequest,
  GetInterviewFromJobResponse,
  JobResponse,
} from './queries/types';
import { jobApi as jobApiV1 } from './v1';

export const JobApiReducerPath = 'jobApiV2' as const;

const jobApi = createApi({
  reducerPath: JobApiReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL!}/v2/job`,
  }),
  endpoints: (builder) => ({
    job: builder.query<RequestSuccess<JobResponse>, string>({
      query: (jobId) => ({
        url: `/${jobId}`,
        method: 'GET',
      }),
      transformErrorResponse: (err) => ({
        ...err,
        message: ApiResponse.getErrorMessage(err),
      }),
    }),
    uploadDocument: builder.mutation<
      RequestSuccess<any>,
      { jobId: string; file: File; documentType: string }
    >({
      query: ({ jobId, file, documentType }) => {
        const url = generatePath('/:jobId/documents/uploads', {
          jobId,
        });

        const form = new FormData();

        form.append(documentType, file);

        return {
          url: url,
          method: 'POST',
          data: form,
        };
      },
    }),
    interviewByJobId: builder.mutation<
      RequestSuccess<GetInterviewFromJobResponse>,
      GetInterviewFromJobRequest
    >({
      query: (details) => {
        const url = generatePath('/:jobId/:applicationType?/interview', {
          applicationType: details.applicationType ?? null,
          jobId: details.jobId,
        });

        return {
          url,
          method: 'POST',
          data: details.userDetails,
          headers: {
            Authorization: details.candidateToken
              ? `bearer ${details.candidateToken}`
              : '',
          },
        };
      },
      transformErrorResponse: (err) => ({
        ...err,
        message: ApiResponse.getErrorMessage(err),
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    myHubertInterviewByJobId: builder.mutation<
      RequestSuccess<GetInterviewFromJobResponse>,
      GetInterviewFromJobRequest
    >({
      query: (details) => {
        const url = generatePath(
          '/:jobId/:applicationType?/my-hubert-interview',
          {
            applicationType: details.applicationType ?? null,
            jobId: details.jobId,
          }
        );

        return {
          url,
          method: 'POST',
          data: details.userDetails,
          headers: {
            Authorization: details.candidateToken
              ? `bearer ${details.candidateToken}`
              : '',
          },
        };
      },
      transformErrorResponse: (err) => ({
        ...err,
        message: ApiResponse.getErrorMessage(err),
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    getAtsJob: builder.query<
      RequestSuccess<GetApplicationJobResponse>,
      ATSApplyConfiguration
    >({
      query: (config) => ({
        url: `/ats`,
        method: 'PUT',
        data: config,
      }),
      transformErrorResponse: (err) => ({
        ...err,
        message: ApiResponse.getErrorMessage(err),
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const queryResult = await queryFulfilled;
        const { jobId, ...jobData } = queryResult.data.data;
        dispatch(
          jobApi.util.upsertQueryData('job', jobId, {
            data: jobData,
            message: queryResult.data.message,
          })
        );
      },
    }),
  }),
});

export const {
  useJobQuery,
  useLazyJobQuery,
  useInterviewByJobIdMutation,
  useMyHubertInterviewByJobIdMutation,
  useGetAtsJobQuery,
  useUploadDocumentMutation,
} = jobApi;

const JobApi = { v1: jobApiV1, v2: jobApi };

export default JobApi;
