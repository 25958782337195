import { zodResolver } from '@hookform/resolvers/zod';
import { animated } from '@react-spring/web';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useRef, useState } from 'react';
import { ArrowRight, Loader } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'sonner';
import Input from 'src/components/PreInterview/Input';
import Button from 'src/components/common/Button';
import useDataSerializer from 'src/hooks/useDataSerializer';
import { ErrorPageProps } from 'src/pages/ErrorPage';
import containerClasses from 'src/pages/PreInterviewPage/container.module.scss';
import HubertChatError from 'src/services/common/HubertChatError';
import { AxiosBaseError } from 'src/services/common/axiosBaseQuery/types';
import { useJobQuery } from 'src/services/job';
import { Agreement } from 'src/services/job/queries/types';
import { useLazyGetCandidateQuery } from 'src/services/pre-interview';
import { PreInterviewApiErrorCodes } from 'src/services/pre-interview/errors';
import { PreInterviewCandidate } from 'src/services/pre-interview/queries/types';
import { Breakpoints } from 'src/utils/breakpoints';
import { z } from 'zod';
import { OverlayContext } from '../../../../Overlay';
import { usePreInterviewContext } from '../../PreInterviewProvider';
import { useRegistrationContext } from '../../RealTimeScreening/hooks/useRegistrationContext';
import { PreInterviewPageParams } from '../../types';
import useSlideSpring from '../useSlideSpring';

const EmailFormSchema = z.object({
  email: z
    .string({
      required_error: 'preInterview:getEmailStep.errors.emailRequired',
      invalid_type_error: 'preInterview:getEmailStep.errors.emailRequired',
      description: 'preInterview:getEmailStep.errors.emailRequired',
    })
    .email('preInterview:getEmailStep.errors.emailInvalid'),
});

type EmailFormTranslationKeys =
  | 'preInterview:getEmailStep.errors.emailRequired'
  | 'preInterview:getEmailStep.errors.emailInvalid';
type EmailForm = z.infer<typeof EmailFormSchema>;

export default function GetEmailStep() {
  const { jobId } = useParams<PreInterviewPageParams>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { serialize, deserialize } = useDataSerializer();
  const { setCurrentStep, currentStep, setNumberOfSteps } =
    usePreInterviewContext();

  const [getEmail, getCandidateQuery] = useLazyGetCandidateQuery();
  const { data: job, ...jobQuery } = useJobQuery(jobId ?? skipToken);

  const slideAnimation = useSlideSpring();
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.sm });
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation(['common', 'preInterview']);
  const { registrationState, setRegistrationState } = useRegistrationContext();
  const [consentGiven, setConsentGiven] = useState({
    items: new Set<string>(),
    ok: false,
  });

  const handleEmailSubmission = async ({ email }: EmailForm) => {
    try {
      let isMyHubertUser = false;

      const { data: getEmailData } = await getEmail({
        email,
        isMyHubertUserCheck: true,
      })
        .unwrap()
        .catch((e) => {
          if (e.status === 401) {
            isMyHubertUser = true;
          } else if (e.status === 404) {
            isMyHubertUser = false;
          } else {
            throw e;
          }

          return {
            data: {
              email: email,
            },
          };
        });

      type PossibleNextPaths = `../${
        | 'additional-details'
        | 'otp'
        | 'opt-sign-in'
        | 'upload-documents'}`;
      let nextPath: PossibleNextPaths = '../additional-details';

      const candidate: PreInterviewCandidate = getEmailData;
      if (isMyHubertUser && job) {
        nextPath = '../opt-sign-in';
      } else if (job?.data && job.data.featureFlags.otp) {
        nextPath = '../otp';
      } else if (
        job?.data.documentRequirements &&
        job.data.documentRequirements.length > 0
      ) {
        nextPath = '../upload-documents';
      }

      if (getEmailData) {
        // const serializedData = serialize<PreInterviewCandidate>(candidate);
        // searchParams.set("candidate", serializedData);
        setRegistrationState({
          ...registrationState,
          candidate: {
            email: candidate.email,
          },
          job: {
            title: job?.data.position ?? '',
            ...(job?.data.documentRequirements && {
              requiredDocuments: job.data.documentRequirements,
            }),
          },
        });

        navigate({
          pathname: nextPath,
          search: `?${searchParams.toString()}`,
        });
      }
    } catch (e) {
      const error = HubertChatError.safeParse(e as AxiosBaseError);

      if (error?.compareTo(PreInterviewApiErrorCodes.CandidateNotFound)) {
        const serializedData = serialize<EmailForm>({ email });
        setSearchParams({ candidate: serializedData });
        navigate({
          pathname: job?.data.featureFlags.otp
            ? '../otp'
            : '../additional-details',
          search: `?candidate=${serializedData}`,
        });
        return;
      }

      toast.error(
        t('preInterview:getEmailStep.errors.email_submission_failed'),
        {
          description: error?.message,
          dismissible: true,
        }
      );
    }
  };

  const getDefaultsFromUrlState = () => {
    const serializedCandidate = searchParams.get('candidate');
    if (serializedCandidate) {
      const candidate = deserialize<PreInterviewCandidate>(serializedCandidate);
      return candidate.email;
    }
  };

  useEffect(() => {
    currentStep !== 0 && setCurrentStep(0);
  }, [currentStep, setCurrentStep]);

  useEffect(() => {
    if (
      job &&
      job.data.documentRequirements &&
      job.data.documentRequirements.length > 0
    ) {
      setNumberOfSteps(5);
    }
  }, [job, setNumberOfSteps]);

  const emailForm = useForm<EmailForm>({
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    resolver: zodResolver(EmailFormSchema),
    defaultValues: {
      email: getDefaultsFromUrlState(),
    },
  });

  const overlay = useContext(OverlayContext);

  if (jobQuery.isError) {
    return (
      <Navigate
        to="/error"
        state={
          {
            title: 'Job not found',
            message: 'The job you are trying to access does not exist',
            redirect: `/rts/${jobId}/pre-interview/get-email`,
            status: 404,
          } as ErrorPageProps
        }
      />
    );
  }

  if (!job || jobQuery.isFetching) {
    return <Loader className="animate-spin" />;
  }

  const agreements = jobQuery.currentData?.data.terms ?? [];

  const getAgreementTemplate = (type: string) => {
    switch (type) {
      case 'privacy-policy':
        return t('preInterview:tips.agreeToPrivacy');
      case 'consent':
        return t('preInterview:tips.agreeToConsent');
      default:
        return '{{company}} - {{type}}';
    }
  };

  const fillTemplate = (type: string, data: { company: string }) => {
    return getAgreementTemplate(type).replace(
      /{{(company)}}/,
      (_, c) => (data as any)[c]
    );
  };

  const requiredConsents = agreements
    .filter((x) => x.type === 'consent' && x.required)
    .map((x) => x.id);
  const onCheckChange = (e: any) =>
    setConsentGiven((x) => {
      if (e.target.checked) x.items.add(e.target.id);
      else x.items.delete(e.target.id);

      return {
        items: x.items,
        ok: requiredConsents.every((id) => x.items.has(id)),
      };
    });

  const agreeableElement = (x: Agreement) => {
    const displayText = fillTemplate(x.type, x);
    if ('text' in x) {
      return (
        <div
          className={containerClasses.info}
          onClick={() => {
            overlay.text = x.text;
          }}
        >
          {displayText}
        </div>
      );
    }
    return (
      <Link className={containerClasses.info} to={x.link ?? ''} target="_blank">
        {displayText}
      </Link>
    );
  };

  const consents = agreements
    .filter((x) => x.type === 'consent')
    .map((x) => (
      <tr key={x.id}>
        <td>
          <input type="checkbox" id={x.id} onChange={onCheckChange} />
        </td>
        <td style={{ textAlign: 'left', paddingLeft: '0.5rem' }}>
          <label htmlFor={x.id}>{agreeableElement(x)}</label>
        </td>
      </tr>
    ));

  const implicitAgreements = agreements
    .filter((x) => x.type !== 'consent')
    .map((x) => agreeableElement(x));

  const consent =
    agreements.length > 0
      ? [
          <table key="agreements">
            <tbody>{consents}</tbody>
          </table>,
        ]
      : [];

  const allConsentsOk = requiredConsents.length === 0 || consentGiven.ok;

  return (
    <animated.section
      style={slideAnimation}
      className={containerClasses.container}
    >
      <form
        ref={formRef}
        onSubmit={(e) => {
          if (!allConsentsOk) {
            e.preventDefault();
            return;
          }
          const next = emailForm.handleSubmit(handleEmailSubmission);
          next(e);
        }}
      >
        <div className={containerClasses.intro}>
          <h1>{t('preInterview:getEmailStep.intro.title')}</h1>
          <p>{t('preInterview:getEmailStep.intro.subtitle')}</p>
        </div>
        <Input
          {...emailForm.register('email')}
          placeholder={t('preInterview:getEmailStep.inputPlaceholder')}
          enterKeyHint="go"
          inputMode="email"
          error={t(
            emailForm.formState.errors.email
              ?.message as EmailFormTranslationKeys
          )}
          disabled={getCandidateQuery.isFetching}
          alignment={isMobile ? 'left' : 'center'}
        />
        <div style={{ marginTop: '1rem' }}>{consent}</div>
      </form>

      <Button
        className={containerClasses.continueButton}
        onClick={() => formRef.current?.requestSubmit()}
        disabled={getCandidateQuery.isFetching || !allConsentsOk}
        loading={getCandidateQuery.isFetching}
      >
        {t('common:continue')}
      </Button>
      <div className="-mt-6">
        {implicitAgreements.length > 0 ? (
          <p className={containerClasses.infoText}>
            {t('preInterview:tips.privacyPolicyText')}
          </p>
        ) : null}
        <div style={{ paddingLeft: '0.25rem' }}>{implicitAgreements}</div>
      </div>
    </animated.section>
  );
}
