import { AxiosBaseError } from "src/services/common/axiosBaseQuery/types";

interface CommonRequest {
  message: string;
}

export interface RequestSuccess<T> extends CommonRequest {
  data: T;
}

export interface RequestError extends CommonRequest {
  errors: Array<{ message: string; path: string[] }>;
}

export class ApiResponse {
  static getErrorMessage(error: AxiosBaseError) {
    if (error.errors) {
      if (Array.isArray(error.errors)) {
        return error.errors[0].message;
      }

      return Object.values(
        error.errors as Record<string, { message: string }>
      ).map((e) => e.message)[0];
    }

    if (error.message) {
      return error.message;
    }

    return "Something went wrong";
  }
}
