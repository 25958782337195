import { animated } from '@react-spring/web';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'src/components/common/Button';
import useDataSerializer from 'src/hooks/useDataSerializer';
import { useJobQuery } from 'src/services/job';
import { usePreInterviewContext } from '../../PreInterviewProvider';
import { useRegistrationContext } from '../../RealTimeScreening/hooks/useRegistrationContext';
import containerClasses from '../../container.module.scss';
import { PreInterviewPageParams } from '../../types';
import useSlideSpring from '../useSlideSpring';

export default function OptInSignInStep() {
  const slideAnimation = useSlideSpring();
  const navigate = useNavigate();
  const { setCurrentStep } = usePreInterviewContext();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('preInterview');
  const { jobId } = useParams<PreInterviewPageParams>();
  const { data: job } = useJobQuery(jobId ?? skipToken);
  const { serialize } = useDataSerializer();
  const { registrationState } = useRegistrationContext();

  useEffect(() => {
    setCurrentStep(1);
  }, [setCurrentStep]);

  const handleSignInWithHubertClick = () => {
    const { job } = registrationState;
    let path: 'additional-details' | 'upload-documents' = 'additional-details';

    if (job?.requiredDocuments) {
      path = 'upload-documents';
    }
    const redirectUrl = new URL(
      `rts/${jobId}/${path}?${searchParams.toString()}`,
      window.location.origin
    );

    const title = job?.title ?? '';
    const candidateEmail = registrationState.candidate?.email;

    const jobParams = serialize({ id: jobId, title });
    const candidateParams = serialize({ email: candidateEmail });

    const targetUrl = new URL(
      '/instant-apply/pre-interview',
      process.env.REACT_APP_CANDIDATE_HUB_URL
    );
    targetUrl.searchParams.append('redirectUrl', redirectUrl.toString());
    targetUrl.searchParams.append('job', jobParams);
    targetUrl.searchParams.append('candidate', candidateParams);

    window.location.replace(targetUrl.toString());
  };

  const handleWithoutSignInClick = () => {
    type NextPath = `../${'additional-details' | 'otp' | 'upload-documents'}`;
    let nextPath: NextPath = '../additional-details';

    if (job?.data && job.data.featureFlags.otp) {
      nextPath = '../otp';
    } else if (job?.data.documentRequirements) {
      nextPath = '../upload-documents';
    }

    navigate({
      pathname: nextPath,
      search: searchParams.toString(),
    });
  };

  return (
    <animated.section
      style={slideAnimation}
      className={containerClasses.container}
    >
      <section className={containerClasses.intro}>
        <h1>{t('optionalSignIn.title')}</h1>
        <p>{t('optionalSignIn.subtitle')}</p>
      </section>
      <div className="flex flex-col gap-4">
        <Button
          iconLeft={
            <img
              src="/img/logo.svg"
              alt=""
              className="w-7 aspect-square invert"
            />
          }
          onClick={handleSignInWithHubertClick}
        >
          {t('optionalSignIn.signWithHubert')}
        </Button>
        <Button variant="neutral-outlined" onClick={handleWithoutSignInClick}>
          {t('optionalSignIn.signWithoutHubert')}
        </Button>
      </div>
    </animated.section>
  );
}
