import axios from 'axios';
import clsx from 'clsx';
import { ChangeEvent, useState } from 'react';
import { Check, X } from 'react-feather';
import { toast } from 'sonner';
import { useFormContext } from 'src/components/Prompts/Form/providers/FormProvider';
import { useAppSelector } from 'src/store/hooks';
import { FileUploadForm } from 'src/types/forms';
import { FormError } from '../common/FormError';
import { FormLabel } from '../common/FormLabel';
import { FormComponentProps } from '../common/types';
import formStyles from '../FormControl.module.scss';

type FormCheckboxProps = FormComponentProps<FileUploadForm>;

const FormFileUpload = ({ form }: FormCheckboxProps) => {
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [{ values }, { addValue, getImmediateError }] = useFormContext();
  const interview = useAppSelector((state) => state.Interview);
  const application = useAppSelector((state) => state.Application);
  const question = useAppSelector((state) => state.Messages.current);

  const handleUpload = async (file: ChangeEvent) => {
    if (!form.id) return;
    const { files } = file.target as HTMLInputElement;

    if (files && files.length !== 0) {
      const formData = new FormData();
      const data = JSON.stringify({
        interview_id: interview._id,
        application_id: application._id,
        job_id: application.job_id,
        candidate_id: application.candidate_id,
        qutter_oid: question?._id,
        qutter_id: question?.id,
        file_property: form.file_property,
      });
      formData.append('data', data);
      formData.append('uploadFile', files[0]);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: { loaded: any; total: any }) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(percentCompleted);
        },
      };

      const url2 = `${process.env.REACT_APP_URL!}/file-upload`;
      await axios
        .post(url2, formData, config)
        .then((response) => {
          if (response && response.data) {
            // toast.success('File uploaded.');
            setIsUploaded(true);
            setIsError(false);
            const data = {
              id: form.id,
              updated_at: new Date(),
              is_mandatory: form.is_mandatory ?? false,
              hubiverse_property: form.hubiverse_property,
              candidate_property: form.candidate_property,
              uploaded: response.data.document,
            };
            console.log('data', data);
            addValue({
              [form.id]: data,
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setIsError(true);
          setErrorMessage(JSON.stringify(error.response.data.message));
        });
    }
  };

  const formError = getImmediateError(form.id);
  // const formValue = values[form.id] as Optional<FileFormValue>;

  return (
    <div className={formStyles.formGroup}>
      <div className="flex items-center gap-2">
        <FormLabel label={form.label} is_mandatory={form.is_mandatory} dense />
      </div>

      <div className="flex justify-around">
        <div className="w-[80%]">
          <input
            onChange={handleUpload}
            className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-xs font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
            id={form.id}
            type="file"
          />
          <label
            htmlFor={form.id}
            className="mt-1 text-sm text-gray-500 inline-block text-neutral-700 dark:text-neutral-200"
          >
            {form.help_text}
          </label>
        </div>
        <div className="w-[10%]">
          <Check className={clsx('text-success', !isUploaded && 'hidden')} />
          <X className={clsx('text-danger', !isError && 'hidden')} />
        </div>
      </div>

      {formError && <FormError error={formError} className="!mt-2" />}
    </div>
  );
};

export default FormFileUpload;
