import { FC } from "react";

interface HeadingProps {
  level: "h1" | "h2" | "h3" | "h4" | "h5";
  light?: boolean;
  className?: string;
  children?: string;
}

const Heading: FC<HeadingProps> = ({ level, light, className, children }) => {
  const HeadingTag = level;

  return (
    <HeadingTag className={`${className} ${light && "light"}`}>
      {children}
    </HeadingTag>
  );
};

Heading.defaultProps = {
  light: false,
  className: "",
  children: "",
};

export default Heading;
