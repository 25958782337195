import clsx from "clsx";
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import { Edit } from "react-feather";
import { useTranslation } from "react-i18next";
import Button from "src/components/common/Button";
import TextField from "src/components/common/TextField";
import { useAppDispatch } from "src/store/hooks";
import { SetQmetrics } from "src/store/slices/QmetricSlice";
import { ReusableMessage } from "src/types/interview";
import useConditionalReusable from "../../hooks/useConditionalReusable";
import reusableOpenAnswerClasses from "./reusableOpen.module.scss";
import { EditingState } from "./reusableOpen.types";
import UpdateOpenReusableForm from "./UpdateOpenQuestionForm";

interface ReusableOpenProps {
  reusable: ReusableMessage;
}

const ReusableOpen = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  ReusableOpenProps
>(({ reusable }, ref) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("reusables");
  const [editingState, setEditingState] = useState<EditingState>("unedited");
  const hasRecordedQMetric = useRef<boolean>(false);
  const [show] = useConditionalReusable(reusable);

  useEffect(() => {
    if (!reusable.qutter_id || !reusable.qmetrics) return;
    if (!hasRecordedQMetric.current) {
      dispatch(
        SetQmetrics({
          qutter_id: reusable.qutter_id,
          qmetrics: reusable.qmetrics,
        })
      );
      hasRecordedQMetric.current = true;
    }
  }, [dispatch, reusable.qutter_id, reusable.qmetrics]);

  return (
    <div
      className={clsx(
        reusableOpenAnswerClasses.container,
        show === false && "hidden"
      )}
    >
      <header className={reusableOpenAnswerClasses.questionHeader}>
        <p dangerouslySetInnerHTML={{ __html: reusable.question.text }}></p>
        {editingState === "edited" && (
          <p className={reusableOpenAnswerClasses.updatedPill}>
            {t("updated")}
          </p>
        )}
      </header>
      {editingState === "editing" ? (
        <UpdateOpenReusableForm
          ref={ref as ForwardedRef<HTMLTextAreaElement>}
          reusable={reusable}
          onEditComplete={setEditingState}
        />
      ) : (
        <>
          <TextField.Area
            value={reusable.texts.join("\n")}
            disabled
            flexibleHeight
            fullWidth
          />
          <Button
            variant="primary-outlined"
            expanded
            iconLeft={<Edit />}
            onClick={() => setEditingState("editing")}
          >
            {t("editAnswer")}
          </Button>
        </>
      )}
    </div>
  );
});

ReusableOpen.displayName = "ReusableOpen";

export default ReusableOpen;
