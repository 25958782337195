import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { generatePath } from 'react-router-dom';
import { ApiResponse, RequestSuccess } from 'src/types/global/ApiResponse';
import axiosBaseQuery from '../common/axiosBaseQuery';
import { AxiosBaseQueryFn } from '../common/axiosBaseQuery/types';

export const RtsApiReducerPath = 'rtsApiV1' as const;

const rtsApi = createApi({
  reducerPath: RtsApiReducerPath,

  baseQuery: retry<AxiosBaseQueryFn>(
    axiosBaseQuery({
      baseUrl: process.env.REACT_APP_URL!,
    }),
    { maxRetries: 0 }
  ),

  endpoints: (builder) => ({
    rtsJob: builder.mutation<RequestSuccess<any>, any>({
      query: (details) => ({
        url: '/v1/rts/jobs',
        method: 'POST',
        data: details,
      }),
      transformErrorResponse: (err) => ({
        ...err,
        message: ApiResponse.getErrorMessage(err),
      }),
      extraOptions: {
        maxRetries: 3,
      },
    }),

    applyToRtsJob: builder.mutation<
      RequestSuccess<any>,
      {
        jobId: string;
        jobAdId?: string;
        candidate: {
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
        };
        documents?: { [documentType: string]: string };
      }
    >({
      query: (details) => {
        const url = generatePath('/v1/rts/jobs/:jobId/apply', {
          jobId: details.jobId,
        });

        return {
          url: url,
          method: 'POST',
          data: details,
        };
      },
      transformErrorResponse: (err) => ({
        ...err,
        message: ApiResponse.getErrorMessage(err),
      }),
      extraOptions: {
        maxRetries: 3,
      },
    }),
  }),
});

const RtsApi = { v1: rtsApi };

export default RtsApi;
