import { useTranslation } from "react-i18next";
import interviewIntroClasses from "./interviewIntro.module.scss";

export default function InterviewIntro() {
  const { t } = useTranslation("common");
  return (
    <div id={interviewIntroClasses["interview-intro"]}>
      <svg
        width={41}
        height={41}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="overflow-visible"
      >
        <rect
          id={interviewIntroClasses["container-rect"]}
          width="41"
          height="41"
          rx="6"
          ry="6"
          fill="#000"
        />
        <circle
          className={interviewIntroClasses.innerCircleShift}
          cx="20.5"
          cy="20"
          r="19"
          fill="#000"
        />
        <circle
          className={interviewIntroClasses.innerCircle}
          cx="20.5"
          cy="20"
          r="19"
          fill="#fff"
        />
      </svg>
      <div>
        <p className="text-gray-600 font-medium text-sm">
          {t("interview-intro.title")}
        </p>
        <p className="text-black text-lg font-medium">
          {t("interview-intro.speakingToHubert")}
        </p>
        <p className="text-sm text-gray-500">{t("interview-intro.subtitle")}</p>
      </div>
    </div>
  );
}
