import { animated } from '@react-spring/web';
import axios from 'axios';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { ArrowRight, Check } from 'react-feather';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import Button from 'src/components/common/Button';
import rollbar from 'src/services/loggers/rollbar';
import containerClasses from '../../container.module.scss';
import useSlideSpring from '../useSlideSpring';
import preInterviewClasses from './PreInterviewPage.module.scss';

type PreInterviewPageParams = {
  interviewId: string;
};

// Move files to the correct folder. This "step" is not only part of the RTS Flow
export default function PreInterviewPage() {
  const { t } = useTranslation('preInterview');
  const navigate = useNavigate();
  const location = useLocation();
  const candidateState = location.state;
  const [searchParams] = useSearchParams();
  const { interviewId } = useParams<PreInterviewPageParams>();
  const slideAnimation = useSlideSpring();
  const [candidateName, setCandidateName] = useState<string>(
    candidateState?.candidate?.firstName ?? undefined
  );
  const [isPreInterviewLoading, setIsPreInterviewLoading] = useState(false);

  const token = searchParams.get('token');
  const otpToken = searchParams.get('otpToken');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/v1/rts/interviews/${interviewId}/pre-interview`
        );

        if (response.data?.data?.candidate?.firstName) {
          setCandidateName(response?.data?.data?.candidate?.firstName);
          setIsPreInterviewLoading(false);
        }
      } catch (error: any) {
        rollbar.error('Error fetching pre-interview data');
        setIsPreInterviewLoading(false);
      }
    };
    if (interviewId && !candidateName) {
      setIsPreInterviewLoading(true);
      fetchData();
    }
  }, []);

  const getTrimmedSearchParams = () => {
    const newSearchParams = new URLSearchParams();
    const token = searchParams.get('token');
    if (token) {
      newSearchParams.set('token', token);
    }

    const redirectUrl = searchParams.get('redirectUrl');
    if (redirectUrl) {
      newSearchParams.set('redirectUrl', redirectUrl);
    }
    return newSearchParams.toString();
  };

  const navigateToInterview = () => {
    if (!token) {
      const redirectUrl = new URL(
        '/instant-apply/post-interview',
        process.env.REACT_APP_CANDIDATE_HUB_URL
      );
      if (otpToken) {
        //@ts-ignore
        if (candidate && !candidate?.isHubertUser) {
          redirectUrl.searchParams.set('token', otpToken);
        }
      }

      // if (true && true) {
      //   redirectUrl.searchParams.set('email', 'woop');
      // }
      searchParams.set('redirectUrl', redirectUrl.toString());
    }
    navigate(`/${interviewId}?${getTrimmedSearchParams()}`);
  };

  if (isPreInterviewLoading) {
    return <LoadingScreen />;
  }

  return (
    <animated.section
      className={clsx(
        containerClasses.container,
        preInterviewClasses.page,
        'text-center'
      )}
      style={slideAnimation}
    >
      <header>
        <h1>
          {t('tips.title', {
            name: candidateName,
          })}
        </h1>
        <p>{t('tips.segue')}</p>
      </header>
      <article className={preInterviewClasses.tipBox}>
        <h1 className="text-lg">{t('tips.tipbox.title')}</h1>
        <ul>
          {[
            t('tips.tipbox.tip1'),
            t('tips.tipbox.tip2'),
            t('tips.tipbox.tip3'),
          ].map((tip, index) => (
            <li key={index} className={preInterviewClasses.tip}>
              <i>
                <Check />
              </i>

              <p>{tip}</p>
            </li>
          ))}
        </ul>
      </article>
      <p className="text-base font-semibold">{t('tips.readyToGetStarted')}</p>
      <Button onClick={navigateToInterview} iconRight={<ArrowRight />}>
        {t('tips.startInterviewBtnText')}
      </Button>
    </animated.section>
  );
}
