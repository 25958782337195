import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
  Fragment,
} from 'react';
import AlertDialog from '..';
import DEFAULT_OPTIONS from './alertProvider.defaults';
import {
  AlertResolveReject,
  BuildOptions,
  IAlertProviderContext,
} from './AlertProvider.types';

const buildOptions = (
  defaultOptions?: BuildOptions,
  options?: BuildOptions
) => {
  return {
    dialogProps: {
      ...(defaultOptions || DEFAULT_OPTIONS.dialogProps),
      ...(options || {}),
    },
    cancelButtonProps: {
      ...(defaultOptions?.cancelButton || DEFAULT_OPTIONS.cancelButton),
      ...(options?.cancelButton || {}),
    },
    actionButtonProps: {
      ...(defaultOptions?.actionButton || DEFAULT_OPTIONS.actionButton),
      ...(options?.actionButton || {}),
    },
    asDisplay: options?.asDisplay || false,
  };
};

const AlertProviderContext = createContext<IAlertProviderContext>({
  confirm: () => Promise.resolve(false),
});

const AlertProvider = ({
  defaultOptions,
  children,
}: PropsWithChildren<{ defaultOptions?: BuildOptions }>) => {
  const [options, setOptions] = useState<BuildOptions>();
  const [key, setKey] = useState(0);
  const [alertPromiseCallbacks, setAlertPromiseCallbacks] = useState<
    AlertResolveReject | []
  >([]);
  const [resolveAlertFn] = alertPromiseCallbacks;

  const handleClose = useCallback(() => {
    setAlertPromiseCallbacks([]);
  }, []);

  const confirm = useCallback((options?: BuildOptions) => {
    return new Promise<boolean>((resolve, reject) => {
      setKey((key) => key + 1);
      setOptions(options);
      setAlertPromiseCallbacks([resolve, reject]);
    });
  }, []);

  const handleAction = useCallback(() => {
    if (resolveAlertFn) {
      resolveAlertFn(true);
      handleClose();
    }
  }, [handleClose, resolveAlertFn]);

  const handleCancel = useCallback(() => {
    if (resolveAlertFn) {
      resolveAlertFn(false);
      handleClose();
    }
  }, [handleClose, resolveAlertFn]);

  return (
    <>
      <AlertDialog
        key={key}
        open={alertPromiseCallbacks.length === 2}
        options={buildOptions(defaultOptions, options)}
        onConfirm={handleAction}
        onCancel={handleCancel}
        onClose={handleClose}
      />
    </>
  );
};

export const useAlert = () => {
  const context = useContext(AlertProviderContext);

  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }

  return context;
};

export default AlertProvider;
