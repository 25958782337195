import { AxiosError } from "axios";
import axiosClient from "./client";
import { AxiosBaseQueryFn } from "./types";

const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string } = { baseUrl: "" }): AxiosBaseQueryFn =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await axiosClient.instance({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: {
          ...headers,
          "Chat-Version": process.env.REACT_APP_CHAT_VERSION!,
        },
      });
      return {
        data: result.data,
      };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data,
          message: err.response?.data.message,
          errors: err.response?.data.errors,
          code: err.response?.data.code,
        },
      };
    }
  };

export default axiosBaseQuery;
