import { cva } from "class-variance-authority";
import classes from "./Button.module.scss";

const fromButtonVariants = cva(classes.btn, {
  variants: {
    kind: {
      icon: [classes.iconBtn],
      text: undefined,
    },
    variant: {
      primary: classes.btnPrimary,
      "primary-outlined": [classes.btnPrimary, classes.outlined],
      danger: classes.btnDanger,
      neutral: classes.btnNeutral,
      "neutral-outlined": [classes.btnNeutral, classes.outlined],
    },
    buttonFit: {
      expanded: ["w-full"],
      default: undefined,
    },
  },
  defaultVariants: {
    variant: "primary",
    buttonFit: "default",
    kind: "text",
  },
});

export default fromButtonVariants;
