import clsx from "clsx";
import { ChangeEvent, useEffect, useRef } from "react";
import { useFormContext } from "src/components/Prompts/Form/providers/FormProvider";
import { MatrixFormValue } from "src/components/Prompts/Form/providers/FormProvider/types";
import { MatrixCell, MatrixForm } from "src/types/forms";
import { Optional } from "src/types/global";
import { FormComponentProps } from "../common/types";
import matrixClasses from "./FormMatrix.module.scss";

type FormMatrixRowProps = FormComponentProps<MatrixForm> & {
  row: MatrixCell;
};

export const FormMatrixRow = ({ form, row }: FormMatrixRowProps) => {
  const [{ values }, { addValue }] = useFormContext();

  const matrixRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (matrixRowRef.current) {
      matrixRowRef.current.style.setProperty(
        "--column-count",
        `${form.columns.length + 1}`
      );
    }
  }, [form.columns.length]);

  const formValue = values[form.id] as Optional<MatrixFormValue>;

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    column: MatrixCell
  ) => {
    let row_values = {};
    if (formValue && formValue.row_values) {
      row_values = formValue.row_values;
    }
    addValue({
      [form.id]: {
        id: form.id,
        row_values: {
          ...row_values,
          [row.id]: {
            checked: e.target.checked,
            column_id: column.id,
            updated_at: new Date(),
          },
        },
        updated_at: new Date(),
        is_mandatory: form.is_mandatory,
        hubiverse_property: form.hubiverse_property,
        candidate_property: form.candidate_property,
      },
    });
  };

  if (form.columns.length === 0) {
    return null;
  }

  return (
    <div
      ref={matrixRowRef}
      className={clsx(
        matrixClasses.matrixRow,
        matrixClasses.verticalCenter,
        "border-b"
      )}
    >
      <p className={matrixClasses.rowLabel}>{row.label}</p>
      {form.columns.map((column) => {
        const isChecked =
          column.id === formValue?.row_values?.[row.id]?.column_id;
        return (
          <input
            key={column.id}
            type="radio"
            name={row.id}
            id={column.id}
            value={column.id}
            checked={isChecked}
            className="accent-primary"
            onChange={(e) => handleInputChange(e, column)}
          />
        );
      })}
    </div>
  );
};
