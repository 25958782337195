import clsx from "clsx";
import { useAppDispatch } from "src/store/hooks";

import { updateReusableSingleSelectValue } from "src/store/slices/ReusableSlice";
import { Option } from "src/types/qutter";

interface ReusableOptionProps {
  option: Option;
  qutter_id: string;
  value: string;
}

export const ReusableOption = ({
  option,
  qutter_id,
  value,
}: ReusableOptionProps) => {
  const dispatch = useAppDispatch();

  const onOptionClick = () => {
    dispatch(
      updateReusableSingleSelectValue({
        qutter_id,
        value: option.id,
        label: option.label,
      })
    );
  };

  const clicked = option.id === value;

  return (
    <div
      role="listitem"
      className={clsx(
        clicked && "clicked",
        "flex items-end justify-center q-btn w-full"
      )}
    >
      <button
        className={clsx("input-bubble", clicked && "clicked", "btn w-full")}
        onClick={onOptionClick}
        dangerouslySetInnerHTML={{ __html: option.label }}
      />
    </div>
  );
};
