import { forwardRef } from "react";
// components
import Button from "src/components/common/Button";
// hooks
import { useTranslation } from "react-i18next";
import { useSendReusablesMutation } from "src/services/interview";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
// slices
import {
  moveToHistory,
  nextMessage,
  setCurrent,
  setPending,
  toggleHubertTyping,
  toggleRendering
} from "src/store/slices/MessageSlice";
import { unset } from "src/store/slices/ReusableSlice";
// types
import { SendReusablesRequest } from "src/services/interview/mutations/types";
import { Next } from "src/store/types/Message/messageState";
import { ReusableMapValue } from "src/types/interview";
import { EMessageSender } from "src/types/payload.types";
// styles
import "./SaveReusables.scss";

const presentableAnswer: any = (type: string, reusable: Partial<ReusableMapValue>) => {
  if (type === 'open')
    return [`${reusable.texts}`];
  else 
  if (type === 'single-select')
    return [`${reusable.label}`]
  else 
  if (type === 'date-picker') {
    if (reusable.value) {
      const a = new Date(reusable.value)
      return [a.toLocaleDateString()];
    }
  }
}

const SaveReusables = forwardRef<HTMLButtonElement>((_, ref) => {
  const dispatch = useAppDispatch();
  const Interview = useAppSelector((state) => state.Interview);
  const order = useAppSelector((state) => state.Reusables.order);
  const map = useAppSelector((state) => state.Reusables.map);
  const qmetrics = useAppSelector((state) => state.Qmetrics);
  const [sendReusables] = useSendReusablesMutation();
  const { t } = useTranslation("reusables");

  const onDoneClick = async () => {
    if (!order || !map) return;

    const reusableAnswersPayload: SendReusablesRequest = {
      interview_id: Interview._id,
      answers: [],
    };

    for (let i = 0; i < order.length; i++) {
      const id = order[i];
      const reusable = map[id];
      const qmetric = qmetrics[id];

      const notApplicable =
        Boolean(reusable.applicable) && reusable.applicable === false;
      if (notApplicable) continue;

      const question: Next = {
        sender:   EMessageSender.Hubert,
        answers:  [`${reusable.question.text}`],
      };

      dispatch(moveToHistory());
      dispatch(nextMessage({ next: question }));

      let answer: Partial<ReusableMapValue> = {
        interview_id: Interview._id,
        qutter_oid: reusable.qutter_oid,
        qutter_id: reusable.qutter_id,
        language: reusable.question.language,
        type: reusable.question.type,
        reusage: reusable.reusage,
      };

      if (reusable.question.type === "open") {
        answer = {
          ...answer,
          texts: reusable.texts,
          units: [qmetric],
        };
      } else {
        answer = {
          ...answer,
          texts: [reusable.label],
          label: reusable.label,
          text: reusable.text,
          value: reusable.value,
        };
      }

      const candidateAnswer: Next = {
        sender:   EMessageSender.Candidate,
        answers:  presentableAnswer(reusable.question.type, answer)
      };

      dispatch(moveToHistory());
      dispatch(nextMessage({ next: candidateAnswer }));

      reusableAnswersPayload.answers.push(answer as ReusableMapValue);
    }
    // Unset the reusables
    dispatch(unset());
    dispatch(toggleHubertTyping({ value: true }));

    const sendReusablesResult = await sendReusables(reusableAnswersPayload);

    if ("data" in sendReusablesResult) {
      const response = sendReusablesResult.data;
      dispatch(toggleHubertTyping({ value: false }));
      dispatch(toggleRendering({ value: true }));
      dispatch(moveToHistory());

      const interviewState = response.interviewState;
      dispatch(setCurrent({
        current:
          (interviewState && interviewState.current) ??
          response.data
      }));
      dispatch(setPending({ 
        pending:
          (interviewState && interviewState.pending) ??
          response.pending
      }));

    }
  };

  return (
    <Button ref={ref} variant="primary" onClick={onDoneClick} expanded>
      {t("readyAndSend")}
    </Button>
  );
});

export default SaveReusables;
