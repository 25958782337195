import clsx from 'clsx';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { File, Trash2, X } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import FileUploadHeader from './FileUploadHeader';
import classes from './fileUpload.module.scss';
import { usePreInterviewContext } from 'src/pages/PreInterviewPage/PreInterviewProvider';

const MediaTypes = {
  file: [
    {
      fileExtension: '.pdf',
      contentType: 'application/pdf',
      name: 'PDF',
    } as const,
    {
      fileExtension: '.docx',
      contentType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      name: 'docx',
    } as const,
    {
      fileExtension: '.doc',
      contentType: 'application/msword',
      name: 'doc',
    } as const,
    {
      fileExtension: '.txt',
      contentType: 'text/plain',
      name: 'txt',
    } as const,
  ],
  image: [
    {
      fileExtension: '.png',
      contentType: 'image/png',
      name: 'png',
    } as const,
    {
      fileExtension: '.jpeg',
      contentType: 'image/jpeg',
      name: 'jpeg',
    } as const,
  ],
};

type SupportedFileTypes = (typeof MediaTypes)['file'][number]['contentType'];

type SupportedImageTypes = (typeof MediaTypes)['image'][number]['contentType'];

type SupportedMediaTypes = SupportedFileTypes | SupportedImageTypes;

type MediaTypeConfig =
  | { media?: 'image'; reject?: SupportedImageTypes[] }
  | { media?: 'file'; reject?: SupportedFileTypes[] }
  | {
      media?: 'all';
      reject?:
        | SupportedFileTypes[]
        | SupportedImageTypes[]
        | SupportedMediaTypes[];
    };

export type FileUploadButtonProps = ComponentPropsWithoutRef<'input'> &
  MediaTypeConfig & {
    title: string;
    description?: string | string[];
    label?: string;
    multiple?: boolean;
    required?: boolean;
    // onChange?: (files: File[]) => void;
    buttonProps?: ComponentProps<'button'>;
    error?: string;
  };

interface FileUploadItem {
  name: string;
  file: File;
}

export const FileUploadControl = forwardRef<any, any>(
  (
    {
      media = 'all',
      reject,
      name,
      title,
      onChange,
      multiple = false,
      required = false,
      error,
      ...props
    },
    ref
  ) => {
    const id = useId();
    const [files, setFiles] = useState<Array<FileUploadItem>>([]);
    const { t } = useTranslation('preInterview');
    const fileInputRef = useRef<any>(null);
    const { setCurrentStep, numberOfSteps } = usePreInterviewContext();
    useImperativeHandle(ref, () => fileInputRef.current!);

    useEffect(() => {
      if (numberOfSteps === 5) {
        setCurrentStep(2);
      }
    }, [numberOfSteps]);

    const supportedExtensions = useMemo(() => {
      let allowedMedia = [];
      if (media === 'all') {
        allowedMedia = [...MediaTypes.file, ...MediaTypes.image];
      } else if (media === 'file') {
        allowedMedia = MediaTypes.file;
      } else if (media === 'image') {
        allowedMedia = MediaTypes.image;
      } else {
        throw new Error('Invalid media type');
      }

      if (reject) {
        allowedMedia = allowedMedia.filter(
          (type) => !(reject as string[]).includes(type.contentType)
        );
      }

      const extensionNames = allowedMedia.map((type) => type.name).join(', ');

      return {
        names: extensionNames,
        contentTypes: allowedMedia.map((type) => type.contentType).join(','),
        fileExtensions: allowedMedia
          .map((type) => type.fileExtension)
          .join(','),
      };
    }, [media, reject]);

    const getUploadButtonTitle = () => {
      if (files.length > 1) {
        return t('submitDocuments.common.uploadedFiles', {
          firstFileName: files[0].file.name,
          count: files.length - 1,
        });
      }

      return files.length === 1 ? files[0].file.name : title;
    };

    const handleClick = () => {
      fileInputRef.current?.click();
    };

    const onRemoveFileClick = () => {
      setFiles([]);
      onChange?.([]);
    };

    return (
      <div
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (e.dataTransfer.files) {
            setFiles(
              Array.from(e.dataTransfer.files).map((file) => ({
                file,
                name: file.name,
              }))
            );
          }
          onChange?.(e.dataTransfer.files[0]);
        }}
      >
        <input
          ref={fileInputRef}
          type="file"
          hidden
          name={name}
          accept={
            supportedExtensions.fileExtensions +
            ',' +
            supportedExtensions.contentTypes
          }
          multiple={multiple}
          // hidden
          onChange={(e) => {
            if (e.target.files) {
              setFiles(
                Array.from(e.target.files).map((file) => ({
                  file,
                  name: file.name,
                }))
              );
            }

            onChange?.(e.target.files?.item(0));
          }}
        />
        <div
          {...props}
          className={clsx('flex flex-col gap-2', props.className)}
        >
          {props.label && (
            <FileUploadHeader
              htmlFor={id}
              label={props.label}
              required={required}
            >
              {props.description}
              {error && <span className="text-danger">{error}</span>}
            </FileUploadHeader>
          )}
          <button
            id={id}
            type="button"
            className={classes.upload}
            aria-labelledby={props.label ? id : undefined}
            onClick={handleClick}
            aria-describedby={`${id}-description-text`}
          >
            <span id={`${id}-description-text`} hidden>
              {t('submitDocuments.common.uploadedFilesAria', {
                supportedExtensions: supportedExtensions.names,
                fileCount: files.length,
              })}
            </span>
            <File className="stroke-primary" />
            <section className={classes.body}>
              <p className={classes.title}>{getUploadButtonTitle()}</p>
              <p className={classes.chooseFileText}>
                <Trans
                  ns="preInterview"
                  i18nKey={
                    multiple
                      ? 'submitDocuments.common.chooseFilesToUpload'
                      : 'submitDocuments.common.chooseFileToUpload'
                  }
                  components={[
                    <span className="text-primary hover:underline cursor-pointer" />,
                    <span />,
                    <span />,
                  ]}
                />
              </p>
              <p className={classes.fileExtensions}>
                {supportedExtensions.names}
              </p>
            </section>
            {files.length > 0 && (
              <div
                onClick={() => onRemoveFileClick()}
                className={classes.removeFile}
              >
                <Trash2 size={16} />
              </div>
            )}
          </button>
        </div>
      </div>
    );
  }
);
