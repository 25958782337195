import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { QueryLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Method } from "axios";

// slices
import { initApplication } from "src/store/slices/ApplicationSlice";
import { initCandidate } from "src/store/slices/CandidateSlice";
import { initInterview } from "src/store/slices/InterviewSlice";
import { initJob } from "src/store/slices/JobSlice";
import { pushToHistory, setCurrent } from "src/store/slices/MessageSlice";
import { pushReusables } from "src/store/slices/ReusableSlice";
import { initTemplate } from "src/store/slices/TemplateSlice";
import { setHeaderOptionNotInterested, setHeaderOptionResetInterview } from "src/store/slices/LayoutControlSlice";

// types
import { TLoadChatPayload } from "src/types/payload.types";


export type InterviewQueryLifecycle<TData, TResponse> = QueryLifecycleApi<
  TData,
  BaseQueryFn<{
    url: string;
    method: Method | undefined;
    data?: any;
    params?: any;
  }>,
  TResponse,
  string
>;

async function handleGetInterviewQueryStarted(
  _arg: string,
  { queryFulfilled, dispatch }: InterviewQueryLifecycle<string, TLoadChatPayload>
) {
  try {
    const { data } = await queryFulfilled;
    if (data) {
      const template = data.template;
      console.log('data', data)

      dispatch(pushToHistory({ history: data.interview.history }));
      dispatch(initJob({ job: data.job }));
      dispatch(initTemplate({ template }));
      dispatch(initCandidate({ candidate: data.candidate }));
      dispatch(initApplication({ application: data.application }));
      dispatch(initInterview({ interview: data.interview }));

      const current = data.interview.state.current;
      if (current) {
        dispatch(setCurrent({ current }));
      }

      if (data.reusable && data.reusable.order.length > 0) {
        dispatch(pushReusables(data.reusable));
      }

      // For testing purposes - remove feature flags when feature has been accepted
      if (template && template.featureFlags && template.featureFlags.chat_header_options) {
        const x = template.featureFlags.chat_header_options;
        if (x.value && x.value.reset_interview) {
          dispatch(setHeaderOptionResetInterview(true))
        }
        if (x.value && x.value.not_interested) {
          dispatch(setHeaderOptionNotInterested(true))
        }
      }
    }
  } catch (e) {
    console.error("getInterviewQuery failed: ", e);
  }
}

const QueryHandlers = {
  handleGetInterviewQueryStarted,
};

export default QueryHandlers;
