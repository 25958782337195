import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import LoadInterview from 'src/pages/LoadInterview/LoadInterview';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './layout/MainLayout/MainLayout';
import InterviewContainer from './pages/InterviewContainer';
import PreviewInterview from './pages/PreviewInterview';

import './App.css';
import { usePlatformAction } from './hooks/usePlatformAction';
import useTheme from './hooks/useTheme';
import ErrorPage from './pages/ErrorPage';
import InvitationPreInterview from './pages/PreInterviewPage/Invitation';
import RTSPreInterviewPage from './pages/PreInterviewPage/RealTimeScreening';
import GetDetailsStep from './pages/PreInterviewPage/Steps/GetDetailsStep';
import GetEmailStep from './pages/PreInterviewPage/Steps/GetEmailStep';
import InitialStep from './pages/PreInterviewPage/Steps/InitialStep';
import OptInSignInStep from './pages/PreInterviewPage/Steps/OptSignInStep';
import OTPStep from './pages/PreInterviewPage/Steps/OTPStep';
import PreInterviewPage from './pages/PreInterviewPage/Steps/PreInterviewPage';
import SubmitDocumentsStep from './pages/PreInterviewPage/Steps/UploadDocumentsStep';
import UnifiedRTSEntry from './pages/RTSRedirect/UnifiedRTSEntry';

const Job = lazy(() => import('./pages/Job'));
const TemplateTest = lazy(() => import('./components/Testing/TemplateTest'));

const preInterviewSteps: RouteObject[] = [
  {
    index: true,
    element: <InitialStep />,
  },
  {
    path: 'get-email',
    element: <GetEmailStep />,
  },
  {
    path: 'additional-details',
    element: <GetDetailsStep />,
  },
  {
    path: 'opt-sign-in',
    element: <OptInSignInStep />,
  },
  {
    path: 'upload-documents',
    element: <SubmitDocumentsStep />,
  },
  {
    path: 'interview',
    element: <PreInterviewPage />,
  },
  {
    path: 'otp',
    element: <OTPStep />,
  },
];

const router = createBrowserRouter([
  {
    path: ':interviewId/pre-interview',
    element: <PreInterviewPage />,
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: ':interviewId',
        element: <LoadInterview />,
        children: [
          {
            index: true,
            element: <InterviewContainer />,
          },
        ],
      },
      {
        path: 'preview/:interviewId',
        element: <LoadInterview.Preview />,
        children: [
          {
            index: true,
            element: <PreviewInterview />,
          },
        ],
      },
      {
        path: 'job',
        element: (
          <Suspense
            fallback={<LoadingScreen subtitle="Creating interview..." />}
          >
            <Job />
          </Suspense>
        ),
        children: [
          {
            path: ':jobId',
            element: <InterviewContainer />,
          },
        ],
      },
      {
        path: 'template/test',
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <TemplateTest />
          </Suspense>
        ),
        children: [
          {
            path: ':templateId',
            element: <InterviewContainer />,
          },
        ],
      },
    ],
  },
  {
    path: '/rts',
    children: [
      {
        path: ':jobId/',
        element: <RTSPreInterviewPage />,
        children: preInterviewSteps,
      },
      {
        path: 'init',
        element: <UnifiedRTSEntry />,
      },
    ],
  },
  {
    path: '/job/:jobId/register',
    element: <RTSPreInterviewPage />,
    children: preInterviewSteps,
  },
  {
    path: '/rts/:jobId/pre-interview',
    element: <RTSPreInterviewPage />,
    children: preInterviewSteps,
  },
  {
    path: '/invitation/:interviewId/pre-interview',
    element: <InvitationPreInterview />,
    children: preInterviewSteps,
  },
  {
    path: '/error',
    element: <ErrorPage />,
  },
  {
    path: '*',
    element: (
      <ErrorPage
        title="Nothing at the page"
        message="You seem to have navigated to a page that doesn't exist in our application."
        status={403}
        backToPrevious
      />
    ),
  },
]);

function App() {
  useTheme();
  usePlatformAction({
    runAction: 'prevent-ios-zoom',
  });

  return <RouterProvider router={router} />;
}

export default App;
