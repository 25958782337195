import {
  SendFormMatrixPayloadItem,
  SendFormPayloadItem
} from "src/services/interview/mutations/types";
import { Next } from "src/store/types/Message/messageState";
import { Form, MatrixForm } from "src/types/forms";
import { Nullable } from "src/types/global";
import { ConstructNextQuestionParams } from "./types";
import { EMessageSender } from "src/types/payload.types";

export const constructNextQuestion = ({
  form,
  value,
  payload,
  type,
}: ConstructNextQuestionParams) => {
  let questionTextHtml = "";
  let formattedValue = { ...value };
  let data: Nullable<SendFormMatrixPayloadItem[]> = null;
  let answer = "";

  let modifiedPayload: SendFormPayloadItem = {
    ...payload,
    ...formattedValue,
    text: questionTextHtml,
  };

  if ("value" in formattedValue) {
    questionTextHtml = `${form.label}: ${formattedValue.value}`;

    if (type === "number") {
      formattedValue.value = Number(formattedValue.value);
    }
  }

  if ("checked" in formattedValue) {
    questionTextHtml = `${form.label}: ${formattedValue.checked ? "👍" : "👎"}`;
  }

  if (type === "file") {
    if ("uploaded" in formattedValue) {
      questionTextHtml = `${form.label}: ${formattedValue.uploaded.original_filename} 👍`;
    } else {
      questionTextHtml = `${form.label}: 👎`;
    }
  }

  if ("range_min" in formattedValue) {
    if (form.is_mandatory || formattedValue.value.length >= 2) {
      questionTextHtml = `${form.label}: ${formattedValue.value[0]} - ${formattedValue.value[1]}`;
    } else {
      questionTextHtml = `${form.label}: `.concat(
        formattedValue.value.length > 0 ? `${formattedValue.value[0]}` : "N/A",
        " - N/A"
      );
    }
  }

  if ("row_values" in formattedValue) {
    const rowValues = formattedValue.row_values;
    const matrixForm = form as Form<MatrixForm>;

    // TODO: data, text and answer properties here is part of the modified payload below.
    //  You need to figure out a way to pass them to the final object without creating the vars here

    // TODO: Debug this. May not work as intended
    for (let rowId in rowValues) {
      const row = matrixForm.rows.find((row) => row.id === rowId);
      const column_id = rowValues[rowId].column_id;
      const column = matrixForm.columns.find(
        (column) => column.id === column_id
      );
      const column_idx = matrixForm.columns.findIndex(
        (column) => column.id === column_id
      );

      if (column && row) {
        if (!column.label.length) {
          answer += `<strong>${row.label}</strong>: ${column_idx + 1} \n <br/>`;
          questionTextHtml = `${row.label}: ${column_idx + 1}`;
        } else {
          answer += `<strong>${row.label}</strong>: ${column.label} \n <br/>`;
          questionTextHtml = `${row.label}: ${column.label}`;
        }
      }

      const matrixPayloadItem: SendFormMatrixPayloadItem = {
        ...payload,
        text: questionTextHtml,
        row_id: rowId,
        column_id: column_id,
        checked: column_id,
        form_id: form.id,
        form_type: form.type,
      };

      data = [...(data || []), matrixPayloadItem];
    }
  }

  // TODO: Consider moving these actions to a separate file.
  // TODO: Commit converted Form components with Context adaptations.
  const candidateAnswer: Next = {
    sender:   EMessageSender.Candidate,
    answers:  [type === "matrix" ? answer : questionTextHtml],
    wait:     0,
  };

  modifiedPayload = {
    ...modifiedPayload,
    ...formattedValue,
    text: questionTextHtml,
  };

  return {
    payload: modifiedPayload,
    candidateAnswer,
    data,
  };
};
