import { createApi, retry } from "@reduxjs/toolkit/query/react";
import { ApiResponse, RequestSuccess } from "src/types/global/ApiResponse";
import axiosBaseQuery from "../common/axiosBaseQuery";
import { AxiosBaseQueryFn } from "../common/axiosBaseQuery/types";
import {
  CreateOtpRequest,
  CreateOtpResponse,
  VerifyOtpRequest,
  VerifyOtpResponse,
} from "./mutations/types";
import { PreInterviewCandidate } from "./queries/types";

const PreInterviewApiReducerPath = "preInterview" as const;

const PreInterviewApi = createApi({
  reducerPath: PreInterviewApiReducerPath,
  baseQuery: retry<AxiosBaseQueryFn>(
    axiosBaseQuery({
      baseUrl: `${process.env.REACT_APP_URL!}/v2`,
    }),
    { maxRetries: 1 }
  ),
  tagTypes: ["PreInterview"],
  endpoints: (builder) => ({
    getCandidate: builder.query<RequestSuccess<PreInterviewCandidate>, { email: string, token?: string, isMyHubertUserCheck?: boolean}>({
      query: (params) => ({
        url: "/candidates",
        method: "GET",
        params: { email: params.email, isMyHubertUser: params.isMyHubertUserCheck },
        headers: {
          ...(params.token && { Authorization: `Bearer ${params.token}` }),
        }
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    getOtp: builder.query<RequestSuccess<CreateOtpResponse>, CreateOtpRequest>({
      query: (data) => ({ url: "/candidate/otp", method: "POST", data }),
    }),
    verifyOtp: builder.mutation<
      RequestSuccess<VerifyOtpResponse>,
      VerifyOtpRequest
    >({
      query: (data) => ({
        url: "/candidate/otp/verify",
        method: "POST",
        data,
      }),
      transformErrorResponse: (err) => ({
        message: ApiResponse.getErrorMessage(err),
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
});

export const {
  useLazyGetCandidateQuery,
  useGetCandidateQuery,
  useGetOtpQuery,
  useVerifyOtpMutation,
} = PreInterviewApi;

export default PreInterviewApi;
