import { cva, VariantProps } from "class-variance-authority";
import clsx from "clsx";
import { ComponentProps } from "react";
import formFieldStyles from "../FormControl.module.scss";

const errorCva = cva(formFieldStyles.fieldError, {
  variants: {
    alignment: {
      left: formFieldStyles.fieldAlignLeft,
      center: formFieldStyles.fieldAlignCenter,
    },
  },
  defaultVariants: {
    alignment: "left",
  },
});

type FormErrorProps = VariantProps<typeof errorCva> &
  ComponentProps<"div"> & {
    error: string;
  };

export const FormError = ({ error, className, alignment }: FormErrorProps) => {
  return (
    <div className={clsx(className, errorCva({ alignment }))}>
      <p id="input_error">{error}</p>
    </div>
  );
};
