import clsx from "clsx";
import { ComponentProps } from "react";
import { useAppSelector } from "src/store/hooks";
import indicatorClasses from "./TypingIndicator.module.scss";

type TypingIndicatorProps = ComponentProps<"div">;

const TypingIndicator = ({ className, ...props }: TypingIndicatorProps) => {
  const htyping = useAppSelector((state) => state.Messages.htyping);

  return (
    <div
      {...props}
      id="hubert-typing"
      className={clsx(className, indicatorClasses.hubertTyping)}
    >
      <div className={indicatorClasses.hubertAvatar}>H</div>
      <div className="chat-bubble typing">
        <div className={clsx("bubble-content left", !htyping && "hidden")}>
          {Array.from({ length: 3 }).map((_, i) => {
            return (
              <span
                key={i}
                className={clsx(
                  indicatorClasses.circle,
                  indicatorClasses.scaling
                )}
              ></span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TypingIndicator;
