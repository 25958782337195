import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { QueryLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Method } from "axios";
// slices
import { initApplication } from "src/store/slices/ApplicationSlice";
import { initCandidate } from "src/store/slices/CandidateSlice";
import { initInterview } from "src/store/slices/InterviewSlice";
import { initJob } from "src/store/slices/JobSlice";
import { setCurrent } from "src/store/slices/MessageSlice";
import { initTemplate } from "src/store/slices/TemplateSlice";
// types
import { GetInterviewByJobIdQueryRequest } from "./types";
import { TLoadChatPayload } from "src/types/payload.types";

export type InterviewQueryLifecycle<TData = string> = QueryLifecycleApi<
  TData,
  BaseQueryFn<{
    url: string;
    method: Method | undefined;
    data?: any;
    params?: any;
  }>,
  TLoadChatPayload,
  string
>;

async function handleGetJobQueryStarted(
  _arg: GetInterviewByJobIdQueryRequest,
  {
    queryFulfilled,
    dispatch,
  }: InterviewQueryLifecycle<GetInterviewByJobIdQueryRequest>
) {
  try {
    const { data } = await queryFulfilled;
    if (data) {
      dispatch(initJob({ job: data.job }));
      dispatch(initTemplate({ template: data.template }));
      dispatch(initCandidate({ candidate: data.candidate }));
      dispatch(initApplication({ application: data.application }));
      dispatch(initInterview({ interview: data.interview }));

      const current = data.interview.state.current;
      if (current) {
        dispatch(setCurrent({ current }));
      }
    }
  } catch (e) {
    console.error("getJobQuery failed: ", e);
  }
}

const QueryHandlers = {
  handleGetJobQueryStarted,
};

export default QueryHandlers;
