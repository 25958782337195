import { useEffect } from "react";

const useBackButtonListener = (callback: () => void) => {
  useEffect(() => {
    if (window.onpopstate == null) {
      window.onpopstate = () => {
        callback();
      };

      return () => {
        window.onpopstate = null;
      };
    }
  }, [callback]);
};

export default useBackButtonListener;
