import { TFunction } from "i18next";
import {
  FormMatrixRowValue,
  FormMatrixRowValueSchema,
} from "src/components/Prompts/Form/components/matrix/types";
import {
  z,
  ZodArray,
  ZodBoolean,
  ZodEffects,
  ZodNullable,
  ZodNumber,
  ZodOptional,
  ZodString,
  ZodTuple,
  ZodType,
} from "zod";

type ZodNullish<T extends ZodType> = ZodOptional<ZodNullable<T>>;
type ZodTypeOrNullish<T extends ZodType> = T | ZodNullish<T>;

export interface ValidateOptions {
  minLength?: number;
  maxLength?: number;
  required?: boolean;
}

interface NumberValidateOptions extends ValidateOptions {
  max?: number;
  min?: number;
}

const getValue = <T extends ZodType>(schema: T, value: any) => {
  const safeParsedValue = schema.safeParse(value);

  return {
    success: safeParsedValue.success,
    error: !safeParsedValue.success
      ? safeParsedValue.error?.errors.map((e) => e.message)
      : null,
  };
};

const FormValidator = (t: TFunction<"formErrors", undefined>) => ({
  validateText(value: string | undefined, options?: ValidateOptions) {
    let schema: ZodTypeOrNullish<ZodString> = z.string({
      required_error: t("common.required"),
    });

    schema = schema.nonempty({
      message: t("text.minLength"),
    });

    if (options?.minLength) {
      schema = schema.min(options.minLength, {
        message: t("text.minLength"),
      });
    }

    if (options?.maxLength) {
      schema = schema.max(options.maxLength, {
        message: t("text.maxLength", { maxLength: options.maxLength }),
      });
    }

    if (!options?.required) {
      schema = schema.nullish();
    }

    return getValue<typeof schema>(schema, value);
  },
  validateNumber(value: number | undefined, options?: NumberValidateOptions) {
    let schema: ZodTypeOrNullish<ZodNumber> = z.number({
      invalid_type_error: t("common.required"),
      required_error: t("common.required"),
    });

    schema = schema
      .finite({
        message: t("common.required"),
      })
      .safe();

    schema = schema.gte(options?.min ?? 0, {
      message: t("number.minNumber", { min: options?.min ?? 1 }),
    });

    if (options?.max) {
      schema = schema.lte(options.max, {
        message: t("number.maxNumber", { max: options.max }),
      });
    }

    if (!options?.required) {
      schema = schema.nullish();
    }

    return getValue<typeof schema>(schema, value);
  },
  validateEmail(
    value: string | undefined,
    options?: Omit<ValidateOptions, "minLength" | "maxLength">
  ) {
    let schema: ZodTypeOrNullish<ZodString> = z.string();

    schema = schema.email({
      message: t("email.invalidType"),
    });

    return getValue(schema, value);
  },
  validateRange(value: number[] | undefined, options?: NumberValidateOptions) {
    let schema: ZodTypeOrNullish<ZodTuple<[ZodNumber, ZodNumber]>> = z.tuple(
      [z.number(), z.number()],
      {
        required_error: t("range.invalidRange"),
        invalid_type_error: t("range.invalidType"),
      }
    );

    if (!options?.required) {
      schema = schema.nullish();
    }

    return getValue(schema, value);
  },
  validateCheckbox(
    value: boolean | undefined,
    options?: Omit<ValidateOptions, "minLength" | "maxLength">
  ) {
    let schema: ZodTypeOrNullish<ZodBoolean> | ZodEffects<ZodBoolean> =
      z.boolean({
        required_error: t("common.required"),
      });

    if (options?.required) {
      schema = schema.refine(
        (value) => {
          return value === true;
        },
        { message: t("checkbox.required") }
      );
    } else {
      schema = schema.nullish();
    }

    return getValue(schema, value);
  },
  validateMatrix(
    value: FormMatrixRowValue[] | undefined,
    options?: Omit<ValidateOptions, "maxLength">
  ) {
    let schema:
      | ZodEffects<ZodArray<typeof FormMatrixRowValueSchema>>
      | ZodArray<typeof FormMatrixRowValueSchema> = z.array(
      FormMatrixRowValueSchema,
      {
        invalid_type_error: t("matrix.invalidType"),
        required_error: t("matrix.required"),
      }
    );

    if (options?.minLength) {
      schema = schema.length(options?.minLength, {
        message: t("matrix.minLength"),
      });
    }

    if (options?.required) {
      schema = schema.refine(
        (data) => {
          return data.every((row) => {
            const safeParsedValue = FormMatrixRowValueSchema.safeParse(row);

            return safeParsedValue.success;
          });
        },
        { message: t("matrix.required") }
      );
    }

    return getValue(schema, value);
  },
});

export default FormValidator;
