import Rollbar from 'rollbar';

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.REACT_APP_ENVIRONMENT
    ? process.env.REACT_APP_ENVIRONMENT
    : 'DEVELOPMENT',
};

const rollbar = new Rollbar(rollbarConfig);

export default rollbar;
