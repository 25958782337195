import axios from "axios";
import rollbar from "src/services/loggers/rollbar";

const axiosClientInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_URL
      : "http://localhost:4001",
});

axiosClientInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    const status = error.response ? error.response.status : null;
    const errMsg = error.response.data ? error.response.data : error.response;

    if (status === 500) {
      rollbar.error(errMsg);
    }

    if (status >= 400 && status <= 499) {
      rollbar.warning(errMsg);
    }

    return Promise.reject(error);
  }
);

const axiosClient = {
  instance: axiosClientInstance,
};

export default axiosClient;
