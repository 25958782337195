import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { Toaster } from 'sonner';
import PreInterviewProvider, {
  PreInterviewContext,
} from './PreInterviewProvider';

export default function PreInterviewPageLayout({
  children,
}: PropsWithChildren) {
  return (
    <>
      <main className="h-screen max-h-screen overflow-hidden flex flex-col items-center justify-center bg-white">
        <PreInterviewProvider>
          {children}
          <PreInterviewContext.Consumer>
            {({ currentStep, numberOfSteps }) => (
              <div className="flex gap-x-2">
                {Array.from({ length: numberOfSteps }).map((_, i) => (
                  <div
                    key={i}
                    className={clsx(
                      'w-2 aspect-square rounded-full',
                      currentStep === i ? 'bg-primary' : 'bg-gray-400'
                    )}
                  ></div>
                ))}
              </div>
            )}
          </PreInterviewContext.Consumer>
        </PreInterviewProvider>
      </main>
      <Toaster position="top-center" closeButton={true} />
    </>
  );
}
