import {
  FormAction,
  FormActions,
  IFormState,
  SetFieldErrorAction,
} from "../types";

export default function formProviderReducer(
  state: IFormState,
  action: FormAction
) {
  switch (action.type) {
    case FormActions.ADD_FORM: {
      return { ...state, forms: [...state.forms, action.payload] };
    }

    case FormActions.ADD_VALUE: {
      return { ...state, values: { ...state.values, ...action.payload } };
    }

    case FormActions.RESET_FORM: {
      if (Array.isArray(action.payload)) {
        return { ...state, forms: action.payload };
      }

      return state;
    }

    case FormActions.SET_FIELD_ERROR: {
      const payload = action.payload as SetFieldErrorAction["payload"];
      return {
        ...state,
        errors: { ...(state.errors || {}), [payload.fieldId]: payload.error },
      };
    }

    case FormActions.CLEAR_FORM_ERROR: {
      const payload = action.payload as string;
      const errorMock = { ...state.errors };

      if (errorMock) {
        delete errorMock[payload];
      }

      return { ...state, errors: errorMock };
    }

    default:
      return state;
  }
}
