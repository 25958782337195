import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";

import {
  moveToHistory,
  nextMessage,
  toggleCandidateTyping,
  toggleHubertTyping,
} from "src/store/slices/MessageSlice";

const DebugActionBar = () => {
  const dispatch = useAppDispatch();
  const htyping = useAppSelector((state) => state.Messages.htyping);
  const ctyping = useAppSelector((state) => state.Messages.ctyping);
  const [input, setInput] = useState(null);

  const sampleHubert = { sender: "hubert", answers: ["Hello from Hubert"] };
  const sampleCandidate = {
    sender: "candidate",
    answers: ["Hello from Candidate"],
  };

  return (
    <div className="flex flex-wrap pt-8 gap-2">
      {/* <div className="flex flex-col"> */}
      <span
        className="btn btn-sm"
        onClick={() => {
          dispatch(moveToHistory());
          dispatch(nextMessage({ next: sampleHubert }));
        }}
      >
        Example Hubert Message
      </span>

      <span
        className="btn btn-sm"
        onClick={() => {
          dispatch(moveToHistory());
          dispatch(nextMessage({ next: sampleCandidate }));
        }}
      >
        Example Candidate Message
      </span>

      <span
        className="btn btn-sm"
        onClick={() => {
          dispatch(toggleHubertTyping());
        }}
      >
        {htyping ? (
          <span>Hubert typing off</span>
        ) : (
          <span>Hubert typing on</span>
        )}
      </span>

      <span
        className="btn btn-sm"
        onClick={() => {
          dispatch(toggleCandidateTyping());
        }}
      >
        {ctyping ? (
          <span>Candidate typing off</span>
        ) : (
          <span>Candidate typing on</span>
        )}
      </span>

      <span
        className="btn btn-sm"
        size={"xs"}
        onClick={() => setInput((value) => !value)}
      >
        {input ? <span>Add input area</span> : <span>Remove input area</span>}
      </span>

      <span className="btn btn-sm" onClick={() => {}}>
        <span>Example Single Select</span>
      </span>
      {/* </div> */}
    </div>
  );
};

export default DebugActionBar;
