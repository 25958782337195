import { ComponentProps, useMemo } from "react";
import SimulateMessage from "src/components/Messages/SimulateMessage";
import { DropdownReturnItem } from "../Prompts/FillInTheGaps/components/GapDropdown";
import { ChatHistoryElement, EMessageSender } from "src/types/payload.types";

interface CandidateHistoryMessageProps {
  wait?: number;
  message: ChatHistoryElement;
}

const CandidateHistoryMessage = ({
  wait,
  message,
}: CandidateHistoryMessageProps) => {
  // Had to use .some instead of .every cause TS was throwing an issue here
  // claiming that .every is not invokable
  const isStringArray =
    Array.isArray(message.list) &&
    !message.list.some((item) => typeof item !== "string");

  const candidateAnswerList = useMemo(() => {
    if (Array.isArray(message.list)) {
      const isGapsAnswer =
        message.list.length > 0 &&
        typeof message.list[0] === "object" &&
        "dropdown_id" in message.list[0];

      if (isStringArray) {
        return message.list as string[];
      }

      if (isGapsAnswer) {
        const gapAnswers = (message.list as DropdownReturnItem[]).map(
          (data, index) =>
            `<span>Gap ${index + 1}:</br> <span>${data.text}</span></span>`
        );
        gapAnswers.unshift("Fill in the gaps answers");
        return gapAnswers;
      }
    }

    return null;
  }, [isStringArray, message.list]);

  const commonMessageProps: Pick<
    ComponentProps<typeof SimulateMessage>,
    "createdAt" | "sender" | "wait"
  > = {
    createdAt: message.createdAt,
    wait,
    sender: EMessageSender.Candidate
  };

  return (
    <div className="candidate-history">
      {candidateAnswerList && candidateAnswerList.length > 0 ? (
        candidateAnswerList.map((answer, idx) => (
          <SimulateMessage
            {...commonMessageProps}
            key={`candidate-message-${idx}-answer-idx-${idx}`}
            createdAt={message.createdAt}
            bubble={answer}
            is_last_message={idx === message.list.length - 1}
          />
        ))
      ) : (
        <SimulateMessage
          {...commonMessageProps}
          createdAt={message.createdAt}
          bubble={message.list as string}
          is_last_message
        />
      )}
    </div>
  );
};

export default CandidateHistoryMessage;
