import { forwardRef } from "react";
import { useAppSelector } from "src/store/hooks";
import { ReusableDate } from "./ReusableDate/ReusableDate";
import ReusableOpen from "./ReusableOpen";
import { ReusableSingleSelect } from "./SingleSelect/ReusableSingleSelect";

const ReusablesList = forwardRef<any, any>((_, ref) => {
  const order = useAppSelector((state) => state.Reusables.order);
  const map = useAppSelector((state) => state.Reusables.map);

  if (!order || !map) {
    return null;
  }

  return (
    <>
      {order?.map((id, idx) => {
        const reusable = map[id];
        switch (reusable.question.type) {
          case "open":
            return (
              <ReusableOpen
                key={`reusable-${reusable.qutter_id}-${idx}`}
                reusable={reusable}
              />
            );
          case "single-select":
            return (
              <ReusableSingleSelect
                key={`reusable-${reusable.qutter_id}-${idx}`}
                ref={ref}
                reusable={reusable}
              />
            );
          case "multiple-select":
            return (
              <div className="text-center m-2" style={{ fontSize: "16px" }}>
                <i>
                  <p
                    className="m-0"
                    dangerouslySetInnerHTML={{
                      __html: `${reusable.question.type}: ${reusable.question.text}`,
                    }}
                  ></p>
                </i>
              </div>
            );
          case "date-picker":
            return (
              <ReusableDate
                key={`reusable-${reusable.qutter_id}-${idx}`}
                reusable={reusable}
              />
            );
          case "month-picker":
            return (
              <div className="text-center m-2" style={{ fontSize: "16px" }}>
                <i>
                  <p
                    className="m-0"
                    dangerouslySetInnerHTML={{
                      __html: `${reusable.question.type}: ${reusable.question.text}`,
                    }}
                  ></p>
                </i>
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );
});

export default ReusablesList;
