import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "src/services/common/axiosBaseQuery";
// types
import { TLoadChatPayload } from "src/types/payload.types";
import { GetInterviewByJobIdQueryRequest } from "./queries/types";

// handlers
import QueryHandlers from "./queries";

export const JobApiReducerPath = "jobApi_v1" as const;

export const jobApi = createApi({
  reducerPath: JobApiReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL!}/interview/v1/job`,
  }),
  endpoints: (builder) => ({
    job: builder.query<TLoadChatPayload, GetInterviewByJobIdQueryRequest>({
      query: ({ jobId, redirectUrl, token }) => ({
        url: `/${jobId}`,
        method: "GET",
        params: {
          token,
          redirectUrl,
        },
      }),
      onQueryStarted: QueryHandlers.handleGetJobQueryStarted,
    }),
  }),
});

export const { useJobQuery, useLazyJobQuery } = jobApi;
