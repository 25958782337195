import clsx from "clsx";
import { ComponentProps, useEffect, useRef } from "react";
import { Send } from "react-feather";
import { useTranslation } from "react-i18next";
import { Text } from "src/components/common";
import Button from "src/components/common/Button";
import useScrollToComponent from "src/hooks/useScrollToComponent";
import { useFormContext } from "../providers/FormProvider";
import { FormElement } from "./FormElement";

type FormContainerProps = ComponentProps<"form"> & {
  submitBtnText?: string;
};

const FormContainer = ({ children, submitBtnText }: FormContainerProps) => {
  const messageRef = useRef<HTMLDivElement>(null);
  const scrollToMessage = useScrollToComponent(messageRef);
  const { t } = useTranslation("common");
  const [{ forms }, formOperations] = useFormContext();

  useEffect(() => {
    if (forms) {
      scrollToMessage();
    }
  }, [scrollToMessage, forms]);

  return (
    <form
      className={clsx(
        "form-container",
        forms.length && forms[0].type === "matrix" && "!max-w-full"
      )}
      onSubmit={formOperations.handleSendClick}
    >
      {children}
      {forms.map((form, form_idx) => {
        return <FormElement key={`form-${form.id}-${form_idx}`} form={form} />;
      })}
      <div ref={messageRef}>
        <Button
          id="formButton"
          variant="primary"
          type="submit"
          iconLeft={<Send size={16} />}
          expanded
        >
          <Text>{submitBtnText ?? t("send") ?? "Nothing here"}</Text>
        </Button>
      </div>
    </form>
  );
};

export default FormContainer;
