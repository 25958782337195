import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Outlet, useParams } from "react-router-dom";
import LoadingScreen from "src/components/LoadingScreen";
import { useGetInterviewPreviewQuery } from "src/services/interview";

type LoadPreviewParams = {
  interviewId: string;
};

const LoadPreviewInterview = () => {
  const { interviewId } = useParams<LoadPreviewParams>();
  const interviewPreview = useGetInterviewPreviewQuery(
    interviewId ?? skipToken
  );

  if (interviewPreview.isFetching) {
    return <LoadingScreen />;
  }

  return <Outlet context={{ ...interviewPreview.data }} />;
};

export default LoadPreviewInterview;
