import { createSlice } from "@reduxjs/toolkit";
import {
  CandidateSliceState,
  InitCandidatePayloadAction,
  UpdateCandidatePayloadAction,
} from "../types/Candidate";

const initialState = {
  _id:        "",
  firstName:  "First name",
  lastName:   "Last name",
  email:      "candidate@email.com",
} as CandidateSliceState;

const CandidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    initCandidate(state, action: InitCandidatePayloadAction) {
      const { candidate } = action.payload;
      return {
        ...state,
        ...candidate,
      };
    },
    updateCandidate(state, action: UpdateCandidatePayloadAction) {
      const { onlyUpdateOnMismatch = false, ...candidateProperties } =
        action.payload;

      if (onlyUpdateOnMismatch) {
        const isInSync = (
          ["_id", "firstName", "lastName", "email"] as const
        ).every((property) => state?.[property] === action.payload[property]);

        if (isInSync) {
          return state;
        }
      }

      return {
        ...state,
        ...candidateProperties,
      };
    },
  },
});

export const { initCandidate, updateCandidate } = CandidateSlice.actions;
export default CandidateSlice.reducer;
