import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Text } from "src/components/common";
import { useFormContext } from "src/components/Prompts/Form/providers/FormProvider";
import { SliderFormValue } from "src/components/Prompts/Form/providers/FormProvider/types";
import { SliderForm } from "src/types/forms";
import { FormError } from "../common/FormError";
import { FormLabel } from "../common/FormLabel";
import { FormComponentProps } from "../common/types";
import sliderStyles from "./FormSlider.module.scss";

type FormSliderProps = FormComponentProps<SliderForm>;

const FormSlider = ({ form }: FormSliderProps) => {
  const [{ values }, { addValue, getImmediateError }] = useFormContext();

  const min = form.min ? form.min : 0;
  const max = form.max ? form.max : 100;
  const step = form.step ? form.step : (max - min) / 100;

  const formValue = values[form.id]
    ? (values[form.id] as SliderFormValue).value
    : min;
  const formError = getImmediateError(form.id);

  const handleChange = (value: number) => {
    const data = {
      id: form.id,
      value: value,
      updated_at: new Date(),
      is_mandatory: form.is_mandatory,
      hubiverse_property: form.hubiverse_property,
      candidate_property: form.candidate_property,
    };
    addValue({
      [form.id]: data,
    });
  };

  return (
    <div className={sliderStyles.formSlider}>
      <FormLabel label={form.label} is_mandatory={form.is_mandatory} />

      <Text block weight="semibold" className={sliderStyles.unitLabel}>
        {form.unit ? `${formValue} ${form.unit}` : formValue.toString()}
      </Text>

      <Slider
        min={min}
        max={max}
        step={step}
        defaultValue={formValue}
        onChange={(e) => typeof e === "number" && handleChange(e)}
      />

      <div className={sliderStyles.rangeLabel}>
        <Text block weight="semibold">
          {form.label_left}
        </Text>
        <Text block weight="semibold">
          {form.label_right}
        </Text>
      </div>

      {formError && <FormError error={formError} className="mt-1" />}
    </div>
  );
};

export default FormSlider;
