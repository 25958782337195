import { createSlice } from "@reduxjs/toolkit";
import { Job } from "src/types/interview";

const initialState = { _id: "" } as Job;

const JobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    initJob(state, action) {
      const { job } = action.payload;
      return {
        ...state,
        ...job,
      };
    },
  },
});

export const { initJob } = JobSlice.actions;
export default JobSlice.reducer;
