import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface TimeContextProps {
  focusedRef?: any;
  setFocusedRef: Dispatch<
    SetStateAction<MutableRefObject<HTMLDivElement> | undefined>
  >;
}

const MessageTimeContext = createContext<TimeContextProps | undefined>(
  undefined
);

const MessageTimeProvider = ({ children }: any) => {
  const [focusedRef, setFocusedRef] = useState<
    MutableRefObject<HTMLDivElement> | undefined
  >();

  useEffect(() => {
    if (focusedRef) {
      const currentRef = focusedRef.current;
      currentRef.classList.toggle("active");

      return () => {
        currentRef.classList.remove("active");
      };
    }
  }, [focusedRef]);

  return (
    <MessageTimeContext.Provider value={{ focusedRef, setFocusedRef }}>
      {children}
    </MessageTimeContext.Provider>
  );
};

export default MessageTimeProvider;

export const useMessageTime = () => {
  const context = useContext(MessageTimeContext);
  if (context === undefined) {
    throw new Error("useMessageTime must be used within a TimeProvider");
  }
  return context;
};
