import { ComponentProps } from "react";
import {
  default as ReactPhoneInput,
  PhoneInputProps,
} from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import FormControlDecorator from "../FormControlDecorator";
import "./phoneInput.scss";

const PhoneNumberMasks = {
  se: ".. ... .. ..",
};

type Props = PhoneInputProps & {
  meta: ComponentProps<typeof FormControlDecorator>;
};

export default function PhoneInput({ meta, ...props }: Props) {
  return (
    <FormControlDecorator error={meta.error}>
      <ReactPhoneInput
        {...props}
        disableDropdown
        containerClass="group"
        masks={{ se: PhoneNumberMasks.se }}
      />
    </FormControlDecorator>
  );
}
