import { useSearchParams } from "react-router-dom";
import { Nullable } from "src/types/global";

const useConditionalSearchParams = () => {
  const [routerSearchParams, setRouterSearchParams] = useSearchParams();

  const setSearchParams = (params: Record<string, Nullable<string>>) => {
    const filteredParams = Object.entries(params).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc.set(key, value);
        }
        return acc;
      },
      new URLSearchParams()
    );

    const newSearchParams = new URLSearchParams(filteredParams);
    setRouterSearchParams(newSearchParams);
  };

  return [routerSearchParams, setSearchParams] as const;
};

export default useConditionalSearchParams;
