import clsx from "clsx";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { toggleRendering } from "src/store/slices/MessageSlice";
import SimulateMessage from "../SimulateMessage";
import CloseEnded from "./CloseEnded";

interface HubertMessageProps {
  wait: number;
}

const HubertMessage = ({ wait }: HubertMessageProps) => {
  const dispatch = useAppDispatch();
  const answers = useAppSelector((state) => state.Messages.answers?.list);
  let isTyping = useAppSelector((state) => state.Messages.htyping);
  let t = 0;
  const delay = 300;

  useEffect(() => {
    dispatch(toggleRendering({ value: true }));
  }, [dispatch]);

  if (!answers) return <span>No answers</span>;

  return (
    <section className="mb-4">
      <div className="current-message-wrapper h-fit">
        <div className={clsx("hubert-avatar", isTyping && "invisible")}>H</div>
        <div>
          {answers.map((answer, idx) => {
            t += delay;
            return (
              <SimulateMessage
                key={`hubert-message-${idx}-answer-idx-${idx}`}
                wait={wait === 0 ? 0 : t + wait + delay}
                sender="hubert"
                bubble={answer}
                is_last_message={idx === answers.length - 1}
              />
            );
          })}
        </div>
      </div>
      <CloseEnded />
    </section>
  );
};

export default HubertMessage;
