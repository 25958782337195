import { KeyboardEvent } from "react";
import { Optional } from "src/types/global";

export const KeyCombinations = {
  /** `Shift` + `Enter` */
  LINE_BREAK: "LINE_BREAK",
  /** `Enter` */
  ENTER: "ENTER",
  BACKSPACE: "BACKSPACE",
} as const;

export type KeyCombination = keyof typeof KeyCombinations;

const useKeyDetection = () => {
  const getKeyCombination = (e: KeyboardEvent): Optional<KeyCombination> => {
    if (e.shiftKey) {
      if (e.key === "ENTER") {
        return KeyCombinations.LINE_BREAK;
      }
    }

    if (e.key === "BACKSPACE") {
      return KeyCombinations.BACKSPACE;
    }

    if (e.key === "ENTER") {
      return KeyCombinations.ENTER;
    }
  };

  return { getKeyCombination };
};

export default useKeyDetection;
